import { RoofTile } from './rooftile';
import { TileFamily, TileFinish, TileColor, RoofArticle, Material, Underlagstak, MarketStr } from '@/calculation/common';
import { ExtraAssortment, AccessoryColor } from '@/enums';
import { replaceByArtnr } from '@/helpers/tile';

import { i18n } from '@/i18n';
const { t } = i18n.global;

export class PalemaTile extends RoofTile {
  public constructor() {
    super(TileFamily.Palema, 330, 240);
    this.label = 'Palema 2-kupig';
    this.key = 'palema';
    this.material = Material.Betong;
    this.läktavstånd = {
      [MarketStr.Sverige]: {
        min: 310,
        angle14to18: 320,
        angle18to22: 340,
        angle22to80: 375,
      },
      [MarketStr.Norge]: {
        min: 310,
        angle14to18: 320,
        angle18to22: 340,
        angle22to80: 375,
      },
      [MarketStr.Tyskland]: {
        min: 310,
        angle14to18: 320,
        angle18to22: 340,
        angle22to80: 375,
      },
    };
    this.avstTillFörstaLäkt = {
      [MarketStr.Sverige]: 340,
      [MarketStr.Norge]: 370,
      [MarketStr.Tyskland]: 340,
    };

    this.itemNos = {
      [RoofArticle.TakPanna]: '020024',
      [RoofArticle.NockPanna]: '030024',
      [RoofArticle.NockPannaAlt]: '033024',
      [RoofArticle.NockBörjan]: '031524',
      [RoofArticle.NockSlut]: '031624',
      [RoofArticle.BörjanValm]: '031024',
      [RoofArticle.BörjanValmAlt]: '0318524',
      [RoofArticle.NockBörjanAlt]: '031824',
      [RoofArticle.NockSlutAlt]: '032024',
      [RoofArticle.NockTBörjan]: '036024',
      [RoofArticle.NockTSlut]: '036124',
      [RoofArticle.ValmKlocka]: '035024',
      [RoofArticle.ValmKlockaBörjan]: '035224',
      [RoofArticle.ValmKlockaSlut]: '035324',
      [RoofArticle.NockXLutande]: '037024',
      [RoofArticle.NockXLutandeAlt]: '037424',
      [RoofArticle.NockXPlan]: '037124',
      [RoofArticle.HalvPanna]: '021024',
      [RoofArticle.GavelPannaVänster]: '041324',
      [RoofArticle.GavelPannaHöger]: '041224',
      [RoofArticle.GavelPannaVänsterBred]: '040324',
      [RoofArticle.GavelPannaHögerBred]: '040224',
      [RoofArticle.GavelBeslag]: '041624',
      [RoofArticle.DubbelvingadPanna]: '021524',
      [RoofArticle.MansardPanna]: '023024',
      [RoofArticle.PultPanna]: '041824',
      [RoofArticle.PultPannaGavelVänster]: '042124',
      [RoofArticle.PultPannaGavelHöger]: '042024',
      [RoofArticle.Ventilationshuv]: '052224',
      [RoofArticle.Ventilationshuv2]: '088524',
      [RoofArticle.VentilationshuvMontering]: '060200',
      [RoofArticle.Avluftare]: '050124',
      [RoofArticle.Avluftare2]: '050724',
      [RoofArticle.AvluftarePlåt]: '051220',
      [RoofArticle.AvluftarePlåtMontering]: '060100',
      [RoofArticle.Kragrör]: '061020',
      [RoofArticle.Rififi]: '060000',
      [RoofArticle.InfästTaksteg]: '081190',
      [RoofArticle.Taksteg]: '083324',
      // [RoofArticle.Glidskydd]: '072920',
      [RoofArticle.Snöglidhinder]: '081720',
      [RoofArticle.SnöglidhinderKonsol]: '081820',
      [RoofArticle.Valmtätning300]: '091620',
      [RoofArticle.Valmtätning370]: '090720',
      [RoofArticle.Ventinock]: '090224',
      [RoofArticle.Rändalstätning]: '118520',
      [RoofArticle.Fågelband]: '092020',
      [RoofArticle.Fågelband5mSvart]: '092120',
      [RoofArticle.Fågelband5mRöd]: '092124',
      [RoofArticle.VentiFågelband]: '091520',
      [RoofArticle.Takluftare]: '097200',
      [RoofArticle.Nocktätningsremsa]: '093110',
      [RoofArticle.SpikPanna]: '094291',
      [RoofArticle.SkruvPanna]: '094491',
      [RoofArticle.Stormclips]: '096290',
      // [RoofArticle.Stormclips2]: '096491',
      [RoofArticle.EasyClip]: '096591',
      [RoofArticle.EasyClip45x70]: '067091',
      [RoofArticle.EasyClip30x48]: '067591',
      [RoofArticle.EasyClip36x48]: '068091',
      [RoofArticle.Nockskruv]: '094391',
      [RoofArticle.NockskruvAlt]: '094691',
      [RoofArticle.Nockklammer]: '114724',
      [RoofArticle.Nockbrädshållare]: '065190',

      // Roof Security
      [RoofArticle.Bärläktsteg45]: '076220',
      [RoofArticle.Bärläktsteg25]: '076220',
      [RoofArticle.KoppladeTaksteg]: '084320',
      [RoofArticle.InfästningKoppladeTaksteg]: '083990',
      [RoofArticle.Glidskydd]: '072920',
      [RoofArticle.Taklucka]: '083820',
      [RoofArticle.Säkerhetskrok]: '070120',
      [RoofArticle.Snökrok]: '081420',
      [RoofArticle.Fästögla]: '171690',
      [RoofArticle.SpårPanna]: '073624',
      [RoofArticle.SnowSlideObstacle]: '081790',
      [RoofArticle.SnowSlideObstacleKonsol]: '081890',
      [RoofArticle.IsStoppare]: '083020',

      [RoofArticle.BTSUnderlagstak]: '128001',
    };

    this.marketSpecificItemNos = {
      [MarketStr.Norge]: {
        [RoofArticle.Kragrör]: '061220',
        [RoofArticle.Ventilationshuv]: '088524',
        [RoofArticle.Valmtätning300]: '092420',
      },
      [MarketStr.Tyskland]: {
        [RoofArticle.AvluftareTyskSpitze]: '061224',
        [RoofArticle.LösVenthattSpitze]: '050824',
        [RoofArticle.Dunstrohrlufter100110]: '087824',
        [RoofArticle.Entwässerunglü100110]: '085124',
        [RoofArticle.Dunstrohrlufter125]: '088324',
        [RoofArticle.Entwässerunglü125]: '085224',
        [RoofArticle.Dunstrohrlufter160]: '085524',
        [RoofArticle.FlexrörAvluftare10070]: '110600',
        [RoofArticle.FlexrörAvluftare12570]: '110640',

        [RoofArticle.Valmtätning300]: '092420',
        [RoofArticle.Valmtätning340]: '090520',
        [RoofArticle.Valmtätning370]: '090720',

	[RoofArticle.Tätningsbeslag]: '095520',
	[RoofArticle.RidgePlate]: '118020',
	[RoofArticle.RidgePlateMount]: '118120',
	[RoofArticle.ChimneySealant5m]: '115720',
	[RoofArticle.ChimneySealant7m]: '115820',
	[RoofArticle.ChimneySealant8m]: '115920',
	[RoofArticle.RidgeSealant]: '118520',
	[RoofArticle.VentivalmPlast]: '091420',
	[RoofArticle.VentivalmBorst]: '091120',
	[RoofArticle.Fågelband]: '092020',
	[RoofArticle.VentiFågelband]: '091520',

        [RoofArticle.GastDurchgGS]: '088724',
        [RoofArticle.AntennenDurchgPanna]: '088924',
        [RoofArticle.AntennenAufsatz]: '085024',

	[RoofArticle.Sturmklammer456VA30x50Palema]: '113391',
	[RoofArticle.Sturmklammer456Zial30x50Palema]: '113390',
	[RoofArticle.Sturmklammer456VA40x60Palema]: '113491',
	[RoofArticle.Sturmklammer456Zial40x60Palema]: '113490',
	[RoofArticle.SturmklammerNordmark2Zial]: '114291',
	[RoofArticle.Sturmklammer456VA30x50Mecklenburger]: '112291',
	[RoofArticle.Sturmklammer456Zial30x50Mecklenburger]: '112290',
	[RoofArticle.Sturmklammer456VA40x60Mecklenburger]: '112491',
	[RoofArticle.Sturmklammer456Zial40x60Mecklenburger]: '112490',
	[RoofArticle.SturmklammerExklusivZial]: '113691',
	[RoofArticle.SturmklammerCarismaZial]: 'C98191',

	[RoofArticle.RänndalsOValmklipps]: '096390',

        [RoofArticle.Nockklammer]: '114424',
        [RoofArticle.NockklammerAlt]: '113524',
        [RoofArticle.SkruvTillBetongtillbehör]: '095990',
        [RoofArticle.SkruvTillBetongtillbehörRostfri]: 'C98291',
      },
    };
  }

  public isSelectable(
    lAvstånd?: number | null,
    taklutning?: { min: number; max: number } | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    underlagstak?: Underlagstak | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    market?: MarketStr | null,
  ): { valid: boolean; message: string } {
    // 310 <= Läktavstånd <= 375
    if (lAvstånd && (lAvstånd < 310 || lAvstånd > 375)) return { valid: false, message: t('validation.notice.disabled_due_to_lakt') };
    if (taklutning && (!this.angleInRange(taklutning.min, { min: 14, max: 80 }) || !this.angleInRange(taklutning.max, { min: 14, max: 80 })))
      return { valid: false, message: t('validation.notice.disabled_due_to_angle') };

    return { valid: true, message: '' };
  }

  public bärläktAvståndNock(angle: number): number {
    const ang = Number(angle.toFixed(1));
    if (ang >= 14 && ang <= 27) return 40;
    if (ang >= 27.1 && ang <= 35) return 25;
    if (ang >= 35.1 && ang <= 80) return 15;
    return 40;
  }

  public getAccessoryOptions(
    assortment: ExtraAssortment,
    { color, finish, accessoryColor }: { color: TileColor; finish: TileFinish; accessoryColor: AccessoryColor },
  ): string[] {
    const items: string[] = [];
    switch (assortment) {
      case ExtraAssortment.VentilationGenomforing:
        switch (accessoryColor) {
          case AccessoryColor.GalvanizedGreySilver:
            items.push(...['052297', '051297', '059997', '051920', '054520']);
            break;
          case AccessoryColor.Black:
            items.push(...['052220', '051220', '059920', '051920', '054520']);
            break;
          case AccessoryColor.BrickRed:
            items.push(...['052224', '051224', '059924', '051924', '054524']);
            break;
        }
        // eslint-disable-next-line no-case-declarations
        const avluftare = this.getAccessoryVariant(RoofArticle.Avluftare, { color, finish, accessoryColor })?.artnr;
        if (avluftare) items.push(avluftare);
        break;
      case ExtraAssortment.TatningLuftning:
        switch (accessoryColor) {
          case AccessoryColor.GalvanizedGreySilver:
          case AccessoryColor.Black:
            items.push(...['118620']);
            break;
          case AccessoryColor.BrickRed:
            items.push(...['118624']);
            break;
        }
        break;
    }

    // Replace artnr if necessary
    for (let i = 0; i < items.length; i++) {
      items[i] = replaceByArtnr(items[i]);
    }

    return items;
  }
}
