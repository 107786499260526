export enum AccessoryColor {
  None,
  GalvanizedGreySilver,
  Black,
  BrickRed,
}

export enum Sealant {
  None,
  NockValmRoll300mm,
  NockValmRoll370mm,
  Ventinock,
  NockValmRoll340mm,
}

export enum Ventilation {
  None,
  Birdband1m,
  Birdband5mRollBlack,
  Birdband5mRollBrickRed,
  VentilatingBirdband1m,
  Tätningsbeslag,
  RidgePlate,
  RidgePlateMount,
  ChimneySealant5m,
  ChimneySealant7m,
  ChimneySealant8m,
  RidgeSealant,
  VentivalmPlast,
  VentivalmBorst,
  VentilatingLath1m,
}

export enum VentilationPassage {
  None,
  GastDurchgGS,
  AntennenDurchgPanna,
  AntennenAufsatz,
  Betong,
  Plast,
  Metall,
}

export enum ExposedWeather {
  None,
  Yes,
  No,
}

export enum ConcealedDraining {
  None,
  Yes,
  No,
}

export enum SealingAiring {
  None,
  Yes,
  No,
}

export enum FixingsTile {
  None,
  EasyClip,
  Screw,
  Nail,
  Stormclips,
  SidefoldStaples,
  DiagonalClip,
  DiagonalClipZial,
  RoofhookJP,
  SBracket,
  RooftileHook,
  EasyClip45x70,
  EasyClip30x48,
  EasyClip36x48,

  // Tyskland
  Sturmklammer456VA30x50Palema,
  Sturmklammer456Zial30x50Palema,
  Sturmklammer456VA40x60Palema,
  Sturmklammer456Zial40x60Palema,
  Sturmklammer456VA30x50Mecklenburger,
  Sturmklammer456Zial30x50Mecklenburger,
  Sturmklammer456VA40x60Mecklenburger,
  Sturmklammer456Zial40x60Mecklenburger,
  SturmklammerExklusivZial,
  SturmklammerCarismaZial,
  SturmklammerNordmark2Zial,
}

export enum FixingsNock {
  None,
  NockScrew,
  NockScrewAndStaples,
  NockScrewAndStaplesHansa,
  NockScrewAndNockClip,
  Nockklammer,
  NockklammerAlt,
  ConcreteAccessoryScrew,
  ConcreteAccessoryScrewStainless,
}

export enum FixingsRidge {
  None, Yes, No
};

export enum BattenStep {
  None,
  Step,
  Connected,
}

export enum SecuritySystem {
  None = '',
  SAFE = 'safe',
  PW = 'pw',
}

export enum Gable {
  None,
  GablePan,
  GableFittings,
  GablePanNA,
  GablePanWide,
  DoubleWingedTile,
}

export enum DrainAerator {
  None,
  Concrete,
  Clay,
  Plate,
  
  // KRI-specific
  PlastDunstrohrlufter,
  PlastEntwasserunglu,
  PlastDunstrohrlufter125,
  PlastEntwasserunglu125,
  PlastDunstrohrlufter160,
}

export enum VentilationHood {
  None,
  Plate,
}

export enum FacadeHeight {
  None = '',
  Less3m = '3m-less',
  Less4m = '4m-less',
  Less5m = '5m-less',
  To58 = '5m-to-8m',
  More8m = '8m-more',
}

export enum BTSBaseRoof {
  None,
  Yes,
  No,
}

export enum NockBoard {
  None,
  Yes,
  No,
}

export enum SnowSlideObstacle {
  None,
  Yes,
  No,
}

export enum ExtraAssortment {
  TakpannorOchTillbehor = 'Takpannor och tillbehör',
  VentilationGenomforing = 'Ventilation/Genomföring',
  // Taksakerhet = "Taksäkerhet",
  TatningLuftning = 'Tätning/Luftning',
  Infastning = 'Infästning',
  Taksakerhet = 'Taksäkerhet',
  OvrigaTillbehor = 'Övriga tillbehör',
}

export enum StepState {
  // Inactive = 'inactive', // can just be null?
  Active = 'active', // Starts as active
  Valid = 'valid', // Field values are valid
  Error = 'error', // Field values are invalid
  Done = 'done', // Step has been completed
}

export enum ValidationSteps {
  Roof = 'roof',
  Tile = 'tile',
  Accessories = 'accessories',
  RoofSecurity = 'roofsecurity',
  Extras = 'extras',

  // RoofType = 'rooftype',
  // LathDistance = 'lathdistance',
  // ConfirmRoof = 'confirmroof',

  RoofAndExtensions = 'roofandextensions',
}

export const accessoryColorMap = {
  [AccessoryColor.GalvanizedGreySilver]: ['Galvad', 'Grå', 'Silver', 'Ljusgrå', 'Mellangrå', 'Platinagrå'],
  [AccessoryColor.Black]: ['Svart'],
  [AccessoryColor.BrickRed]: ['Tegelröd'],
} as { [key in AccessoryColor]: string[] };
