import type { Roof } from './roof';
import type { RoofPartHörnVinkel } from './roofparthornvinkel';
import type { RoofDimensions, RoofDimensionsWithError } from '@/project';
import { RoofTile } from '@/calculation/rooftile';
import {
	RoofType,
	RoofModel,
	RoofForm,
	MIN_INPUT_WIDTH,
	MIN_INPUT_ANGLE,
	MAX_INPUT_ANGLE,
	svinn,
	RoofArticle,
	ByggBredd,
	GavelTillval,
	MarketStr,
	addArticleSum,
	Material,
	TileFamily,
	Underlagstak,
	NockPannaVariant,
	type ArticleSum,
	round,
	roundCutoff,
	MAX_INPUT_WIDTH,
	isCompatMode,
	GAVEL_MIN_LÄKTAVSTÅND,
} from '@/calculation/common';

import { i18n } from '@/i18n';
const { t } = i18n.global;

/** Class to represent the geometrical shape of a single roof or roof extension. */
export abstract class RoofPart {
	public roof: Roof;

	public length: number;
	public width: number;
	public width2: number;
	public angle: number;
	public angle2: number;

	/** Number of this roofpart to calculate articles for, accessible through `amount` getter/setter */
	protected amountValue = 1;

	public form;
	public nockben;
	public nockben2;
	private origNockben;
	private origNockben2;

	public static readonly type: RoofType;
	public static readonly model: RoofModel;
	public static readonly sides: number;
	public static readonly rändaler: number;

	protected constructor(
		roof: Roof,
		form: RoofForm,
		nockben = 0,
		nockben2 = 0,
		length = -1,
		width = -1,
		width2 = -1,
		angle = -1,
		angle2 = -1
	) {
		this.roof = roof;
		this.form = form;
		this.nockben  = this.origNockben  = nockben;
		this.nockben2 = this.origNockben2 = nockben2;

		this.length = length;
		this.width = width;
		this.width2 = width2;
		this.angle = angle;
		this.angle2 = angle2;

		// Huvudtak med hörnvinkeldelar kommer tappa ett nockben per hörnvinkel
		if (this.isMainPart()) {
			const numHV = this.roof.numHVTak();
			if (this.form == RoofForm.Valm) this.nockben -= numHV;
			if (this.form == RoofForm.Halvvalm) this.nockben2 -= numHV;
		}
	}

	/** Instantiate a RoofPart object of the type given in `roofType`. For extension roofs roof forms are given with the `roofForm` parameter */
	public static async createRoofPart(roof: Roof, roofType: RoofType, roofForm?: RoofForm): Promise<RoofPart> {
		const saddle = await import('./roofpartsaddle');
		const valm = await import('./roofpartvalm');
		const halvvalm = await import('./roofparthalvvalm');
		const mansard = await import('./roofpartmansard');
		const mansardValm = await import('./roofpartmansardvalm');
		const mansardHalvvalm = await import('./roofpartmansardhalvvalm');
		const pult = await import('./roofpartpult');
		const vinkel = await import('./roofpartvinkel');
		const kupa = await import('./roofpartkupa');
		const front = await import('./roofpartfront');
		const pultdel = await import('./roofpartpultdel');
		const tillbyggnad = await import('./roofparttillbyggnad');
		const hörnvinkel = await import('./roofparthornvinkel');

		switch (roofType) {
			case RoofType.Sadel:
				return new saddle.RoofPartSaddle(roof);
			case RoofType.Valm:
				return new valm.RoofPartValm(roof);
			case RoofType.Halvvalm:
				return new halvvalm.RoofPartHalvvalm(roof);
			case RoofType.Mansard:
				return new mansard.RoofPartMansard(roof);
			case RoofType.MansardValm:
				return new mansardValm.RoofPartMansardValm(roof);
			case RoofType.MansardHalvvalm:
				return new mansardHalvvalm.RoofPartMansardHalvvalm(roof);
			case RoofType.Pulttak:
				return new pult.RoofPartPult(roof);
			case RoofType.VinkelTak:
				if (!roofForm)
					throw new Error('Attempted to create an extension roof object with an empty roof form parameter');
				return new vinkel.RoofPartVinkel(roof, roofForm);
			case RoofType.Kupa:
				if (!roofForm)
					throw new Error('Attempted to create an extension roof object with an empty roof form parameter');
				return new kupa.RoofPartKupa(roof, roofForm);
			case RoofType.Front:
				if (!roofForm)
					throw new Error('Attempted to create an extension roof object with an empty roof form parameter');
				return new front.RoofPartFront(roof, roofForm);
			case RoofType.PultDel:
				if (!roofForm)
					throw new Error('Attempted to create an extension roof object with an empty roof form parameter');
				return new pultdel.RoofPartPultDel(roof, roofForm);
			case RoofType.Tillbyggnad:
				if (!roofForm)
					throw new Error('Attempted to create an extension roof object with an empty roof form parameter');
				return new tillbyggnad.RoofPartTillbyggnad(roof, roofForm);
			case RoofType.HörnVinkel:
				if (!roofForm)
					throw new Error('Attempted to create an extension roof object with an empty roof form parameter');
				return new hörnvinkel.RoofPartHörnVinkel(roof, roofForm);
			default:
				throw new Error('No implementation for roofpart of type: ' + RoofType[roofType]);
		}
	}

	public roofSelected() {
		if (this.model === RoofModel.Mansard && this.form == RoofForm.Pult)
			console.error('Mansard pulttak is not supported');

		return this.type !== RoofType.None && this.form !== RoofForm.None;
	}

	// Length & Width are in meters, angle is in degrees. Length & Width then gets converted to mm
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public setOptions({
		a,
		b,
		c,
		angle = 0,
		angle2 = 0,
	}: {
		a: number;
		b: number;
		c?: number;
		angle: number;
		angle2?: number;
	}) {
		this.length = a * 1000;
		this.width = b * 1000;
		if (c) this.width2 = c * 1000;
		// this.height = c * 1000; // Not used for all roofs
		this.angle = angle;
		if (angle2 && angle2 > 0) this.angle2 = angle2;
	}

	/** The amount of this part when taking into account both number of RoofParts in the Roof and number of Roofs in total. */
	public getAbsAmount() {
		return this.amount * this.roof.amount;
	}

	public lengthEnabled() {
		return this.roofSelected();
	}
	public widthEnabled() {
		return this.roofSelected();
	}
	public width2Enabled() {
		return this.roofSelected() && (this.model === RoofModel.Mansard || this.form === RoofForm.Halvvalm);
	}
	public angleEnabled() {
		return this.roofSelected();
	}
	public angle2Enabled() {
		return this.roofSelected() && this.model === RoofModel.Mansard;
	}

	public angleInRads() {
		return (this.angle * Math.PI) / 180;
	}
	public angle2InRads() {
		return (this.angle2 * Math.PI) / 180;
	}

	public sideWidth() {
		return this.width / this.sides;
	}
	public sideWidth2() {
		return this.width2 / this.sides;
	}

	public get amount() {
		return this.amountValue;
	}

	public set amount(value: number) {
		this.amountValue = value;
	}

	public get tile() {
		return this.roof.selection.tileFamily;
	}

	public get type() {
		return (this.constructor as typeof RoofPart).type;
	}
	public get model() {
		return (this.constructor as typeof RoofPart).model;
	}
	public get sides() {
		return (this.constructor as typeof RoofPart).sides;
	}
	public get rändaler() {
		return (this.constructor as typeof RoofPart).rändaler;
	}

	public isMainPart() {
		return [
			RoofType.Sadel,
			RoofType.Valm,
			RoofType.Halvvalm,
			RoofType.Mansard,
			RoofType.MansardValm,
			RoofType.MansardHalvvalm,
			RoofType.Pulttak,
		].includes(this.type);
	}

	/** Om den här takdelen är huvudtaket och det finns minst en hörnvinkeldel på taket */
	public isHuvudHVTak() {
		return this.isMainPart() && this.roof.extensions.find((part) => part.type === RoofType.HörnVinkel) !== undefined;
	}

	/** Varje hörnvinkeldel tar bort ett nockben från huvuddelen, den här funktionen kallas om HV läggs till efter huvuddelen redan skapats */
	public notifyHVAdded(hv: RoofPartHörnVinkel) {
		if (this.isMainPart()) {
			let numHVTak = this.roof.numHVTak();
			if (!this.roof.extensions.includes(hv)) numHVTak += hv.amount;

			if (this.form == RoofForm.Valm) this.nockben = this.origNockben - numHVTak;
			if (this.form == RoofForm.Halvvalm) this.nockben2 = this.origNockben2 - numHVTak;
		}
	}

	/** Return number of gavlar, number of roof-ends per side (not including the takfot) */
	public getGavlar() {
		return 2;
	}

	/** Beräkna höjden från takfot till nock */
	// Paths:
	// TC1	case TM_STANDARD
	// TC3		If TF_HALVVALM
	// TC13	case TM_MANSARD
	public getHeight(): { height: number; height2: number } {
		const height = this.sideWidth() * Math.tan(this.angleInRads());

		let height2 = 0;
		if (this.form === RoofForm.Halvvalm) height2 = this.sideWidth2() * Math.tan(this.angleInRads());
		if (this.model === RoofModel.Mansard) height2 = this.sideWidth2() * Math.tan(this.angle2InRads());

		return { height: round(height), height2: round(height2) };
	}

	/** Beräkna summan av takfall för det här taket, längden mellan nocken och takfoten */
	// Paths:
	// TC1 	Switch
	// TC1	case TM_STANDARD:
	// TC1		case TF_SADEL, TF_PULT
	// TC23			If TT_HUVUD && _HV_Tak
	// TC2		case TF_VALM
	// TC3		case TF_HALVVALM
	// TC25			If TT_HUVUD && _HV_Tak
	// TC13	case TM_MANSARD:
	// TC14		case TF_VALM
	// TC15		case TF_HALVVALM
	// TC3	Switch
	// TC3	case TT_VINKEL, TT_HORNVINKEL
	// TC6	case TT_FRONT
	// TC5	case TT_KUPA
	public getTakfall(): { takfall: number; takfall2: number; takfallGavel: number; takfallGavel2: number } {
		assertInRange(this.width, MIN_INPUT_WIDTH, MAX_INPUT_WIDTH, `Width(${this.width}) invalid`);
		assertInRange(this.angle, MIN_INPUT_ANGLE[this.roof.market], MAX_INPUT_ANGLE[this.roof.market], `Angle(${this.angle}) invalid`);

		const width = this.sideWidth();
		const width2 = this.sideWidth2();
		const takfall = width / Math.cos(this.angleInRads());
		let takfall2 = 0;
		let takfallGavel = 0;
		let takfallGavel2 = 0;

		if (this.form === RoofForm.Valm) takfallGavel = this.sideWidth() / Math.cos(this.angleInRads());

		if (this.form === RoofForm.Halvvalm) {
			takfall2 = width2 / Math.cos(this.angleInRads());
			takfallGavel2 = width2 / Math.cos(this.angleInRads());
			if (this.isHuvudHVTak()) takfallGavel = width / Math.cos(this.angleInRads());
		}

		if (this.model === RoofModel.Mansard) {
			takfall2 = width2 / Math.cos(this.angle2InRads());
			if (this.form == RoofForm.Valm) {
				takfallGavel = this.sideWidth() / Math.cos(this.angleInRads());
				takfallGavel2 = this.sideWidth2() / Math.cos(this.angle2InRads());
			} else if (this.form == RoofForm.Halvvalm) {
				takfallGavel2 = this.sideWidth2() / Math.cos(this.angle2InRads());
			}
		}

		return {
			takfall: round(takfall),
			takfall2: round(takfall2),
			takfallGavel: takfallGavel,
			takfallGavel2: takfallGavel2,
		};
	}

	/** Change the angle of the roof such that takfall is equal to the given value */
	public setTakfall(takfall: number) {
		const width = this.sideWidth();
		assert(takfall >= width, t('error.calculation.invalid_geometry'));
		const newAngle = round((Math.acos(width / takfall) * 180) / Math.PI);
		assertInRange(newAngle, MIN_INPUT_ANGLE[this.roof.market], MAX_INPUT_ANGLE[this.roof.market], t('error.calculation.angle_out_of_range'));
		this.angle = newAngle;
	}

	/** Change the angle of the roof such that takfall2 is equal to the given value */
	public setTakfall2(takfall2: number) {
		const width2 = this.sideWidth2();
		assert(takfall2 >= width2, t('error.calculation.invalid_geometry'));
		const newAngle = round((Math.acos(width2 / takfall2) * 180) / Math.PI);
		assertInRange(newAngle, MIN_INPUT_ANGLE[this.roof.market], MAX_INPUT_ANGLE[this.roof.market], t('error.calculation.angle_out_of_range'));
		this.angle2 = newAngle;
	}

	public setTakBredd(takbredd: number) {
		assertInRange(takbredd, MIN_INPUT_WIDTH, MAX_INPUT_WIDTH, t('error.calculation.width_out_of_range'));
		this.width = takbredd;
	}

	public setTakBredd2(takbredd2: number) {
		assertInRange(takbredd2, MIN_INPUT_WIDTH, MAX_INPUT_WIDTH, t('error.calculation.width_out_of_range'));
		this.width2 = takbredd2;
	}

	/** Frontend only: Set the angle and return the roof slope in meters */
	public setDimensionAngle(angle: number, width?: number): number {
		this.width = width ? width * 1000 : this.width; // Make sure the width is up to date
		this.angle = angle;
		try {
			return this.getTakfall().takfall / 1000;
		} catch (e) {
			console.warn(e);
		}
		return 0;
	}

	/** Frontend only: Set the slope and return the roof angle */
	public setDimensionSlope(slope: number, width?: number): number {
		this.width = width ? width * 1000 : this.width; // Make sure the width is up to date
		try {
			this.setTakfall(slope * 1000);
		} catch (e) {
			console.warn(e);
			return 0;
		}
		return this.angle;
	}

	/** Frontend only: Set the measuremnt and return the missing dimension */
	public setDimensionMeasurement({ b, c, angle, angle2, slope, slope2 }: RoofDimensions): RoofDimensionsWithError {
		const resp = {} as RoofDimensionsWithError;

		// Roof Slopes
		if (b && angle && !slope) {
			if (angle < MIN_INPUT_ANGLE[this.roof.market] || angle > MAX_INPUT_ANGLE[this.roof.market])
				return t('error.calculation.angle_out_of_range');
			this.width = b ? b * 1000 : this.width; // Make sure the width is up to date
			this.width2 = c ? c * 1000 : this.width2; // Make sure the width is up to date
			this.angle = angle;
			try {
				const v = Math.round(this.getTakfall().takfall / 10) / 100;
				resp.slope = v;
				assertInRange(v * 1000, MIN_INPUT_WIDTH, MAX_INPUT_WIDTH, t('error.calculation.slope_out_of_range'));
			} catch (e) {
				// resp.slope = 0;
				console.warn(e);
				if (e instanceof Error) resp.error = e.message;
				else resp.error = 'Unknown error';
			}
		}

		if (c && angle2 && !slope2) {
			this.width = b ? b * 1000 : this.width; // Make sure the width is up to date
			this.width2 = c ? c * 1000 : this.width2; // Make sure the width is up to date
			this.angle2 = angle2;
			try {
				const v = Math.round(this.getTakfall().takfall2 / 10) / 100;
				resp.slope2 = v;
				assertInRange(v * 1000, MIN_INPUT_WIDTH, MAX_INPUT_WIDTH, t('error.calculation.slope_out_of_range'));
			} catch (e) {
				// resp.slope2 = 0;
				console.warn(e);
				if (e instanceof Error) resp.error = e.message;
				else resp.error = 'Unknown error';
			}
		}

		// Roof Angle
		if (b && slope && !angle) {
			this.width = b ? b * 1000 : this.width; // Make sure the width is up to date
			try {
				this.setTakfall(slope * 1000);
				resp.angle = this.angle;
			} catch (e) {
				resp.angle = 0;
				console.warn(e);
				if (e instanceof Error) resp.error = e.message;
				else resp.error = 'Unknown error';
			}
		}

		if (c && slope2 && !angle2) {
			this.width2 = c ? c * 1000 : this.width2; // Make sure the width is up to date
			try {
				this.setTakfall2(slope2 * 1000);
				resp.angle2 = this.angle2;
			} catch (e) {
				resp.angle2 = 0;
				console.warn(e);
				if (e instanceof Error) resp.error = e.message;
				else resp.error = 'Unknown error';
			}
		}

		// Roof width
		if (angle && slope && !b) {
			this.angle = angle;
			try {
				const v = round(this.sides * slope * Math.cos((angle * Math.PI) / 180), 2);
				this.setTakBredd(v * 1000);
				resp.b = v;
			} catch (e) {
				resp.b = 0;
				console.warn(e);
				if (e instanceof Error) resp.error = e.message;
				else resp.error = 'Unknown error';
			}
		}

		if (angle2 && slope2 && !c) {
			this.angle2 = angle2;
			try {
				const v = round(2 * slope2 * Math.cos((angle2 * Math.PI) / 180), 2);
				this.setTakBredd2(v * 1000);
				resp.c = v;
			} catch (e) {
				resp.c = 0;
				console.warn(e);
				if (e instanceof Error) resp.error = e.message;
				else resp.error = 'Unknown error';
			}
		}

		return resp;
	}

	// /** Frontend only: Set the angle2 and return the roof slope in meters */
	// public setDimensionAngle2(angle2: number, width2?: number): number {
	// 	this.width2 = width2 ? width2 * 1000 : this.width2; // Make sure the width is up to date
	// 	this.angle2 = angle2
	// 	try {
	// 		return this.getTakfall().takfall2 / 1000;
	// 	} catch (e) {
	// 		console.warn(e);
	// 	}
	// 	return 0;
	// }

	// /** Frontend only: Set the slope2 and return the roof angle */
	// public setDimensionSlope2(slope2: number, width2?: number): number {
	// 	this.width2 = width2 ? width2 * 1000 : this.width2; // Make sure the width is up to date
	// 	try {
	// 		this.setTakfall2(slope2 * 1000);
	// 	} catch (e) {
	// 		console.warn(e);
	// 		return 0;
	// 	}
	// 	return this.angle2;
	// }

	public usesBredGavelpanna(): boolean {
		if (this.model === RoofModel.Standard) return this.calculateLäktavstånd().läktavstånd1 < GAVEL_MIN_LÄKTAVSTÅND;
		if (this.model === RoofModel.Mansard)
			return (
				this.calculateLäktavstånd().läktavstånd1 < GAVEL_MIN_LÄKTAVSTÅND ||
				this.calculateLäktavstånd().läktavstånd2 < GAVEL_MIN_LÄKTAVSTÅND
			);

		return false;
	}

	/** En panna är bredare än dess byggbredd. Totala takbredden blir alltså bredare än bara byggbredden*n. Räkna ut den effektiva takbredden som vi måste täcka med pannor */
	public getNettoTaklängd() {
		if (this.tile === RoofTile.None) throw Error('Tile not set');

		return this.length - (this.tile.bredd - this.tile.takpannaByggBredd());
	}

	/** Beräkna avståndet mellan läkter/pannrader, eller användarens läktavstånd om fast läktavstånd är valt */
	// Paths:
	// TC1	Switch 1
	// TC1	case TM_STANDARD
	// TC5		If TF_PULT
	// TC1		Else
	// TC13	case TM_MANSARD
	// TC1	Switch 2
	// TC1	case TM_STANDARD
	// TC1		case TF_SADEL, TF_HALVVALM, TF_PULT
	// TC3			If Betongpanna && _Anvand_Gavel_Pannor && LäktAvstånd < minLäktAvståndGavelpanna
	// TC13	case TM_MANSARD
	// TC15		If _Anvand_Gavel_Pannor
	// TC16			case TF_SADEL
	// TC17				If LäktAvstånd < minLäktAvståndGavelpanna
	// TC17				If LäktAvstånd2 < minLäktAvståndGavelpanna
	// TC15			case TF_HALVVALM
	// TC15				If LäktAvstånd2 < minLäktAvståndGavelpanna
	public calculateLäktavstånd(): { läktavstånd1: number; läktavstånd2: number } {
		const fastAvst = this.roof.getLäktavstånd(); // TODO: Fast läktavstånd för mansard
		if (fastAvst != null) return { läktavstånd1: fastAvst, läktavstånd2: fastAvst };

		// Preliminär sättning av läktavstånd beroende på takvinkel och takpannetyp
		const cc = this.tile.maxLäktavstånd(this.roof.market, this.angle);

		const nockAvst =
			this.form === RoofForm.Pult ? -20 : this.tile.bärläktAvståndNock(this.angle, this.roof.underlagstak);
		const takfall = this.getTakfall().takfall - this.tile.förstaläkt(this.roof.market) - nockAvst;

		const rows = Math.ceil(takfall / cc);

		// Slutlig beräkning av läktavstånd när vi nu vet antal rader
		let läktavstånd = round(takfall / rows);
		let läktavstånd2 = 0;
		if (läktavstånd < this.tile.minLäktavstånd(this.roof.market)) läktavstånd = cc;

		// Gör ovan beräkningar men för övre halvan av taket om mansard
		if (this.model === RoofModel.Mansard) {
			const cc2 = this.tile.maxLäktavstånd(this.roof.market, this.angle2);
			const nockAvst2 = this.tile.bärläktAvståndNock(this.angle2, this.roof.underlagstak);
			const takfall2 = this.getTakfall().takfall2 - this.tile.förstaläkt(this.roof.market) - nockAvst2;
			const rows2 = Math.ceil(takfall2 / cc2);
			läktavstånd2 = round(takfall2 / rows2);
			if (läktavstånd2 < this.tile.minLäktavstånd(this.roof.market)) läktavstånd2 = cc2;
		}

		// Om betongpanna är valt och vi använder gavelpanna så måste läkavstånd vara minst 315mm
		if (this.model == RoofModel.Standard) {
			if (
				[RoofForm.Sadel, RoofForm.Halvvalm, RoofForm.Pult].includes(this.form) &&
				this.roof.selection.tileFamily.material === Material.Betong &&
				this.roof.selection.selGavelTillval === GavelTillval.Gavelpanna
			) {
				läktavstånd = Math.max(läktavstånd, 315);
				läktavstånd2 = Math.max(läktavstånd2, 315);
			}
		} else if (this.model == RoofModel.Mansard && this.roof.selection.selGavelTillval == GavelTillval.Gavelpanna) {
			if (this.form == RoofForm.Sadel) {
				läktavstånd = Math.max(läktavstånd, 315);
				läktavstånd2 = Math.max(läktavstånd2, 315);
			} else if (this.form == RoofForm.Halvvalm) {
				if (isCompatMode()) läktavstånd2 = Math.max(läktavstånd2, 315);
				else läktavstånd = Math.max(läktavstånd, 315);
			}
		}

		if (this.model !== RoofModel.Mansard) läktavstånd2 = 0;

		return { läktavstånd1: läktavstånd, läktavstånd2: läktavstånd2 };
	}

	/** Beräkna antal rader pannor för den här takdelen. Om fast läktavstånd är valt används det här. `includeDecimals` säger om decimaler ska returneras för taken som kan ha det */
	// Paths:
	// TC4	If Fast läktavstånd
	// TC1	Else
	// TC1	Switch
	// TC1	case TM_STANDARD
	// TC1		case TF_SADEL, TF_PULT
	// TC23			If TT_HUVUD && _HV_Tak
	// TC2		case TF_VALM
	// TC3		case TF_HALVVALM
	// TC25			If TT_HUVUD && _HV_Tak
	// TC13	case TM_MANSARD
	// TC14		case TF_VALM
	// TC15		case TF_HALVVALM
	public calculatePannrader(includeDecimals=false): RoofPannrader {
		const calc = { antalRader: 0, antalRader2: 0, antalRaderGavel: 0, antalRaderGavel2: 0, withDecimals: false };

		calc.withDecimals = this.roof.usesFastLäktavstånd() || this.roof.selection.tileFamily == RoofTile.Höganäs;

		// BENT-193: Avrunda med 0.1 som avbrytspunkt förutom om includeDecimals==true. Då vill vi visa "riktiga" värdet avrundat till 1 decimal
		const decimalsBase = includeDecimals && calc.withDecimals ? 10 : 1;
		const roundFn = (x: number) => {
			if (!calc.withDecimals)
				return round(x);

			return includeDecimals
				? round(x * decimalsBase) / decimalsBase
				: roundCutoff(x, 0.1);
		}

		const läktavstånd = this.calculateLäktavstånd();
		calc.antalRader = roundFn(this.getTakfall().takfall / läktavstånd.läktavstånd1);
		calc.antalRaderGavel = round(this.getTakfall().takfall / läktavstånd.läktavstånd1);
		if (this.form === RoofForm.Halvvalm) {
			calc.antalRader2 = roundFn(this.getTakfall().takfall2 / läktavstånd.läktavstånd1);
			calc.antalRaderGavel2 = round(this.getTakfall().takfall2 / läktavstånd.läktavstånd1);
		}

		return calc;
	}

	/** Beräkna antal kolumner pannor för den här takdelen, även om halvpannor bör användas för taket */
	// Paths:
	// TC1	Switch
	// TC1	case TM_STANDARD
	// TC1		case TT_HUVUD, TT_TILLBYGGNAD
	// TC1			case TF_SADEL, TF_PULT
	// TC8				If _Anvand_Gavel_Pannor
	// TC1				Else
	// TC23				If _HV_Tak
	// TC2			case TF_VALM
	// TC3			case TF_HALVVALM
	// TC3				If _Anvand_Gavel_Pannor
	// TC4				Else
	// TC4					If _Takpanna_familj_Har_Halvpannor
	// TC9					Else
	// TC28				If _HV_Tak
	// TC5		case TT_VINKEL, TT_KUPA, TT_HORNVINKEL
	// TC3			case TF_SADEL
	// TC3				If _Anvand_Gavel_Pannor
	// TC5				Else
	// TC4			case TF_VALM
	// TC6			case TF_HALVVALM
	// TC6				If _Anvand_Gavel_Pannor
	// TC7				Else
	// TC6			If _Anvand_Gavel_Pannor
	// TC7			Else
	// TC6		case TT_FRONT, TT_PULT
	// TC6			case TF_SADEL, TF_PULT
	// TC6				If _Anvand_Gavel_Pannor
	// TC7				Else
	// TC7			case TF_VALM
	// TC13	case TM_MANSARD
	// TC13		case TF_SADEL
	// TC16			If _Anvand_Gavel_Pannor
	// TC13			Else
	// TC13				If _Takpanna_familj_Har_Halvpannor
	// TC30				Else
	// TC14		case TF_VALM
	// TC15		case TF_HALVVALM
	// TC15			If _Anvand_Gavel_Pannor
	// TC31			Else
	// TC21				If _Takpanna_familj_Har_Halvpannor
	// TC31				Else
	public calculatePannkolumner(): {
		kolumner: number;
		kolumner2: number;
		kolumnerNock: number;
		kolumnerGavel: number;
		kolumnerGavel2: number;
		kolumnerVinkel: number;
		användHalvpanna: boolean;
	} {
		let res = {
			kolumner: 0,
			kolumner2: 0,
			kolumnerNock: 0,
			kolumnerGavel: 0,
			kolumnerGavel2: 0,
			kolumnerVinkel: 0,
			användHalvpanna: false,
		};

		if (this.model === RoofModel.Standard) {
			switch (this.form) {
				case RoofForm.Pult:
				case RoofForm.Sadel:
					if (this.roof.selection.selGavelTillval == GavelTillval.Gavelpanna)
						res = { ...res, ...this.calculatePannkolumnerGavelp(this.length) };
					/** Vi använder inte halvpannor till sadeltak */ else
						res = { ...res, ...this.calculatePannkolumnerSTDEjHalvpanna(this.length) };

					res.kolumnerNock = res.kolumner;

					break;
				case RoofForm.Valm:
					res.kolumner = this.calculatePannkolumnerSTD(this.length);
					res.kolumnerGavel = this.calculatePannkolumnerSTD(this.sideWidth());
					res.kolumnerNock = this.calculatePannkolumnerSTD(this.getNocklängd().nocklängd);

					break;
				case RoofForm.Halvvalm:
					if (this.roof.selection.selGavelTillval == GavelTillval.Gavelpanna) {
						res = { ...res, ...this.calculatePannkolumnerGavelp(this.length) };
					} else {
						if (this.roof.selection.tileFamily.harHalvpanna()) {
							res = { ...res, ...this.calculatePannkolumnerSTDHalvpanna(this.length) };
							res.kolumnerNock = this.calculatePannkolumnerSTDHalvpanna(this.getNocklängd().nocklängd).kolumner;
						} else {
							res = { ...res, ...this.calculatePannkolumnerSTDEjHalvpanna(this.length) };
							res.kolumnerNock = this.calculatePannkolumnerSTDEjHalvpanna(this.getNocklängd().nocklängd).kolumner;
						}
					}
					if (this.isHuvudHVTak())
						res.kolumnerGavel = this.calculatePannkolumnerSTDEjHalvpanna(this.sideWidth()).kolumner;

					res.kolumnerGavel2 = this.calculatePannkolumnerSTDEjHalvpanna(this.sideWidth2()).kolumner;

					break;
			}
		} else if (this.model === RoofModel.Mansard) {
			const length2 = this.length - this.sideWidth() * 2;

			switch (this.form) {
				case RoofForm.Sadel:
					if (this.roof.selection.selGavelTillval == GavelTillval.Gavelpanna) {
						res = { ...res, ...this.calculatePannkolumnerGavelp(this.length) };
						res.kolumner2 = res.kolumner;
					} else if (this.tile.harHalvpanna()) {
						res = { ...res, ...this.calculatePannkolumnerSTDHalvpanna(this.length) };
						res.kolumner2 = res.kolumner;
					} else {
						res = { ...res, ...this.calculatePannkolumnerSTDEjHalvpanna(this.length) };
						res.kolumner2 = res.kolumner;
					}

					res.kolumnerNock = res.kolumner;

					break;
				case RoofForm.Valm:
					res.kolumner = this.calculatePannkolumnerSTD(this.length);
					res.kolumner2 = this.calculatePannkolumnerSTD(length2);
					res.kolumnerNock = this.calculatePannkolumnerSTD(this.getNocklängd().nocklängd);
					res.kolumnerGavel = this.calculatePannkolumnerSTD(this.width);
					res.kolumnerGavel2 = this.calculatePannkolumnerSTD(this.width2);

					break;
				case RoofForm.Halvvalm:
					if (this.roof.selection.selGavelTillval == GavelTillval.Gavelpanna) {
						res = { ...res, ...this.calculatePannkolumnerGavelp(this.length) };
					} else {
						if (this.roof.selection.tileFamily.harHalvpanna()) {
							res = { ...res, ...this.calculatePannkolumnerSTDHalvpanna(this.length) };
						} else {
							res = { ...res, ...this.calculatePannkolumnerSTDEjHalvpanna(this.length) };
						}
					}
					res.kolumner2 = this.calculatePannkolumnerSTD(this.length);
					res.kolumnerNock = this.calculatePannkolumnerSTD(this.getNocklängd().nocklängd);
					res.kolumnerGavel2 = this.calculatePannkolumnerSTD(this.width2);

					break;
			}
		}

		return res;
	}

	/** Beräkna antal kolumner inklusive gavelpannor */
	// Paths:
	// TC3	If gavelpannaVänsterBredd < gavelpannaHögerBredd
	// TC10	Else
	// TC3	case PR_FAM_PALEMA, PR_FAM_EXKLUSIV, PR_FAM_MECKLENBURG, PR_FAM_TVILLING, PR_FAM_TVILLING_RAK
	// TC3		case TT_HUVUD, TT_FRONT, TT_PULT
	// TC3			If rest >= Takpanna_byggbredd_halvpanna
	// TC10		case TT_TILLBYGGNAD
	// ~			If rest > 50
	// ~				If
	// ~				Else
	// ~			Else
	// TC3		case TT_VINKEL, TT_KUPA, TT_HORNVINKEL
	// TC3			If rest > 0
	// TC5	case PF_FAM_CARISMA
	// TC4		case TT_HUVUD, TT_FRONT, TT_PULT
	// ~			If rest <= rest2
	// ~			Else
	// TC8		case TT_TILLBYGGNAD
	// ~			If rest > (Takpanna_max_rest / 2)
	// TC4		case TT_VINKEL, TT_KUPA, TT_HORNVINKEL
	// ~			If rest > 10
	// TC9	case PF_FAM_HANSA
	// TC9		case TT_HUVUD, TT_FRONT, TT_PULT
	// TC40		case TT_TILLBYGGNAD
	// -			If rest > (Takpanna_max_rest / 2)
	// TC9		case TT_VINKEL, TT_KUPA, TT_HORNVINKEL
	// ~			If rest > 0
	public calculatePannkolumnerGavelp(length: number): { kolumner: number; användHalvpanna: boolean } {
		const res = { kolumner: 0, användHalvpanna: false };

		// 'För tak med gavelpannor, Palema, Mecklenburg, Carisma, Hansa, Tvilling
		// 'Beräknar antal kolumner av takpannor, inkl. halvpannor

		const takpannaByggBredd = this.roof.selection.tileFamily.takpannaByggBredd();
		const halvpannaBredd = this.roof.selection.tileFamily.halvpannaByggBredd();
		const gavelpannabreddVänster = this.roof.selection.tileFamily.byggBreddGavelPannaVänster(true);
		const gavelpannabreddHalvVänster = this.roof.selection.tileFamily.byggBreddGavelPannaVänster(false);
		const gavelpannabreddHöger = this.roof.selection.tileFamily.byggBreddGavelPannaHöger(true);

		// 'Dessa är tvåkupiga och har halvpannor
		// 'Gavelpannor 265 + 295, Exklusiv 215 + 295 och tvilling 180 + 214
		// TODO: Har exklusiv halvpannor? Trodde den inte hade det
		if (
			[
				TileFamily.Palema,
				TileFamily.Exklusiv,
				TileFamily.TvillingFalsatLertegel,
				TileFamily.TvillingLertegelRakFramkant,
				TileFamily.Mecklenburger,
			].includes(this.roof.selection.tileFamily.family)
		) {
			// "Dessa är tvåkupiga och har halvpannor
			// "Gavelpannor 265 + 295, Exklusiv 215 + 295 och tvilling 180 + 214

			// Special case for some extension roofs
			if ([RoofType.VinkelTak, RoofType.Kupa, RoofType.HörnVinkel].includes(this.type)) {
				// Dessa tak har 1 gavel och därför bara 1 gavelpanna per rad och dessutom rändaler så de kapas där, använder inte halvpanna för optimering
				const längdMedHelpanna = length - Math.min(gavelpannabreddVänster, gavelpannabreddHöger);
				res.kolumner = Math.floor(längdMedHelpanna / takpannaByggBredd) + 1;

				// Om takets längd inte täcks så lägger vi till en kolumn
				if (längdMedHelpanna % takpannaByggBredd > 0) {
					res.kolumner++;
				}

			// Special case for Tillbyggnad
			} else if ([RoofType.Tillbyggnad].includes(this.type)) {
				// Dessa tak har 1 gavel och därför bara 1 gavelpanna per rad men kan optimeras med en halvpanna
				const längdMedHelpanna = length - Math.min(gavelpannabreddVänster, gavelpannabreddHöger);
				res.kolumner = Math.floor(längdMedHelpanna / takpannaByggBredd) + 1;

				// Om det som inte täcks av takets längd är samma eller längre än en halvpanna så lägger vi till en halvpanna.
				// Blir det ytterligare rest täcks den av plåt vid anslutningen till huvudtaket
				if (längdMedHelpanna % takpannaByggBredd > 50)
					if ((längdMedHelpanna % takpannaByggBredd) - halvpannaBredd < 50) {
						res.kolumner++;
						res.användHalvpanna = true;
					} else {
						res.kolumner++;
						res.användHalvpanna = false;
					}

			// General case
			} else {
				// "Dessa tak har 2 gavlar och därför 2 gavelpannor per rad
				const längdMedHelpanna = length - gavelpannabreddVänster - gavelpannabreddHöger;
				res.kolumner = Math.floor(längdMedHelpanna / takpannaByggBredd) + 2;

				// 'Om det som inte täcks av takets längd är samma eller längre än en halvpanna så lägger vi till en halvpanna.
				// 'Finns det ytterligare rest får taket kapas
				if (längdMedHelpanna % takpannaByggBredd >= halvpannaBredd) {
					res.kolumner++;
					res.användHalvpanna = true;
				}
			}

		// 'Teknik med hel eller halvpanna som gavelpanna ska användas.
		// '200 + 265   halv 75 + 140
		//
		// 'Hel+Hel ger  465 mm
		// 'Halv+hel ger 340 mm

		// Nya beräkningen, Piano kan ha gavelpannor så lägg till piano under samma hantering som Carisma
		} else if ([TileFamily.Carisma].includes(this.roof.selection.tileFamily.family)
				|| (!isCompatMode() && this.roof.selection.tileFamily.family == TileFamily.PianoFalsatLertegel)) {
			// Special cases for some roof types
			if ([RoofType.VinkelTak, RoofType.Kupa, RoofType.HörnVinkel].includes(this.type)) {
				// Dessa tak har i gavel och därför bara 1 gavelpanna per rad och dessutom rändaler så de kapas där
				// Dessa tak använder ej optimering med HalvHel
				const längdMedHelpanna = this.length - Math.min(gavelpannabreddVänster, gavelpannabreddHöger);
				res.kolumner = Math.floor(längdMedHelpanna / takpannaByggBredd) + 1;
				const rest = längdMedHelpanna % takpannaByggBredd;

				if (rest > 10) res.kolumner++;

				// Special cases for Tillbyggnad
			} else if ([RoofType.Tillbyggnad].includes(this.type)) {
				// Dessa tak har 1 gavel och därför bara 1 gavelpanna per rad men kan optimeras med gavelpanna
				const längdMedHelpanna = this.length - Math.min(gavelpannabreddVänster, gavelpannabreddHöger);
				res.kolumner = Math.floor(längdMedHelpanna / takpannaByggBredd) + 1;
				const rest = längdMedHelpanna % takpannaByggBredd;

				if (rest > this.roof.selection.tileFamily.maxRest / 2) {
					res.kolumner += 1;
					res.användHalvpanna = true;
				}

			// General case
			} else {
				// Dessa tak har 2 gavlar och därför 2 gavelpannor per rad. Gör två kalkyler, den som ger minst rest tar vi.
				// Test hel+hel
				let längdMedHelpanna = this.length - gavelpannabreddVänster - gavelpannabreddHöger;
				const antal1 = Math.floor(längdMedHelpanna / takpannaByggBredd);
				const rest1 = längdMedHelpanna % takpannaByggBredd;

				// Test hel+halv
				längdMedHelpanna = this.length - gavelpannabreddHalvVänster - gavelpannabreddHöger;
				const antal2 = Math.floor(längdMedHelpanna / takpannaByggBredd);
				const rest2 = längdMedHelpanna % takpannaByggBredd;

				if (rest1 <= rest2) res.kolumner = antal1 + 2;
				else {
					res.kolumner = antal2 + 2;
					res.användHalvpanna = true;
				}
			}
		} else if ([TileFamily.HansaFalsatLertegel].includes(this.roof.selection.tileFamily.family)) {
			// Special cases for some roof types
			if ([RoofType.VinkelTak, RoofType.Kupa, RoofType.HörnVinkel].includes(this.type)) {
				// Dessa tak har i gavel och därför bara 1 gavelpanna per rad och dessutom rändaler så de kapas där
				const längdMedHelpanna = this.length - Math.min(gavelpannabreddVänster, gavelpannabreddHöger);
				res.kolumner = Math.floor(längdMedHelpanna / takpannaByggBredd) + 1;
				const rest = längdMedHelpanna % takpannaByggBredd;

				if (rest > 0) res.kolumner++;

			// Special cases for Tillbyggnad
			} else if ([RoofType.Tillbyggnad].includes(this.type)) {
				// Dessa tak har 1 gavel och därför bara 1 gavelpanna per rad
				const längdMedHelpanna = this.length - Math.min(gavelpannabreddVänster, gavelpannabreddHöger);
				res.kolumner = Math.floor(längdMedHelpanna / takpannaByggBredd) + 1;
				const rest = längdMedHelpanna % takpannaByggBredd;

				if (rest > this.roof.selection.tileFamily.maxRest / 2) res.kolumner += 1;

			// General case
			} else {
				// Dessa tak har 2 gavlar och därför 2 gavelpannor per rad
				const längdMedHelpanna = this.length - gavelpannabreddVänster - gavelpannabreddHöger;
				res.kolumner = Math.floor(längdMedHelpanna / takpannaByggBredd) + 2;
			}
		}

		return res;
	}

	/** Beräkna antal kolumner för tak med halvpannor men utan gavelpannor */
	// Paths:
	// 	If rest < takpanna_max_rest
	// 	Else
	// 		If ny_rest < 0
	// 		Elseif ny_rest <= takpanna_max_rest
	// 		Else
	public calculatePannkolumnerSTDHalvpanna(length: number): {
		kolumner: number;
		kolumnerGavel: number;
		kolumnerGavel2: number;
		användHalvpanna: boolean;
	} {
		const res = { kolumner: 0, kolumnerGavel: 0, kolumnerGavel2: 0, användHalvpanna: false };

		// 'Används för Sadeltak, Halvvalm, Pult, Front, alla har två gavlar
		// 'Alltså denna används ej för valmtak, vinkel eller hörnvinkel, eller kupa då de har rändaler och skärs där

		const netto_length = length - (this.tile.bredd - this.tile.takpannaByggBredd());
		res.kolumner = Math.ceil(netto_length / this.tile.takpannaByggBredd());
		const rest = netto_length % this.tile.takpannaByggBredd();

		// 'Om det blir en spalt som ej täcks som är större än vi får ha
		if (rest <= this.tile.maxRest) return res; // 'det räcker
		else {
			// 'Det räcker inte och vi kommer behöva lägga till en panna av hel eller halv så vi ska testa vilken
			const ny_rest = (netto_length - this.tile.halvpannaByggBredd()) % this.tile.takpannaByggBredd();
			if (ny_rest < 0) {
				// 'vi behöver kapa och då väljer vi helpannor
				res.kolumner++;
				res.användHalvpanna = false;
			} else if (ny_rest <= this.tile.maxRest) {
				// 'en halvpanna räcker
				res.kolumner++;
				res.användHalvpanna = true;
			} else {
				// 'vi behöver lägga till en kolumn med helpannor
				res.kolumner++;
				res.användHalvpanna = false;
			}
		}

		return res;
	}

	/** Beräkna antal kolumner utan halvpannor eller gavelpannor */
	// Paths:
	// TC3	If rest > takpanna_max_rest
	public calculatePannkolumnerSTDEjHalvpanna(length: number): { kolumner: number; användHalvpanna: boolean } {
		const res = { kolumner: 0, användHalvpanna: false };

		// 'För tak utan gavelpannor och halvpannor
		// 'Används för Sadeltak, Halvvalm, Pult, Front, alla har två gavlar
		// 'Alltså denna används ej för valmtak, vinkel eller hörnvinkel, eller kupa då de har rändaler och skärs där

		const netto_length = length - (this.tile.bredd - this.tile.takpannaByggBredd());
		if (this.roof.selection.tileFamily == RoofTile.Höganäs) {
			// BENT-199: Avrunda med 0.1 som cutoff punkt för höganäs
			res.kolumner = roundCutoff(netto_length / this.tile.takpannaByggBredd(), 0.1);
		}else {
			res.kolumner = Math.floor(netto_length / this.tile.takpannaByggBredd());
		}
		if (netto_length - res.kolumner * this.tile.takpannaByggBredd() > this.tile.maxRest) res.kolumner++;

		return res;
	}

	/** Beräkna antal kolumner utan halvpannor eller gavelpannor, tar en längd parameter så valmade tak kan beräkna kortsidans kolumner */
	// Paths:
	// 	If rest > 10
	public calculatePannkolumnerSTD(length: number): number {
		if (length <= 0) return 0; // TODO: Gamla beräkning saknar en sån här check vilket kan resultera i -1 kolumner som return värde. Ser ut som det
		// möjligtvis kan bli ett problem för nockpannekolumnvärdet som används för att beräkna nockanslutningspannor för
		// hansa och tvilling pannorna. Testa att lägga till en valmad frontdel som använder hansa eller tvilling och se om
		// ett negativt antal nockanslutningspannor läggs till

		// 'För tak utan gavelpannor och halvpannor skall ej användas, t.ex. Valm och Vinkel

		const nettoLength = length - (this.tile.bredd - this.tile.takpannaByggBredd());
		let columns = 0;
		if (this.roof.selection.tileFamily == RoofTile.Höganäs) {
			// BENT-199: Avrunda med 0.1 som cutoff punkt för höganäs
			columns = roundCutoff(nettoLength / this.tile.takpannaByggBredd(), 0.1);
		}else {
			columns = Math.floor(nettoLength / this.tile.takpannaByggBredd());
		}
		if (nettoLength - columns * this.tile.takpannaByggBredd() > 10) columns++;

		return columns;
	}

	/** Beräkna totala längden bärläkter (horisontella läkter) för taket. */
	// Paths:
	// TC1	case TM_STANDARD
	// TC13	case TM_MANSARD
	// TC14		If TF_VALM
	// TC13		Else
	// TC3	Ränndalar
	// TC3	Switch
	// TC3	case TT_KUPA, TT_VINKEL, TT_HORNVINKEL
	// TC6	case TT_FRONT
	public calculateBärläkt(includeSvinn = false) {
		let bärläkt = 0;

		if (this.model === RoofModel.Standard) {
			bärläkt = (((this.calculatePannrader().antalRader + 1) * this.length) / 1000) * this.sides;
		} else if (this.model === RoofModel.Mansard) {
			if (this.form === RoofForm.Valm)
				bärläkt =
					(((this.calculatePannrader().antalRader + 1) * (this.length - (this.sideWidth() - this.sideWidth2()))) /
						1000) *
						this.sides +
					(((this.calculatePannrader().antalRader2 + 1) *
						(this.length + this.sideWidth() - (this.sideWidth() - this.sideWidth2()))) /
						1000) *
						this.sides;
			else
				bärläkt =
					(((this.calculatePannrader().antalRader + 1) * this.length) / 1000) * this.sides +
					(((this.calculatePannrader().antalRader2 + 1) * this.length) / 1000) * this.sides;
		}

		bärläkt += ((this.rändaler * this.getRändalslängd()) / 1000) * this.sides;

		// Avdrag för anslutningen mot huvudtak
		if ([RoofType.Kupa, RoofType.VinkelTak, RoofType.HörnVinkel].includes(this.type) && this.roof.mainPart) {
			const antalRader = round(
				this.getTakfallAnslutning().takfallAnslutning / this.roof.mainPart.calculateLäktavstånd().läktavstånd1 - 1
			);
			bärläkt -= (this.sideWidth() / 1000) * antalRader;
		} else if ([RoofType.Front].includes(this.type) && this.roof.mainPart) {
			const antalRader = round(
				this.getTakfallAnslutning().takfallAnslutning / this.roof.mainPart.calculateLäktavstånd().läktavstånd1 - 1
			);
			bärläkt -= (this.length / 1000) * antalRader;
		}

		if (includeSvinn) bärläkt *= svinn.bärläkt;

		return bärläkt;
	}

	/** Beräkna totala längden ströläktar (vertikala läkter) för taket. */
	// Paths:
	// TC1	case UNDERTAK_RASPONT
	// TC3	case UNDERTAK_INGET, UNDERTAK_LÄTT
	// TC4	case UNDERTAK_LÄTT_DIST
	// TC1	switch
	// TC1	case TM_STANDARD
	// TC13	case TM_MANSARD
	// TC13		case TF_SADEL
	// TC14		case TF_VALM, TF_HALVVALM
	// TC3	switch
	// TC3	case TT_VINKEL, TT_KUPA, TT_HORNVINKEL
	// TC6	case TT_FRONT
	// TC6	switch
	// TC6	case TT_FRONT, TT_PULT
	// TC7		case TF_VALM
	public calculateStröläkt(includeSvinn = false) {
		let cc = 1200;
		switch (this.roof.underlagstak) {
			// Om inget eller "Lätt utan ströläkt" är valt som underlagstak så ska vi inte ge ett värde här
			case Underlagstak.None:
			case Underlagstak.LättUtanStröläkt:
				return 0;
			case Underlagstak.RåspontMedPapp:
				cc = 600;
				break;
			case Underlagstak.LättMedStröläkt:
				cc = 1200;
				break;
		}

		let längdStröläkt = 0;
		if (this.model == RoofModel.Standard) {
			const antalPerSida = Math.ceil(this.length / cc) + 1;
			längdStröläkt = ((antalPerSida * this.getTakfall().takfall) / 1000) * this.sides;
		} else if (this.model == RoofModel.Mansard) {
			const antalPerSida = Math.ceil(this.length / cc) + 1;
			let antalPerSida2 = 0;
			if (this.form == RoofForm.Sadel) antalPerSida2 = Math.ceil(this.length / cc) + 1;
			else antalPerSida2 = Math.ceil((this.length - this.sideWidth() * 2) / cc) + 1;

			längdStröläkt =
				((antalPerSida * this.getTakfall().takfall) / 1000) * this.sides +
				((antalPerSida2 * this.getTakfall().takfall2) / 1000) * this.sides;
		}

		const nockbenLängd = this.getNockbenLängd();
		längdStröläkt +=
			((nockbenLängd.nockbenLängd * this.nockben +
				nockbenLängd.nockben2Längd * this.nockben2 +
				nockbenLängd.nockbenLängdHV) /
				1000) *
			2;

		längdStröläkt += (this.getRändalslängd() / 1000) * this.rändaler * this.sides;

		if ([RoofType.VinkelTak, RoofType.Kupa, RoofType.HörnVinkel].includes(this.type)) {
			const antalStrl = Math.floor(this.sideWidth() / cc);
			längdStröläkt -= (antalStrl * this.getTakfallAnslutning().takfallAnslutning) / 1000;
		} else if (this.type == RoofType.Front) {
			const antalStrl = Math.floor(this.length / cc);
			längdStröläkt -= (antalStrl * this.getTakfallAnslutning().takfallAnslutning) / 1000;
		}

		if ([RoofType.Front, RoofType.PultDel].includes(this.type) && this.form === RoofForm.Valm) {
			längdStröläkt += (this.getNockbenLängd().nockbenLängd / 1000) * this.sides * 2;
		}

		if (includeSvinn) längdStröläkt *= svinn.ströläkt;

		return längdStröläkt;
	}

	/** Complete roof area in mm² */
	// Paths:
	// TC1	case TM_STANDARD
	// TC13	case TM_MANSARD
	// TC13		case TF_SADEL, TF_HALVVALM
	// TC14		case TF_VALM
	// TC3	switch
	// TC3	case TT_VINKEL, TT_HORNVINKEL, TT_KUPA
	// TC6	case TT_FRONT
	// TC3	switch
	// TC3	case TT_VINKEL, TT_HORNVINKEL
	// TC5	case TT_KUPA
	// TC6	case TT_FRONT
	public getRoofArea(): RoofArea {
		let area = 0;
		let area2 = 0;
		if (this.model == RoofModel.Standard) area = this.length * this.getTakfall().takfall * this.sides;
		else if (this.model == RoofModel.Mansard) {
			if (this.form == RoofForm.Valm) {
				const taklangd2 = this.length - (this.width - this.width2);
				area = ((this.length + taklangd2) / 2) * this.getTakfall().takfall * this.sides;
				area += ((this.width + this.width2) / 2) * this.getTakfall().takfall * this.getGavlar();
				area2 = taklangd2 * this.getTakfall().takfall2 * this.sides;
			} else {
				area = this.length * this.getTakfall().takfall * this.sides;
				area2 = this.length * this.getTakfall().takfall2 * this.sides;
			}
		}

		// Lägg till arean för delen av utbyggnadstak som går över huvudtaket
		if ([RoofType.VinkelTak, RoofType.HörnVinkel, RoofType.Kupa].includes(this.type))
			area += this.getUtbyggnadExtraLängd() * this.getTakfall().takfall;
		if ([RoofType.Front].includes(this.type) && this.form === RoofForm.Valm)
			area += this.getRändalslängd() * this.getTakfall().takfallGavel;

		// Ta bort area för anslutning mot huvudtaket
		if ([RoofType.VinkelTak, RoofType.HörnVinkel].includes(this.type))
			area -= this.sideWidth() * this.getTakfallAnslutning().takfallAnslutning;
		if ([RoofType.Kupa].includes(this.type))
			area -=
				this.sideWidth() * this.getTakfallAnslutning().takfallAnslutning2 +
				this.width * this.getTakfallAnslutning().takfallAnslutning;
		if ([RoofType.Front].includes(this.type)) area -= this.length * this.getTakfallAnslutning().takfallAnslutning;

		return { area, area2 };
	}

	/** Length of nock part */
	// Paths:
	// TC1	case TT_HUVUD, TT_VINKEL, TT_KUPA, TT_HORNVINKEL
	// TC1		case TF_SADEL
	// TC2		case TF_VALM
	// TC3		case TF_HALVVALM
	// TC5		case TF_PULT
	// TC23		If TT_HUVUD && _HV_Tak
	// TC23			case TF_SADEL, TF_PULT
	// TC7	case TT_PULT
	public getNocklängd(): RoofNockLängd {
		const calc = { nocklängd: 0, pultlängd: 0 };
		if (
			[
				RoofType.Sadel,
				RoofType.Valm,
				RoofType.Halvvalm,
				RoofType.Mansard,
				RoofType.MansardValm,
				RoofType.MansardHalvvalm,
				RoofType.Pulttak,
				RoofType.VinkelTak,
				RoofType.Front,
				RoofType.Kupa,
				RoofType.Tillbyggnad,
				RoofType.HörnVinkel,
			].includes(this.type)
		) {
			switch (this.form) {
				case RoofForm.Sadel:
					calc.nocklängd = this.length;
					break;
				case RoofForm.Valm:
					calc.nocklängd = this.length - (this.width * this.getGavlar()) / 2;
					break;
				case RoofForm.Halvvalm:
					calc.nocklängd = this.length - (this.width2 * this.getGavlar()) / 2;
					break;
				case RoofForm.Pult:
					calc.pultlängd = this.length;
					break;
			}

			// Dra av en bit för hörnvinkel på sadel och pulttak
			if (this.isHuvudHVTak() && [RoofForm.Sadel, RoofForm.Pult].includes(this.form)) calc.nocklängd -= this.width / 2;

			calc.nocklängd += this.getUtbyggnadExtraLängd();
		} else if (this.type === RoofType.PultDel) {
			calc.pultlängd = this.length;
		}

		return calc;
	}

	/** Calculate the additional nock length extension roofs needed to connect to the main nock  */
	// Paths:
	// TC3	Case TT_VINKEL, TT_KUPA, TT_HORNVINKEL
	public getUtbyggnadExtraLängd() {
		if ([RoofType.VinkelTak, RoofType.Kupa, RoofType.HörnVinkel].includes(this.type) && this.roof.mainPart != null)
			return round(this.getHeight().height / Math.tan(this.roof.mainPart.angleInRads()));

		return 0;
	}

	/** Beräknar takfallet för en utbyggnads anslutning mot huvudtaket */
	// Paths:
	// TC3	Case TT_VINKEL, TT_HORNVINKEL
	// TC6	Case TT_FRONT
	// TC5	Case TT_KUPA
	public getTakfallAnslutning(): { takfallAnslutning: number; takfallAnslutning2: number } {
		const calc = { takfallAnslutning: 0, takfallAnslutning2: 0 };
		if ([RoofType.VinkelTak, RoofType.HörnVinkel].includes(this.type) && this.roof.mainPart)
			calc.takfallAnslutning = round(this.getUtbyggnadExtraLängd() / Math.cos(this.roof.mainPart.angleInRads()));
		if (this.type == RoofType.Front && this.roof.mainPart)
			calc.takfallAnslutning = round(this.sideWidth() / Math.cos(this.roof.mainPart.angleInRads()));
		if (this.type == RoofType.Kupa && this.roof.mainPart) {
			calc.takfallAnslutning = round(this.length / Math.cos(this.roof.mainPart.angleInRads()));
			calc.takfallAnslutning2 = round(this.getUtbyggnadExtraLängd() / Math.cos(this.roof.mainPart.angleInRads()));
		}

		return calc;
	}

	/** Calculate number of nockpannor for all roofs */
	// Paths:
	// TC5	If TF_PULT
	// TC6	If PR_FAM_CARISMA && Use_Nock_Panna_STD
	// TC1	Else
	// TC7	If TF_PULT || TT_PULT
	// TC1	Else
	// TC1		If
	// 		Else
	// TC2	If Nockben > 0
	// 		If
	// 		Else
	// TC3	If Nockben2 > 0
	// 		If
	// 		Else
	//	(Nockbenlängd_hv)
	//	If
	//	Else
	// TC1	case TM_STANDARD
	// TC1		case TF_SADEL
	// TC6			case TT_FRONT, TT_PULT
	// TC1			Default
	// TC8				If Gavelpanna || Gavelbeslag
	// TC32					If TT_HUVUD && HV_Tak
	// TC3					Else
	//						If Gavlar = 2 // Pultdel sadel, gavelpanna
	// TC1				Else
	// TC23					If TT_HUVUD && HV_Tak
	// TC2		case TF_VALM
	// TC7			case TT_FRONT, TT_PULT
	// TC2			Default
	//				If HV_Tak
	//					If
	//					Else
	// TC2				Else
	// TC4					If Bredd == Taklängd
	// TC2					Else
	// TC3		case TF_HALVVALM
	// TC6		if TT_VINKEL
	//			If Höjd == Huvudtak.Höjd
	// TC13	case TM_MANSARD
	// TC16		case TF_SADEL
	// TC16			If Gavelpanna || Gavelbeslag
	// TC16				If Gavlar == 2
	// TC14		case TF_VALM
	//			If Bredd == Taklängd
	// TC14			Else
	// TC15		case TF_HALVVALM
	public calculateNockpannor(doBasicFormsCalc = false) {
		const calc = {
			[RoofArticle.NockPanna]: 0,
			[RoofArticle.NockBörjan]: 0,
			[RoofArticle.NockSlut]: 0,
			[RoofArticle.BörjanValm]: 0,
			[RoofArticle.XNock]: 0,
			[RoofArticle.TNock]: 0,
			[RoofArticle.ValmKlocka]: 0,
		};

		if (this.form == RoofForm.Pult) return calc;

		const nockArticle =
			this.roof.selection.selNockTile == NockPannaVariant.Standard ? RoofArticle.NockPanna : RoofArticle.NockPannaAlt;
		let pannbredd = ByggBredd[this.roof.selection.tileFamily.itemPrefixNo(nockArticle)];
		let pannbreddNockben = ByggBredd[this.roof.selection.tileFamily.itemPrefixNo(nockArticle)];
		const nocklängd = this.getNocklängd();
		const nockbenlängd = this.getNockbenLängd();

		// "Om takpannan är av typ Carisma så ska nockpannorna ligga kant i kant och för nockben ha ett överlapp som gör att varje panna räknas som 370mm"
		if (this.roof.selection.tileFamily.family == TileFamily.Carisma && this.roof.selection.selNockTile == NockPannaVariant.Standard) {
			pannbreddNockben = pannbredd - 60;
		} else {
			pannbreddNockben = pannbredd;
		}

		// I nya takberäkningen sköter vi Carisma pannor annorlunda (rad 34), användaren kan välja om de ska placeras med överlapp eller inte, med överlapp placeras
		// 2.8st/m (357mm, vi avrundar till 360mm), utan 2.4st/m (416.66mm bredd, vi använder 420mm måttet som skrivs i produktinfon).
		if (!isCompatMode() && this.roof.selection.tileFamily.family == TileFamily.Carisma && this.roof.selection.selNockTile == NockPannaVariant.Standard) {
			if (this.roof.selection.selOverlapCarisma)
				pannbredd = 360;
			else
				pannbredd = ByggBredd[this.roof.selection.tileFamily.itemPrefixNo(nockArticle)];
		}

		let antal = 0;
		if (this.type !== RoofType.PultDel) {
			antal = Math.floor(nocklängd.nocklängd / pannbredd);

			// Om rest ≥ 20, addera 1
			if (nocklängd.nocklängd % pannbredd >= 20) antal++;
			calc[RoofArticle.NockPanna] = antal;
		}

		if (this.nockben > 0) {
			let antal = Math.floor(nockbenlängd.nockbenLängd / pannbreddNockben);
			const rest = nockbenlängd.nockbenLängd % pannbreddNockben;
			if (rest >= 20) antal++;
			calc[RoofArticle.NockPanna] += antal * this.nockben;
		}

		if (this.nockben2 > 0) {
			let antal = Math.floor(nockbenlängd.nockben2Längd / pannbreddNockben);
			const rest = nockbenlängd.nockben2Längd % pannbreddNockben;
			if (rest >= 20) antal++;
			calc[RoofArticle.NockPanna] += antal * this.nockben2;
		}

		// Lägg till extra nockpannor för hörnvinkeldels anslutning
		const hvNockpannor = Math.floor(nockbenlängd.nockbenLängdHV / pannbreddNockben);
		if (nockbenlängd.nockbenLängdHV % pannbreddNockben < 20) calc[RoofArticle.NockPanna] += hvNockpannor;
		else calc[RoofArticle.NockPanna] += hvNockpannor + 1;

		/** The above code is executed for every roof type but some special cases exist for specific roof types. Those are implemented in the
		 * respective child classes */

		if (!doBasicFormsCalc) return calc;

		if (this.form == RoofForm.Sadel) {
			// Om gavelpanna eller gavelbeslag är valt:
			if ([GavelTillval.Gavelpanna, GavelTillval.Gavelbeslag].includes(this.roof.selection.selGavelTillval)) {
				if (this.isHuvudHVTak()) {
					calc[RoofArticle.NockPanna] -= 1;
					calc[RoofArticle.NockSlut] = 1;
				} else {
					calc[RoofArticle.NockPanna] -= this.getGavlar();
					calc[RoofArticle.NockSlut] = 1;
					if (this.getGavlar() == 2) calc[RoofArticle.NockBörjan] = 1;
				}
			} else if (this.isHuvudHVTak()) {
				calc[RoofArticle.NockPanna] -= this.nockben;
				calc[RoofArticle.BörjanValm] += this.nockben;
				calc[RoofArticle.ValmKlocka] = this.roof.numHVTak();
			}
		} else if (this.form == RoofForm.Valm) {
			if (this.roof.isHVTak()) {
				if (!this.isMainPart()) calc[RoofArticle.ValmKlocka] = 1;
			} else {
				if (isCompatMode() ? this.sideWidth() == this.length : this.getNocklängd().nocklängd == 0) calc[RoofArticle.XNock] = 1;
				else calc[RoofArticle.ValmKlocka] = this.getGavlar();
			}

			// Byt ut sista nockbenspannan med en börjanvalm för varje nockben
			calc[RoofArticle.BörjanValm] = this.nockben;
			calc[RoofArticle.NockPanna] -= this.nockben;
		} else if (this.form == RoofForm.Halvvalm) {
			calc[RoofArticle.NockPanna] -= this.nockben + this.nockben2;
			calc[RoofArticle.BörjanValm] = this.nockben + this.nockben2;
			calc[RoofArticle.ValmKlocka] = this.getGavlar();
		}

		return calc;
	}

	/** Calculate takpannor for this roof */
	// Paths:
	// TC1	switch:
	// TC1	case TM_STANDARD:
	// TC1		case TT_HUVUD:
	// TC1			case TF_SADEL:
	// TC23				if HV_Tak
	// TC1				else
	// TC2			case TF_VALM:
	// TC3			case TF_HALVVALM:
	// TC5			case TF_PULT:
	// TC3		case TT_VINKEL:
	// TC3			case TF_SADEL:
	// TC4			case TF_VALM:
	// TC6			case TF_HALVVALM:
	// TC5		case TT_KUPA:
	// TC5			case TF_SADEL:
	// -			case TF_VALM: Valm verkar inte finnas för den här delen
	// TC7			case TF_HALVVALM:
	// TC6		case TT_FRONT:
	// TC7		case TT_PULT:
	// TC7			case TF_SADEL:
	// TC8			case TF_VALM:
	// TC8		case TT_TILLBYGGNAD:
	// TC8			case TF_SADEL:
	// TC10			case TF_VALM:
	// TC11			case TF_HALVVALM:
	// TC23		case TT_HORNVINKEL:
	// TC23			case TF_SADEL:
	// TC24			case TF_VALM:
	// TC25			case TF_HALVVALM:
	// TC13	case TM_MANSARD:
	// TC13		case TF_SADEL:
	// TC14		case TF_VALM:
	// TC15		case TF_HALVVALM:
	// TC1	switch:
	// TC1	case PALEMA, MECKLENBURG, TVILLING:
	// TC4	case CARISMA, PIANO
	// TC4		If flgHalvPanna
	// TC3	Default
	public abstract calculateTakpannor(halvPannor: number): number;

	/** Beräkna halvpannor, ska endast användas om användHalvpanna är satt
	 * "OBS denna beräkning skall användas före beräkning av takpannor eftersom det beräknats ett antal halvpannor så skall beräkning av takpannor ta hänsyn till detta"
	 * "Antal halvpannor ersätter behovet av motsvarande antal helpannor då det blev bäst med halvpanna, gäller även de för vinkeltak"
	 **/
	// Paths:
	// TC1	case TM_STANDARD
	// TC1		case TT_HUVUD
	// TC1			case TF_SADEL
	// TC28			case TF_HALVVALM
	// TC29			case TF_PULT
	// TC28		case TT_VINKEL, TT_KUPA
	// TC28		case TT_FRONT, TT_PULT
	// TC28		case TT_TILLBYGGNAD
	// TC28			case TF_SADEL
	// TC29			case TF_HALVVALM
	// TC23		case TT_HORNVINKEL
	// TC13	case TM_MANSARD
	// TC13		case TF_SADEL
	// TC20		case TF_HALVVALM
	// TODO: Ran for Palema|Exklusiv & användHalvpannor
	public calculateHalvpannor(): number {
		return round(this.calculatePannrader().antalRader * this.sides);
	}

	/** Beräkna halvpannor för Carisma/Piano tak. Dessa tak har förskjuten läggning och behöver en halvpanna per rad. */
	// Paths:
	// TC4	case TM_STANDARD
	// TC4		case TT_HUVUD
	// TC26			case TF_SADEL
	// TC4			case TF_HALVVALM
	// TC5			case TF_PULT
	// TC5		case TT_VINKEL, TT_KUPA
	// TC5			case TF_SADEL
	// TC7			case TF_HALVVALM
	// TC7		case TT_FRONT, TT_PULT
	// TC27		case TT_TILLBYGGNAD
	// TC41			case TF_SADEL
	// TC27			case TF_HALVVALM
	// TC26		case TT_HORNVINKEL
	// TC26			case TF_SADEL
	// TC27			case TF_HALVVALM
	// TC14	case TM_MANSARD
	// TC19		case TF_SADEL
	// TC21		case TF_HALVVALM
	// TODO: Ran for Carisma|Piano & !Gavelpannor
	public calculateHalvpannorCarismaPiano(): number {
		const rader = this.calculatePannrader();

		if ([RoofType.Front, RoofType.PultDel].includes(this.type)) {
			return round(rader.antalRader * this.sides);
		} else if ([RoofForm.Sadel, RoofForm.Halvvalm, RoofForm.Pult].includes(this.form)) {
			let hpRader = rader.antalRader - rader.antalRader2;
			if (this.form == RoofForm.Halvvalm) hpRader++;
			return round(hpRader * this.sides);
		}

		return 0;
	}

	/** Beräkna halvpannor för valmade tak */
	// Paths:
	// TC2	case TM_STANDARD
	// TC2		case TT_HUVUD
	// TC2			case TF_VALM
	// TC3			case TF_HALVVALM
	// TC23		case TT_HORNVINKEL
	// TC23			case TF_SADEL, TF_HALVVALM
	// TC24			case TF_VALM
	// TC24			Select
	// TC24			case TF_VALM
	// TC25			case TF_HALVVALM
	// TC28		default
	// TC28			case TF_VALM
	// TC28			case TF_HALVVALM
	// TC15	case TM_MANSARD
	// TC22		case TF_VALM
	// TC15		case TF_HALVVALM
	// TODO: Ran for Palema|Exklusiv
	public calculateHalvpannorValm(calc: ArticleSum): void {
		if (this.form === RoofForm.Valm) {
			const varannanRad = Math.ceil(this.calculatePannrader().antalRader / 2);
			calc[RoofArticle.HalvPanna] = varannanRad * 2 * this.nockben;
		}
		if (this.form === RoofForm.Halvvalm) {
			const varannanRad = Math.ceil(this.calculatePannrader().antalRader2 / 2);
			calc[RoofArticle.HalvPanna] = varannanRad * 2 * this.nockben2;
		}
	}

	/** Beräkna halvpannor för vinkeltak */
	// Paths:
	// TC4	Case TT_VINKEL, TT_KUPA
	// TC23	Case TT_HORNVINKEL
	// TC23		Case TF_SADEL
	// TODO: Ran for Palema|Exklusiv
	public calculateHalvpannorVinkel(): number {
		if ([RoofType.VinkelTak, RoofType.Kupa].includes(this.type))
			return Math.ceil(this.calculatePannrader().antalRader / 2) * 2 * 2;

		return 0;
	}

	/** Beräkna pultnockpannor, används endast av Tyskland */
	public calculatePultPannor(calc: ArticleSum): void {
		const kolumner = this.calculatePannkolumner();

		if (this.roof.selection.selGavelTillval !== GavelTillval.None) {
			calc[RoofArticle.PultPanna] = kolumner.kolumnerNock - 2;
			calc[RoofArticle.PultPannaGavelHöger] = 1;
			calc[RoofArticle.PultPannaGavelVänster] = 1;

			if (this.roof.selection.tileFamily.family == TileFamily.Carisma) {
				calc[RoofArticle.PultPannaHalv] = 1;
				calc[RoofArticle.PultPannaGavelHalvHöger] = 1;
				calc[RoofArticle.PultPannaGavelHalvVänster] = 1;
			}
		}else {
			calc[RoofArticle.PultPanna] = kolumner.kolumnerNock;
		}
	}

	/** Total längd av alla takfötter på taket */
	// Paths:
	// TC1	case TM_STANDARD
	// TC1		case TF_SADEL
	// TC23			If TT_HUVUD && _HV_Tak
	// TC2		case TF_VALM
	// TC3		case TF_HALVVALM
	// TC25			If TT_HUVUD && _HV_Tak
	// TC5		case TF_PULT
	// TC13	case TM_MANSARD
	// TC13		case TF_SADEL
	// TC14		case TF_VALM
	// TC20		case TF_HALVVALM
	// TC3	if TT_VINKEL || TT_HORNVINKEL
	public getTakfotslängd() {
		if (this.form == RoofForm.Sadel) {
			let length = this.length * this.sides;
			if (this.isHuvudHVTak()) length += this.width;
			return length;
		}
		if (this.form == RoofForm.Valm) return this.sides * this.length + this.getGavlar() * this.width;
		if (this.model == RoofModel.Standard && this.form == RoofForm.Halvvalm) {
			let length = this.sides * this.length + this.getGavlar() * this.width2;
			if (this.isHuvudHVTak()) length += this.width;
			return length;
		}
		if (this.model == RoofModel.Mansard && this.form == RoofForm.Halvvalm) return this.sides * this.length;
		if (this.form == RoofForm.Pult) return this.length;
		return 0;
	}

	/** Lista av de individuella takfotslängderna på taket. */
	public getTakfotslängder(): number[] {
		let längder: number[] = [];
		switch (this.form) {
		case RoofForm.Sadel:
		case RoofForm.Halvvalm:
			längder = [this.length, this.length];
			break;
		case RoofForm.Valm:
			längder = [this.length, this.length, this.width, this.width];

			// Remove one of the sides if a hörnvinkel exists, we count it as part of the extension, not the main roofpart
			// (Should only remove the short side to stop at 2)
			if (this.isMainPart()) {
				const numHVParts = this.roof.extensions.filter(ext => ext.type === RoofType.HörnVinkel);
				for (let i = 0; i < Math.min(numHVParts.length, 2); i++)
					längder.pop();
			}
			break;
		case RoofForm.Pult:
			längder = [this.length];
			break;
		}

		// Gör ett hål i en takfotslängd och splitta den i två
		const makeHole = (hole: number) => {
			const longest = längder.sort()[0];
			if (longest < hole) // Om bredden på hålet är större än nån hel sida blir det konstigt
				längder.splice(0, 1);
			else
				längder.splice(0, 1, (longest - hole) / 2, (longest - hole) / 2)
		}

		if (this.isMainPart()) {
			for (const ext of this.roof.extensions) {
				switch (ext.type) {
				case RoofType.VinkelTak:
					makeHole(ext.width);
					break;
				case RoofType.HörnVinkel:
					// Shorten long side of main roofpart
					if (längder[0] < ext.width) längder.splice(0, 1);
					else längder[0] = längder[0] - ext.width;
					break;
				}
			}
		}

		return längder;
	}

	/** Calculate length of a single rändal for this roof */
	// Paths:
	// TC3	Case TT_VINKEL, TT_KUPA, TT_FRONT, TT_HORNVINKEL
	// TC6		If TT_FRONT && TF_SADEL
	// TC3		Else
	public getRändalslängd() {
		if (this.type === RoofType.Front && this.form === RoofForm.Sadel) return 0;

		if ([RoofType.VinkelTak, RoofType.Kupa, RoofType.Front, RoofType.HörnVinkel].includes(this.type)) {
			const length = round(this.getHeight().height / Math.tan(this.roof?.mainPart?.angleInRads?.() ?? 0));
			return round(Math.sqrt(length ** 2 + this.getTakfall().takfall ** 2));
		}

		return 0;
	}

	public getTotalRändalslängd() {
		return this.getRändalslängd() * this.rändaler;
	}

	/** Calculate length of a single vindskiva for this roof */
	// Paths:
	// TC1	If !Gavelpanna && !Gavelbeslag
	// TC1		case TM_STANDARD
	// TC1			case TF_SADEL, TF_PULT
	// TC3			case TF_HALVVALM
	// TC13		case TM_MANSARD
	// TC13			case TF_SADEL, TF_PULT
	// TC20			case TF_HALVVALM
	// ~	(Else)
	public getVindskivelängd(): number {
		if ([GavelTillval.Gavelpanna, GavelTillval.Gavelbeslag].includes(this.roof.selection.selGavelTillval)) return 0;

		const takfall = this.getTakfall();
		if (this.model === RoofModel.Standard) {
			if ([RoofForm.Sadel, RoofForm.Pult].includes(this.form)) return takfall.takfall;
			if ([RoofForm.Halvvalm].includes(this.form)) return takfall.takfall - takfall.takfallGavel;
		} else if (this.model === RoofModel.Mansard) {
			if ([RoofForm.Sadel, RoofForm.Pult].includes(this.form)) return takfall.takfall + takfall.takfall2;
			if (this.form === RoofForm.Halvvalm) return takfall.takfall + takfall.takfall2 - takfall.takfallGavel2;
		}

		return 0;
	}

	public getTotalVindskivelängd() {
		return this.getVindskivelängd() * this.sides * this.getGavlar();
	}

	/** Calculate number of ventinock articles */
	// Paths:
	// TC1	If Sverige && Palema && TF_SADEL
	public getVentiNock(): number {
		// "enligt sortimentlista åtgår 2.2 per meter nock"

		// "Endast Sverige använder ventinock"
		// "Endast Palema har Ventinock, övriga nock och valmtätningsrulle"
		// "Endast ventinock för Sadeltak"

		// Inget ventinock för pulttak, halvvalm och valm ska ha nock och valmtätningsrulle istället

		if ([MarketStr.Sverige, MarketStr.Tyskland].includes(this.roof.market) && this.tile.family == TileFamily.Palema && this.form == RoofForm.Sadel || isCompatMode() == false)
			return (this.getNocklängd().nocklängd / 1000) * 2.2;

		return 0;
	}

	/** Calculate total length to use for fågelband and snöglidhinder */
	public getTotalLength() {
		return this.getTakfotslängd();
	}

	/** Length of nockben, the part of the roof connecting the corners of valmade tak to the nock. Returns 0 if the roof doesn't have them */
	// Paths:
	// TC1	case TM_STANDARD
	// TC1		case TF_SADEL
	// TC23			If TT_HUVUD && _HV_Tak
	// TC2		case TF_VALM
	// TC28			If TT_FRONT
	// TC2			Else
	// TC3		case TF_HALVVALM
	// TC27			If TT_HUVUD && _HV_Tak
	// TC3			End
	// TC14	case TM_MANSARD
	// TC14		case TF_VALM
	// TC15		case TF_HALVVALM
	// TC23	If TT_HORNVINKEL
	public getNockbenLängd() {
		const calc = { nockbenLängd: 0, nockben2Längd: 0, nockbenLängdHV: 0, totalNockbenLängd: 0 };

		if (this.model == RoofModel.Standard) {
			if (this.form == RoofForm.Sadel) {
				if (this.isHuvudHVTak())
					calc.nockbenLängd = Math.sqrt(2 * this.sideWidth() ** 2 + this.getHeight().height ** 2);
			} else if (this.form == RoofForm.Valm) {
				if (this.type === RoofType.Front) {
					calc.nockbenLängd = this.getTakfall().takfall;
				} else {
					calc.nockbenLängd = Math.sqrt(2 * this.sideWidth() ** 2 + this.getHeight().height ** 2);
				}
			} else if (this.form == RoofForm.Halvvalm) {
				if (this.isHuvudHVTak())
					calc.nockbenLängd = Math.sqrt(2 * this.sideWidth() ** 2 + this.getHeight().height ** 2);
				calc.nockben2Längd = Math.sqrt(2 * this.sideWidth2() ** 2 + this.getHeight().height2 ** 2);
			}
		} else if (this.model == RoofModel.Mansard) {
			if (this.form == RoofForm.Valm) {
				calc.nockbenLängd = Math.sqrt(2 * this.sideWidth() ** 2 + this.getHeight().height ** 2);
				calc.nockben2Längd = Math.sqrt(2 * this.sideWidth2() ** 2 + this.getHeight().height2 ** 2);
			} else if (this.form == RoofForm.Halvvalm) {
				calc.nockben2Längd = Math.sqrt(2 * this.sideWidth2() ** 2 + this.getHeight().height2 ** 2);
			}
		}

		calc.totalNockbenLängd = calc.nockbenLängd * this.nockben + calc.nockben2Längd * this.nockben2;

		return calc;
	}

	/** Calculate gavelpannor for this roof */
	// TODO: Ran for gavelpannor&!Carisma
	// Paths:
	// TC3	If Gavelpannor
	// TC3		Case TM_STANDARD
	// TC3			Case TF_SADEL
	// TC33				If TT_HUVUD && HV_Tak
	// TC3				Else
	// TC29			Case TF_PULT
	// TC3			Case TF_HALVVALM
	// TC34				If TT_HUVUD && HV_Tak
	// TC3				Else
	// TC16		Case TM_MANSARD
	// TC16			Case TF_SADEL
	// TC15			Case TF_HALVVALM
	public calculateGavelpannor() {
		const calc = { [RoofArticle.GavelPannaVänster]: 0, [RoofArticle.GavelPannaHöger]: 0 };

		// Quit early if gavelpanna is not selected
		if (
			this.roof.selection.selGavelTillval !== GavelTillval.Gavelpanna ||
			![RoofForm.Sadel, RoofForm.Halvvalm, RoofForm.Pult].includes(this.form)
		)
			return calc;

		const rows = this.calculatePannrader();
		let antal = round(rows.antalRader - rows.antalRader2);

		// +1 för spill eftersom halvvalm ej går precis vid hel panna
		if (this.form === RoofForm.Halvvalm) antal++;

		if ([RoofForm.Sadel, RoofForm.Halvvalm].includes(this.form) && !this.isHuvudHVTak()) {
			calc[RoofArticle.GavelPannaVänster] = antal * this.sides;
			calc[RoofArticle.GavelPannaHöger] = antal * this.sides;
		} else {
			calc[RoofArticle.GavelPannaVänster] = antal;
			calc[RoofArticle.GavelPannaHöger] = antal;
		}

		return calc;
	}

	/** Calculate gavelpannor for Carisma and Piano roofs */
	// TODO: Ran for Carisma&Gavelpannor
	// Paths <Only called for test cases that use Carisma or Piano>:
	// TC6	Case TM_STANDARD
	// TC6		Case TT_HUVUD
	// TC8			Case TF_SADEL
	// ~				If Halvpanna
	// ~				Else
	// TC6			Case TF_HALVVALM
	// TC6				If Halvpanna
	// TC6				Else
	// TC38			Case TF_PULT
	// ~				If Halvpanna
	// ~				Else
	// TC6		Case TT_VINKEL, TT_KUPA, TT_TILLBYGGNAD
	// TC39			Case TF_SADEL
	// -				If Halvpanna
	// -				Else
	// TC6			Case TF_HALVVALM
	// ~				If Halvpanna
	// ~				Else
	// TC6		Case TT_FRONT
	// TC6			Case TF_SADEL
	// ~				If Halvpanna
	// ~				Else
	// TC39			Case TF_VALM
	// TC18		Case TT_PULT
	// TC39			Case TF_SADEL
	// -				If Halvpanna
	// -				Else
	// TC39		Case TT_HORNVINKEL
	// TC39			Case TF_SADEL
	// -				If Halvpanna
	// -				Else
	// TC39			Case TF_HALVVALM
	// -				If Halvpanna
	// -				Else
	// TC17	Case TM_MANSARD
	// TC17		Case TF_SADEL
	// ~			If Halvpanna
	// ~			Else
	// TC18		Case TF_HALVVALM
	// ~			If Halvpanna
	// ~			Else
	public calculateCarismaGavelpannor(användHalvpanna: boolean) {
		const calc = {
			[RoofArticle.GavelPannaVänster]: 0,
			[RoofArticle.GavelPannaHöger]: 0,
			[RoofArticle.GavelPannaHalvVänster]: 0,
			[RoofArticle.GavelPannaHalvHöger]: 0,
		};

		const pannRader = this.calculatePannrader();

		let rows = pannRader.antalRader - pannRader.antalRader2;
		if (this.model == RoofModel.Mansard && this.form == RoofForm.Sadel)
			rows = pannRader.antalRader + pannRader.antalRader2;
		if (this.model == RoofModel.Mansard && this.form == RoofForm.Halvvalm) rows = pannRader.antalRader;

		let antal = Math.ceil(rows / 2);
		let antal2 = round(rows) - antal;

		if (this.form === RoofForm.Valm) return calc;

		if (this.model == RoofModel.Standard && this.form === RoofForm.Halvvalm) {
			antal++;
			antal2++;
		}

		let gavlar = this.getGavlar();
		if ([RoofType.Front, RoofType.PultDel, RoofType.Pulttak].includes(this.type)) gavlar = 1; // Behandla vissa tak som 1 gavel

		if (användHalvpanna) {
			calc[RoofArticle.GavelPannaVänster] = antal2 * gavlar;
			calc[RoofArticle.GavelPannaHalvVänster] = antal * gavlar;
			calc[RoofArticle.GavelPannaHöger] = antal * gavlar;
			calc[RoofArticle.GavelPannaHalvHöger] = antal2 * gavlar;
		} else {
			calc[RoofArticle.GavelPannaVänster] = antal * gavlar;
			calc[RoofArticle.GavelPannaHalvVänster] = antal2 * gavlar;
			calc[RoofArticle.GavelPannaHöger] = antal * gavlar;
			calc[RoofArticle.GavelPannaHalvHöger] = antal2 * gavlar;
		}

		return calc;
	}

	/** Calculate number of nockscrews */
	// Paths:
	// TC5	case Carisma, Piano
	// TC1	default
	public calculateNockskruv(calc: ArticleSum, includeSvinn?: boolean) {
		// Nockskruv åtgår en styck till följande takelement:
		// 	NockPanna
		// 	NockBorjan
		// 	NockSlut
		// 	ValmKlocka
		// 	BorjanValm
		// 	T_Nock

		let sum =
			(calc[RoofArticle.NockPanna] || 0) +
			(calc[RoofArticle.NockBörjan] || 0) +
			(calc[RoofArticle.NockSlut] || 0) +
			(calc[RoofArticle.ValmKlocka] || 0) +
			(calc[RoofArticle.BörjanValm] || 0) +
			(calc[RoofArticle.TNock] || 0);

		// Om Carisma eller Piano är valt används två skruv per panna
		if (this.roof.selection.tileFamily.family == TileFamily.PianoFalsatLertegel
				|| (this.roof.selection.tileFamily.family == TileFamily.Carisma && !this.roof.selection.selOverlapCarisma))
			sum *= 2;

		if (includeSvinn) sum *= svinn.nockskruv;

		return sum;
	}

	/** Calculate number of nockscrews for gavelbeslag */
	// Paths:
	// 	<no branches>
	public calculateNockskruv2(includeSvinn = false) {
		let nockskruv = this.calculateGavelbeslag() * 2;
		if (includeSvinn) nockskruv *= svinn.nockskruv;
		return nockskruv;
	}

	/** Beräkna antal snöglidhinder (varje är 1m) */
	// Paths:
	// TC1	<no branching>
	public calculateSnöglidhinder() {
		return Math.ceil(this.getTotalLength() / 1000);
	}

	/** Beräkna antal snöglidhinders konsoler */
	// Paths:
	// TC1	<no branching>
	public calculateSnöglidhinderKonsoler() {
		return Math.ceil(this.calculateSnöglidhinder() * 1.6);
	}

	/** Calculate number of nocktätningsremsor */
	// Paths:
	// TC5	case PR_FAM_CARISMA, PR_FAM_HANSA, PR_FAM_HANSA_RAK, PR_FAM_TVILLING, PR_FAM_TVILLING_RAK, PR_FAM_PIANO
	// TC1	default
	public calculateNocktätningsremsor(calc: ArticleSum) {
		// "enligt sortimentlista åtgår 2 per nockpanna + slut och början"
		if (
			[
				TileFamily.Carisma,
				TileFamily.HansaFalsatLertegel,
				TileFamily.HansaFalsatLertegelRakFramkant,
				TileFamily.TvillingFalsatLertegel,
				TileFamily.TvillingLertegelRakFramkant,
				TileFamily.PianoFalsatLertegel,
			].includes(this.roof.selection.tileFamily.family)
		)
			return 0;

		const nockpannorPack = Math.ceil((calc?.[RoofArticle.NockPanna] ?? 0) / 4) * 4;
		const börjanValm = calc?.[RoofArticle.BörjanValm] ?? 0;
		const nockBörjan = calc?.[RoofArticle.NockBörjan] ?? 0;
		const nockSlut = calc?.[RoofArticle.NockSlut] ?? 0;
		const tNock = calc?.[RoofArticle.TNock] ?? 0;

		return Math.ceil((nockpannorPack + börjanValm + nockBörjan + nockSlut + tNock) / 7);
	}

	/** Beräknar antalet fästelement, spik eller stormclips */
	// Paths:
	// TC1	case PR_FAM_PALEMA, PR_FAM_EXKLUSIV, PR_FAM_CARISMA, PR_FAM_MECKLENBURG
	// TC3		case TM_STANDARD
	// TC3			If 14 <= takvinkel < 30
	// TC3				If rader > 0 && kolumner > 0
	// TC1			Else if 30 <= takvinkel < 55
	// TC13		case TM_MANSARD
	// 			If 14 <= takvinkel < 30
	// 				If rader > 0 && kolumner > 0
	// TC13			Else if 30 <= takvinkel < 55
	// 				If rader > 0 && kolumner > 0
	// 			If 14 <= takvinkel2 < 30
	// 				If rader > 0 && kolumner > 0
	// TC13			Else if 30 <= takvinkel2 < 55
	// 				If rader > 0 && kolumner > 0
	// TC9	case PR_FAM_HANSA_RAK, PR_FAM_HANSA, PR_FAM_TVILLING, PR_FAM_TVILLING_RAK, PR_FAM_PIANO
	// TC9		case TM_STANDARD
	// TC9			If 14 <= takvinkel < 55
	// -				If rader > 0 && kolumner > 0
	// TC13		case TM_MANSARD
	// TC13			If 14 <= takvinkel < 55
	// -				If rader > 0 && kolumner > 0
	// TC13			If 14 <= takvinkel2 < 55
	// -				If rader > 0 && kolumner > 0
	public calculateFästelement(calc: ArticleSum, includeSvinn=false) {
		const takpannor = calc[RoofArticle.TakPanna] ?? 0;
		const halvpanna = calc[RoofArticle.HalvPanna] ?? 0;
		const pultpanna = calc[RoofArticle.PultPanna] ?? 0;
		const pultHalvpanna = calc[RoofArticle.PultHalvPanna] ?? 0;
		const gavelpannaVänster = calc[RoofArticle.GavelPannaVänster] ?? 0;
		const gavelpannaHöger = calc[RoofArticle.GavelPannaHöger] ?? 0;
		const gavelpannaHalvVänster = calc[RoofArticle.GavelPannaHalvVänster] ?? 0;
		const gavelpannaHalvHöger = calc[RoofArticle.GavelPannaHalvHöger] ?? 0;
		const gavelPultPanna = calc[RoofArticle.GavelPultPanna] ?? 0;
		const gavelbeslag = calc[RoofArticle.GavelBeslag] ?? 0;
		const nockAnslutningGP = calc[RoofArticle.NockAnslutningsGavelPanna] ?? 0;

		// Ett fästelement krävs för varje panna i yttersta 2 raderna/kolumnerna, de inre pannorna kan behöva fästelement beroende på panna och takvinkel
		const innerRows = this.calculatePannrader().antalRader - 4;
		const innerCols = this.calculatePannkolumner().kolumner - 4;
		const innerRows2 = this.calculatePannrader().antalRader2 - 4;
		const innerCols2 = this.calculatePannkolumner().kolumner2 - 4;

		let subAmount = 0, subAmount2 = 0;
		let totalAmount = 0;
		if ([TileFamily.Palema, TileFamily.Exklusiv, TileFamily.Carisma, TileFamily.Mecklenburger].includes(this.roof.selection.tileFamily.family)) {
			// Vi har inte mecklenburg

			if (14 <= this.angle && this.angle < 30) subAmount = round(innerRows * innerCols);
			if (30 <= this.angle && this.angle < 55) subAmount = round(innerRows * innerCols * 0.8);
			if (14 <= this.angle2 && this.angle2 < 30) subAmount2 = round(innerRows2 * innerCols2);
			if (30 <= this.angle2 && this.angle2 < 55) subAmount2 = round(innerRows2 * innerCols2 * 0.8);

			// BENT-190: Nya regler för infästningsarticklar
			if (!isCompatMode()) {
				subAmount = 0;
				subAmount2 = 0;
				if (this.roof.market == MarketStr.Norge) {
					if (this.angle < 44) subAmount = round(innerRows * innerCols);
				}else {
					if (this.angle <= 44) subAmount = round(innerRows * innerCols);
					else if (44 < this.angle && this.angle <= 54) subAmount = round(innerRows * innerCols * 0.8);
				}

				if (this.roof.market == MarketStr.Norge) {
					if (this.angle2 < 44) subAmount2 = round(innerRows2 * innerCols2);
				}else {
					if (this.angle2 <= 44) subAmount2 = round(innerRows2 * innerCols2);
					else if (44 < this.angle2 && this.angle2 <= 54) subAmount2 = round(innerRows2 * innerCols2 * 0.8);
				}
			}

			if (subAmount <= 0) subAmount = 0;
			if (subAmount2 <= 0) subAmount2 = 0;
			if (this.model == RoofModel.Mansard) subAmount += subAmount2;

			totalAmount =
				takpannor +
				halvpanna +
				pultpanna +
				pultHalvpanna +
				gavelpannaVänster +
				gavelpannaHalvVänster +
				gavelpannaHöger +
				gavelpannaHalvHöger +
				gavelPultPanna -
				nockAnslutningGP -
				subAmount * this.sides;
		}else if ([TileFamily.HansaFalsatLertegelRakFramkant, TileFamily.HansaFalsatLertegel, TileFamily.TvillingFalsatLertegel,
				TileFamily.TvillingLertegelRakFramkant, TileFamily.PianoFalsatLertegel, TileFamily.Strängpressat2kupLertegel]
				.includes(this.roof.selection.tileFamily.family)) {
			if (14 <= this.angle && this.angle < 55) subAmount = round(innerRows * innerCols * 0.66);
			if (14 <= this.angle2 && this.angle2 < 55) subAmount2 = round(innerRows2 * innerCols2 * 0.66);

			if (!isCompatMode()) {
				subAmount = 0;
				subAmount2 = 0;
				if (this.angle <= 44) subAmount = round(innerRows * innerCols);
				else if (44 < this.angle && this.angle <= 54) subAmount = round(innerRows * innerCols * 0.8);

				if (this.angle2 <= 44) subAmount2 = round(innerRows2 * innerCols2);
				else if (44 < this.angle2 && this.angle2 <= 54) subAmount2 = round(innerRows2 * innerCols2 * 0.8);
			}


			if (subAmount <= 0) subAmount = 0;
			if (subAmount2 <= 0) subAmount2 = 0;
			if (this.model == RoofModel.Mansard) subAmount += subAmount2;

			totalAmount =
				takpannor +
				halvpanna +
				pultpanna +
				pultHalvpanna +
				gavelpannaVänster +
				gavelpannaHöger +
				gavelPultPanna -
				nockAnslutningGP -
				subAmount * this.sides;
		}

		if (!isCompatMode()) {
			totalAmount += gavelbeslag;
		}

		if (includeSvinn && isCompatMode()) totalAmount *= svinn.fästelement;
		if (includeSvinn && !isCompatMode() && (
			([MarketStr.Sverige, MarketStr.Tyskland].includes(this.roof.market) && this.angle <= 54) || (this.roof.market == MarketStr.Norge && this.angle <= 44)
		))
			totalAmount *= svinn.fästelement;

		return totalAmount;
	}

	/** Beräkna antalet klipps som behövs i ränndalar och nockben */
	public calculateRänndalsOValmklipps(): number {
		const rader = this.calculatePannrader();

		return rader.antalRader * (this.rändaler + this.nockben) + rader.antalRader2 * this.nockben2;
	}


	/** Calculate nock och valmtätningsrulle in meters */
	// Paths:
	// TC1	case Palema
	// TC1		If Sverige
	// TC1			case Sadel
	// TC29			case Pult
	// TC2			case Halvvalm, Valm
	// 		Else
	// TC3	case PR_FAM_MECKLENBURG, PR_FAM_EXKLUSIV, PR_FAM_CARISMA
	// TC9	case PR_FAM_HANSA_RAK, PR_FAM_HANSA, PR_FAM_TVILLING, PR_FAM_TVILLING_RAK
	// 		If NockAnslutning
	// 		Else
	// 	case Piano
	public calculateNockOchValmtätningsrulle(): number {
		let nockOValmtätning = 0;
		switch (this.roof.selection.tileFamily.family) {
			case TileFamily.Palema:
				if ([MarketStr.Sverige, MarketStr.Tyskland].includes(this.roof.market)) {
				  if ([RoofForm.Valm, RoofForm.Halvvalm].includes(this.form) || isCompatMode() == false) nockOValmtätning = this.getNocklängd().nocklängd;
				} else {
				  nockOValmtätning = this.getNocklängd().nocklängd;
				}
				break;
			case TileFamily.Exklusiv:
			case TileFamily.Carisma:
			case TileFamily.PianoFalsatLertegel:
			case TileFamily.Strängpressat2kupLertegel:
			case TileFamily.Mecklenburger:
				nockOValmtätning = this.getNocklängd().nocklängd;
				break;
			case TileFamily.HansaFalsatLertegel:
			case TileFamily.HansaFalsatLertegelRakFramkant:
			case TileFamily.TvillingFalsatLertegel:
			case TileFamily.TvillingLertegelRakFramkant:
				// Om nockanslutningspanna används behöver vi inte nock-o-valmtätningsrullar för nockarna, bara nockbenen nedan
				if (!this.roof.selection.selNockAnslutning) nockOValmtätning = this.getNocklängd().nocklängd;
				break;
		}

		nockOValmtätning += this.getNockbenLängd().totalNockbenLängd;

		return nockOValmtätning;
	}

	/** Calculate stormclips needed for this roofpart */
	// Paths:
	// 	<no branches>
	public calculateStormclips(
		takPannor: number,
		halvPannor: number,
		gavelpannaVänster: number,
		gavelpannaHöger: number
	): number {
		return Math.ceil((takPannor + halvPannor + gavelpannaVänster + gavelpannaHöger) * 0.3);
	}

	/** Beräkna antal gavelbeslag */
	// Paths:
	// TC5	If Gavelbeslag
	// TC5		If TM_STANDARD
	// TC5			Case TF_SADEL
	// TC32				If TT_HUVUD && HV_Tak
	// TC5				Else
	// TC35			Case TF_HALVVALM
	// TC35				If TT_HUVUD && HV_Tak
	// TC35				Else
	// TC5			Case TF_PULT
	// TC14		Else
	// TC30			Case TF_SADEL
	// TC31			Case TF_HALVVALM
	public calculateGavelbeslag(): number {
		if (this.roof.selection.selGavelTillval == GavelTillval.Gavelbeslag) {
			const rader = this.calculatePannrader();
			const gavlar = this.model == RoofModel.Standard && this.isHuvudHVTak() ? 1 : this.getGavlar();

			if (this.form == RoofForm.Valm) return 0;

			if (this.form == RoofForm.Halvvalm) return round((rader.antalRader - rader.antalRader2 + 1) * gavlar * this.sides);

			return round(rader.antalRader * gavlar * this.sides);
		}

		return 0;
	}

	/** Beräkna dubbelvingade pannor (Används i KRI, samma beräkning som gavelbeslag) */
	public calculateDubbelvingadePannor(): number {
		if (this.roof.selection.selGavelTillval == GavelTillval.Dubbelvingadpanna) {
			const rader = this.calculatePannrader();
			const gavlar = this.model == RoofModel.Standard && this.isHuvudHVTak() ? 1 : this.getGavlar();

			if (this.form == RoofForm.Valm) return 0;

			if (this.form == RoofForm.Halvvalm) return round((rader.antalRader - rader.antalRader2 + 1) * gavlar * this.sides);

			return round(rader.antalRader * gavlar * this.sides);
		}

		return 0;
	}

	/** Beräkna antal nockanslutningar */
	// Paths:
	// TC36	If TF_PULT
	// TC9	Case Hansa, HansaRak
	// TC36		If Gavelpanna
	// TC36			If Gavelpanna_V > 0
	// -		If Halvpanna > 0 // Hansa verkar inte ha halvpanna
	// TC15	Case Tvilling, TvillingRak
	// TC15		If TakVinkel > 25
	// TC15			If Gavelpanna
	// TC15				If Gavelpanna_V > 0
	// TC37			If Halvpanna > 0
	// TODO: Ran for selNockAnslutning
	public calculateNockAnslutning(calc: ArticleSum) {
		let nockAnslutning = 0;
		let nockAnslutningsGavelPanna = 0;

		// Beräkna inga nockanslutningspannor för tak med Pultform
		if (this.form === RoofForm.Pult || (this.type == RoofType.Front && this.form == RoofForm.Sadel) || this.type == RoofType.PultDel) return;

		if (
			[TileFamily.HansaFalsatLertegel, TileFamily.HansaFalsatLertegelRakFramkant].includes(
				this.roof.selection.tileFamily.family
			)
		) {
			nockAnslutning = this.calculatePannkolumner().kolumnerNock * this.sides;

			// Om gavelpanna är valt, byt ut mot nockanslutningsgavelpannor
			if (this.roof.selection.selGavelTillval === GavelTillval.Gavelpanna) {
				nockAnslutningsGavelPanna = this.getGavlar() * this.sides;
				nockAnslutning -= nockAnslutningsGavelPanna;

				if (calc?.[RoofArticle.GavelPannaVänster] ?? 0 > 0) {
					calc[RoofArticle.GavelPannaVänster] =
						(calc[RoofArticle.GavelPannaVänster] ?? 0) - Math.ceil(nockAnslutningsGavelPanna / 2);
					calc[RoofArticle.GavelPannaHöger] =
						(calc[RoofArticle.GavelPannaHöger] ?? 0) - Math.ceil(nockAnslutningsGavelPanna / 2);
				}
			}

			// Om halvpannor har lagts till, dra av en för varje sida på taket
			if (calc[RoofArticle.HalvPanna] ?? 0 > 0)
				calc[RoofArticle.HalvPanna] = (calc[RoofArticle.HalvPanna] ?? 0) - this.sides;

			// Ta bort en Takpanna för varje NockAnslutning och N.A.Gavelpanna
			calc[RoofArticle.TakPanna] = (calc[RoofArticle.TakPanna] ?? 0) - nockAnslutning - nockAnslutningsGavelPanna;

			// Tvilling har bara anslutningspannor om takvinkeln är minst 25 grader
		} else if (
			[TileFamily.TvillingFalsatLertegel, TileFamily.TvillingLertegelRakFramkant].includes(
				this.roof.selection.tileFamily.family
			) &&
			this.angle >= 25
		) {
			nockAnslutning = this.calculatePannkolumner().kolumnerNock * this.sides * 2;

			// Om gavelpanna är valt, byt ut mot nockanslutningsgavelpannor
			if (this.roof.selection.selGavelTillval === GavelTillval.Gavelpanna) {
				nockAnslutningsGavelPanna = this.getGavlar() * this.sides;
				nockAnslutning -= nockAnslutningsGavelPanna;

				if (calc?.[RoofArticle.GavelPannaVänster] ?? 0 > 0) {
					calc[RoofArticle.GavelPannaVänster] =
						(calc[RoofArticle.GavelPannaVänster] ?? 0) - Math.ceil(nockAnslutningsGavelPanna / 2);
					calc[RoofArticle.GavelPannaHöger] =
						(calc[RoofArticle.GavelPannaHöger] ?? 0) - Math.ceil(nockAnslutningsGavelPanna / 2);
				}
			}

			if (calc[RoofArticle.HalvPanna] ?? 0 > 0)
				calc[RoofArticle.HalvPanna] = (calc[RoofArticle.HalvPanna] ?? 0) - this.sides;
			calc[RoofArticle.TakPanna] = (calc[RoofArticle.TakPanna] ?? 0) - nockAnslutning / 2 - nockAnslutningsGavelPanna;
		}

		calc[RoofArticle.NockAnslutning] = nockAnslutning;
		calc[RoofArticle.NockAnslutningsGavelPanna] = nockAnslutningsGavelPanna;
	}

	/** Beräkna tätningsklossar för Carisma och Piano */
	// Paths:
	// TC5	Case TM_STANDARD
	// -		If Gavelpanna // Den här funktionen körs inte om gavelpannor är satta
	// -			Case TT_TILLBYGGNAD
	// TC5		Else
	// TC5			Case TF_SADEL, TF_PULT
	// TC4			Case TF_VALM
	// TC4			Case TF_HALVVALM
	// TC14	Case TM_MANSARD
	// TC19		Case TF_SADEL
	// TC14		Case TF_VALM
	// TC21		Case TF_HALVVALM
	public calculateKlossar(): number {
		let gavlar = this.getGavlar();

		// BENT-127: Beräkna för båda takändarna för tillbyggnad när Carisma är valt
		if (this.type === RoofType.Tillbyggnad && this.roof.selection.tileFamily.family === TileFamily.Carisma)
			gavlar = 2;

		if (this.roof.selection.selGavelTillval == GavelTillval.Gavelpanna) {
			if (this.type === RoofType.Tillbyggnad) return round(this.calculatePannrader().antalRader * this.sides);
		} else {
			switch (this.form) {
				case RoofForm.Sadel:
				case RoofForm.Pult:
					return round(this.calculatePannrader().antalRader * this.sides * gavlar);
				case RoofForm.Valm:
					return 0;
				case RoofForm.Halvvalm:
					return round((this.calculatePannrader().antalRader - this.calculatePannrader().antalRaderGavel2) * this.sides * gavlar);
			}
		}
		return 0;
	}

	/** Beräkna avrinningsplåtar för Carisma och Piano */
	// Paths:
	// TC5	Case TM_STANDARD
	// TC8		If Gavelpanna
	// TC8			Case TT_TILLBYGGNAD
	// TC5		Else
	// TC5			Case TF_SADEL, TF_PULT
	// TC4			Case TF_VALM
	// TC4			Case TF_HALVVALM
	// TC14	Case TM_MANSARD
	// TC18		Case TF_SADEL
	// TC14		Case TF_VALM
	// TC21		Case TF_HALVVALM
	public calculateAvrinningsplåt(): number {
		let gavlar = this.getGavlar();

		// BENT-127: Beräkna för båda takändarna för tillbyggnad när Carisma är valt
		if (this.type === RoofType.Tillbyggnad && this.roof.selection.tileFamily.family === TileFamily.Carisma)
			gavlar = 2;

		if (this.roof.selection.selGavelTillval == GavelTillval.Gavelpanna) {
			if (this.type === RoofType.Tillbyggnad)
				return Math.ceil(this.getTakfall().takfall / 1950) * this.sides * gavlar;
		} else {
			switch (this.form) {
				case RoofForm.Sadel:
				case RoofForm.Halvvalm:
				case RoofForm.Pult:
					return Math.ceil(this.getTakfall().takfall / 1950) * this.sides * gavlar;
				case RoofForm.Valm:
					return 0;
			}
		}
		return 0;
	}

	/** Calculate rändalstätning */
	// Paths:
	// TC5	<no branches>
	public calculateRändalstätning(): number {
		return this.getRändalslängd() * this.rändaler * 2;
	}

	/** Calculate nockbyglar */
	// Paths:
	// TC5	If Carisma && STD nockpanna
	// TC5		If TF_PULT || TT_PULT
	// TC5		Else
	public calculateNockByglar(): number {
		if (
			this.roof.selection.tileFamily.family !== TileFamily.Carisma ||
			this.roof.selection.selNockTile !== NockPannaVariant.Standard ||
			this.form === RoofForm.Pult ||
			this.type === RoofType.PultDel
		)
			return 0;

		const npBredd = ByggBredd[this.roof.selection.tileFamily.itemPrefixNo(RoofArticle.NockPanna)];
		return Math.ceil(this.getNocklängd().nocklängd / npBredd) + 1;
	}

	/** Calculate Nockbrädshållare. This article wasn't calculated in the old calc */
	public calculateNockbrädshållare() {
		const nocklängd = this.getNocklängd();
		const nockbenlängd = this.getNockbenLängd();

		// Row 193: Sum for nockben and nocklängd separately
		let nockbrädshållare = 0.0;
		if (nocklängd.nocklängd > 0 || nocklängd.pultlängd > 0) nockbrädshållare += Math.ceil(1 + (nocklängd.nocklängd / 1000 + nocklängd.pultlängd / 1000) / 1.2);
		if (nockbenlängd.nockbenLängd > 0)	nockbrädshållare += this.nockben * Math.ceil(1 + (nockbenlängd.nockbenLängd / 1000) / 1.2);
		if (nockbenlängd.nockben2Längd > 0)	nockbrädshållare += this.nockben2 * Math.ceil(1 + (nockbenlängd.nockben2Längd / 1000) / 1.2);
		if (nockbenlängd.nockbenLängdHV > 0)	nockbrädshållare += Math.ceil(1 + (nockbenlängd.nockbenLängdHV / 1000) / 1.2);
		return nockbrädshållare;
	}

	public calculateVentileradLäkt() {
		const takfotslängd = this.getTakfotslängd() / 1000.0;

		return Math.ceil(takfotslängd); // 1 per meter
	}

	/** Calculate all amounts for all possible articles for this roof. Some of these articles should only be used in some markets, or when certain rooftiles are selected */
	// Paths (Total_Kalk, inside the roof for-loop):
	// 	If Gavelpanna
	// 		If Carisma
	// 		Else
	// TC5	If Gavelbeslag
	// 	case Palema
	// 		If flgHalvpannor
	// 		Then
	// TC4	case Carisma, Piano
	// TC5		If Gavelpanna == false
	// 	If Nockanslutning
	public calculateArticles(): { [key in RoofArticle]?: number } {
		if (this.roof.selection.tileFamily === RoofTile.None) throw Error('Tile not set');

		const calc = {} as { [key in RoofArticle]?: number };
		const kCalc = this.calculatePannkolumner();

		addArticleSum(calc, this.calculateNockpannor());

		if (this.roof.market == MarketStr.Tyskland) this.calculatePultPannor(calc);

		if (this.roof.selection.selGavelTillval == GavelTillval.Gavelpanna) {
			if (this.roof.selection.tileFamily.family == TileFamily.Carisma
					|| (!isCompatMode() && this.roof.selection.tileFamily.family == TileFamily.PianoFalsatLertegel))
				addArticleSum(calc, this.calculateCarismaGavelpannor(kCalc.användHalvpanna));
			else addArticleSum(calc, this.calculateGavelpannor());
		}

		calc[RoofArticle.GavelBeslag] = this.calculateGavelbeslag();
		if (this.roof.market == MarketStr.Tyskland)
			calc[RoofArticle.DubbelvingadPanna] = this.calculateDubbelvingadePannor();

		switch (this.roof.selection.tileFamily.family) {
			case TileFamily.Palema:
			case TileFamily.TvillingFalsatLertegel:
			case TileFamily.TvillingLertegelRakFramkant:
			case TileFamily.Mecklenburger:
				if (kCalc.användHalvpanna)
					calc[RoofArticle.HalvPanna] = (calc?.[RoofArticle.HalvPanna] ?? 0) + this.calculateHalvpannor();

				addArticleSum(calc, { [RoofArticle.HalvPanna]: this.calculateHalvpannorVinkel() });
				this.calculateHalvpannorValm(calc);
				break;
			case TileFamily.Carisma:
			case TileFamily.PianoFalsatLertegel:
				if (this.roof.selection.selGavelTillval !== GavelTillval.Gavelpanna)
					// Ej för gavelpannor då man använder start med halva gavelpannor eller hela
					addArticleSum(calc, { [RoofArticle.HalvPanna]: this.calculateHalvpannorCarismaPiano() });
				break;
		}

		addArticleSum(calc, { [RoofArticle.TakPanna]: this.calculateTakpannor(calc?.[RoofArticle.HalvPanna] ?? 0) });

		if (this.roof.selection.selNockAnslutning) this.calculateNockAnslutning(calc);
		addArticleSum(calc, { [RoofArticle.Fästelement]: this.calculateFästelement(calc, !isCompatMode()) });
		addArticleSum(calc, { [RoofArticle.RänndalsOValmklipps]: this.calculateRänndalsOValmklipps() });
		addArticleSum(calc, { [RoofArticle.Nocktätningsremsa]: this.calculateNocktätningsremsor(calc) });
		calc[RoofArticle.Nockskruv] = this.calculateNockskruv(calc, false);
		calc[RoofArticle.Nockskruv2] = this.calculateNockskruv2(false);
		calc[RoofArticle.Tätningskloss] = this.calculateKlossar();
		calc[RoofArticle.Avrinningsplåt] = this.calculateAvrinningsplåt();
		calc[RoofArticle.CBygel] = this.calculateNockByglar();

		if (this.roof.market == MarketStr.Norge && this.roof.selection.tileFamily.family == TileFamily.Carisma) {
			calc[RoofArticle.VentileradLäkt] = this.calculateVentileradLäkt();
		}

		// Multiply all article values by the amount of this roofpart to calculate
		for (const [key, value] of Object.entries(calc)) calc[Number(key) as RoofArticle] = value * this.getAbsAmount();

		return calc;
	}

	public getSummary(market: MarketStr): RoofSummary {
		return {
			rows: this.calculatePannrader(true),
			bärläkt: Math.round(this.calculateBärläkt(true)),
			ströläkt: Math.round(this.calculateStröläkt(true)),
			area: this.getRoofArea(),
			nock: this.getNocklängd(),
			takfot: this.getTakfotslängd(),
			ränndal: this.getTotalRändalslängd(),
			vindskive: this.getTotalVindskivelängd(),
			nockbenlängd: this.getNockbenLängd().totalNockbenLängd,
			pultlängd: this.getNocklängd().pultlängd,
			förstaläkt: this.roof.selection.tileFamily.förstaläkt(market),
		};
	}
}

function assert(condition: boolean, msg: string) {
	if (!condition) throw new Error(msg);
}

function assertInRange(value: number, min: number, max: number, msg: string) {
	return assert(value >= min && value <= max, msg);
}

interface RoofSummary {
	rows: RoofPannrader;
	bärläkt: number;
	ströläkt: number;
	area: RoofArea;
	nock: RoofNockLängd;
	takfot: number;
	ränndal: number;
	vindskive: number;
	nockbenlängd: number;
	pultlängd: number;
	förstaläkt: number;
}

interface RoofPannrader {
	antalRader: number;
	antalRader2: number;
	antalRaderGavel: number;
	antalRaderGavel2: number;
	withDecimals?: boolean;
}

interface RoofArea {
	area: number;
	area2: number;
}

interface RoofNockLängd {
	nocklängd: number;
	pultlängd: number;
}
