import { RoofTile } from './rooftile';
import { TileFamily, TileFinish, TileColor, RoofArticle, Material, Underlagstak, MarketStr } from '@/calculation/common';
import { ExtraAssortment, AccessoryColor } from '@/enums';
import { replaceByArtnr } from '@/helpers/tile';

import { i18n } from '@/i18n';
const { t } = i18n.global;

export class CarismaTile extends RoofTile {
  public constructor() {
    super(TileFamily.Carisma, 280, 210);
    this.label = 'Carisma platt';
    this.key = 'carisma';
    this.material = Material.Betong;
    this.läktavstånd = {
      [MarketStr.Sverige]: {
        min: 310,
        angle14to18: 320,
        angle18to22: 340,
        angle22to80: 350,
      },
      [MarketStr.Norge]: {
        min: 310,
        angle14to18: 320,
        angle18to22: 340,
        angle22to80: 350,
      },
      [MarketStr.Tyskland]: {
        min: 310,
        angle14to18: 320,
        angle18to22: 340,
        angle22to80: 350,
      },
    };
    this.avstTillFörstaLäkt = {
      [MarketStr.Sverige]: 360,
      [MarketStr.Norge]: 370,
      [MarketStr.Tyskland]: 360,
    };

    this.itemNos = {
      [RoofArticle.TakPanna]: 'C00024',
      [RoofArticle.NockPanna]: 'C30024',
      [RoofArticle.NockPannaAlt]: '030024',
      [RoofArticle.NockBörjan]: 'C31524',
      [RoofArticle.BörjanValm]: 'C31524',
      [RoofArticle.BörjanValmAlt]: '031024',
      [RoofArticle.NockBörjanAlt]: '031524',
      [RoofArticle.NockSlutAlt]: '031624',
      [RoofArticle.ValmKlocka]: 'C35024',
      [RoofArticle.ValmKlockaAlt]: '035024',
      [RoofArticle.ValmKlockaBörjan]: '035224',
      [RoofArticle.ValmKlockaSlut]: '035324',
      [RoofArticle.NockXLutande]: 'C37024',
      [RoofArticle.NockXLutandeAlt]: '037024',
      [RoofArticle.NockXPlan]: '037124',
      [RoofArticle.HalvPanna]: 'C21224',
      [RoofArticle.GavelPannaHalvVänster]: 'C41524',
      [RoofArticle.GavelPannaHalvHöger]: 'C41424',
      [RoofArticle.GavelPannaVänster]: 'C41324',
      [RoofArticle.GavelPannaHöger]: 'C41224',
      [RoofArticle.GavelPannaVänsterBred]: 'C40324',
      [RoofArticle.GavelPannaHögerBred]: 'C40224',
      [RoofArticle.GavelPannaHalvVänsterBred]: 'C40524',
      [RoofArticle.GavelPannaHalvHögerBred]: 'C40424',
      [RoofArticle.GavelBeslag]: '041624',
      [RoofArticle.PultPanna]: 'C41824',
      [RoofArticle.PultPannaHalv]: 'C42224',
      [RoofArticle.PultPannaGavelVänster]: 'C42124',
      [RoofArticle.PultPannaGavelHöger]: 'C42024',
      [RoofArticle.PultPannaGavelHalvVänster]: 'C42324',
      [RoofArticle.PultPannaGavelHalvHöger]: 'C42424',
      [RoofArticle.Ventilationshuv]: 'C52224',
      [RoofArticle.Ventilationshuv2]: 'C88524',
      // [RoofArticle.VentilationshuvMontering]: '060200',
      [RoofArticle.Avluftare]: 'C50124',
      [RoofArticle.Avluftare2]: 'C50724',
      [RoofArticle.AvluftarePlåt]: 'C51224',
      [RoofArticle.AvluftarePlåtMontering]: '060100',
      [RoofArticle.Kragrör]: '061020',
      [RoofArticle.Rififi]: '060000',
      [RoofArticle.InfästTaksteg]: '081190',
      [RoofArticle.Taksteg]: '083124',
      // [RoofArticle.Glidskydd]: '072920',
      [RoofArticle.Snöglidhinder]: '081720',
      [RoofArticle.SnöglidhinderKonsol]: '082920',
      [RoofArticle.Valmtätning300]: '091620',
      [RoofArticle.Valmtätning370]: '090720',
      [RoofArticle.Rändalstätning]: '118520',
      [RoofArticle.Fågelband]: '092020',
      [RoofArticle.Fågelband5mSvart]: '092120',
      [RoofArticle.Fågelband5mRöd]: '092124',
      [RoofArticle.VentiFågelband]: '091520',
      [RoofArticle.VenTakfotsläkt]: 'C92220',
      [RoofArticle.CBygel]: 'C97190',
      [RoofArticle.Tätningskloss]: 'C97420',
      [RoofArticle.Avrinningsplåt]: 'C78890',
      [RoofArticle.Takluftare]: '097200',
      [RoofArticle.Nocktätningsremsa]: '093110',
      [RoofArticle.SkruvPanna]: '095491',
      [RoofArticle.Stormclips]: 'C96090',
      [RoofArticle.Nockskruv]: '093691',
      [RoofArticle.NockskruvAlt]: '094391',
      [RoofArticle.Nockklammer]: '114724',
      [RoofArticle.Nockbrädshållare]: '065190',
      [RoofArticle.VentileradLäkt]: 'C92220',

      // Roof Secutity
      [RoofArticle.Bärläktsteg45]: '076120',
      [RoofArticle.Bärläktsteg25]: '076120',
      [RoofArticle.KoppladeTaksteg]: '084120',
      [RoofArticle.InfästningKoppladeTaksteg]: '083990',
      [RoofArticle.Glidskydd]: '072920',
      [RoofArticle.Taklucka]: 'C83820',
      [RoofArticle.Säkerhetskrok]: '070120',
      [RoofArticle.Snökrok]: '081420',
      [RoofArticle.Fästögla]: '171690',
      [RoofArticle.SpårPannaHöger]: 'C73424',
      [RoofArticle.SpårPannaVänster]: 'C73524',
      [RoofArticle.SnowSlideObstacle]: '081790',
      [RoofArticle.SnowSlideObstacleKonsol]: '082990',
      [RoofArticle.IsStoppare]: '083020',
    };

    this.marketSpecificItemNos = {
      [MarketStr.Norge]: {
        [RoofArticle.Kragrör]: '061220',
        [RoofArticle.Ventilationshuv]: 'C88524',
        [RoofArticle.Valmtätning300]: '092420',
      },
      [MarketStr.Tyskland]: {
        [RoofArticle.AvluftareTyskSpitze]: '061224',
        [RoofArticle.LösVenthattSpitze]: '050824',
        [RoofArticle.Dunstrohrlufter100110]: 'C87824',
        [RoofArticle.Entwässerunglü100110]: 'C85124',
        [RoofArticle.Dunstrohrlufter125]: 'C88324',
        [RoofArticle.Entwässerunglü125]: 'C85224',
        [RoofArticle.Dunstrohrlufter160]: 'C85524',
        [RoofArticle.FlexrörAvluftare10070]: '110600',
        [RoofArticle.FlexrörAvluftare12570]: '110640',

        [RoofArticle.Valmtätning300]: '092420',
        [RoofArticle.Valmtätning340]: '090520',
        [RoofArticle.Valmtätning370]: '090720',

	[RoofArticle.Tätningsbeslag]: '095520',
	[RoofArticle.RidgePlate]: '118020',
	[RoofArticle.RidgePlateMount]: '118120',
	[RoofArticle.ChimneySealant5m]: '115720',
	[RoofArticle.ChimneySealant7m]: '115820',
	[RoofArticle.ChimneySealant8m]: '115920',
	[RoofArticle.RidgeSealant]: '118520',
	[RoofArticle.VentivalmPlast]: '091420',
	[RoofArticle.VentivalmBorst]: '091120',
	[RoofArticle.Fågelband]: '092020',
	[RoofArticle.VentiFågelband]: '091520',
	[RoofArticle.VentiLäktPlast]: 'C92220',

        [RoofArticle.GastDurchgGS]: 'C88724',
        [RoofArticle.AntennenDurchgPanna]: 'C88924',
        [RoofArticle.AntennenAufsatz]: 'C85024',

	[RoofArticle.Sturmklammer456VA30x50Palema]: '113391',
	[RoofArticle.Sturmklammer456Zial30x50Palema]: '113390',
	[RoofArticle.Sturmklammer456VA40x60Palema]: '113491',
	[RoofArticle.Sturmklammer456Zial40x60Palema]: '113490',
	[RoofArticle.SturmklammerNordmark2Zial]: '114291',
	[RoofArticle.Sturmklammer456VA30x50Mecklenburger]: '112291',
	[RoofArticle.Sturmklammer456Zial30x50Mecklenburger]: '112290',
	[RoofArticle.Sturmklammer456VA40x60Mecklenburger]: '112491',
	[RoofArticle.Sturmklammer456Zial40x60Mecklenburger]: '112490',
	[RoofArticle.SturmklammerExklusivZial]: '113691',
	[RoofArticle.SturmklammerCarismaZial]: 'C98191',

	[RoofArticle.RänndalsOValmklipps]: '096390',

        [RoofArticle.Nockklammer]: '114424',
        [RoofArticle.NockklammerAlt]: '113524',
        [RoofArticle.SkruvTillBetongtillbehör]: '095990',
        [RoofArticle.SkruvTillBetongtillbehörRostfri]: 'C98291',
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public isSelectable(
    lAvstånd?: number | null,
    taklutning?: { min: number; max: number } | null,
    underlagstak?: Underlagstak | null,
    market?: MarketStr | null,
  ): { valid: boolean; message: string } {
    market = market || MarketStr.Sverige;
    // 310 <= Läktavstånd <= 375
    if (lAvstånd && (lAvstånd < 310 || lAvstånd > 350)) return { valid: false, message: t('validation.notice.disabled_due_to_lakt') };
    if (taklutning) {
      const angleInRangeSE =
        market === MarketStr.Sverige && this.angleInRange(taklutning.min, { min: 18, max: 80 }) && this.angleInRange(taklutning.max, { min: 18, max: 80 });
      const angleInRangeNO =
        market === MarketStr.Norge && this.angleInRange(taklutning.min, { min: 14, max: 80 }) && this.angleInRange(taklutning.max, { min: 14, max: 80 });
      const angleInRangeDE =
        market === MarketStr.Tyskland && this.angleInRange(taklutning.min, { min: 18, max: 80 }) && this.angleInRange(taklutning.max, { min: 18, max: 80 });
      if (!angleInRangeSE && !angleInRangeNO && !angleInRangeDE) return { valid: false, message: t('validation.notice.disabled_due_to_angle') };
    }
    if (underlagstak && underlagstak !== Underlagstak.RåspontMedPapp) return { valid: false, message: t('validation.notice.disabled_due_to_underlagstak') };

    return { valid: true, message: '' };
  }

  public bärläktAvståndNock(angle: number): number {
    const ang = Number(angle.toFixed(1));
    if (ang >= 14 && ang <= 27) return 40;
    if (ang >= 27.1 && ang <= 35) return 25;
    if (ang >= 35.1 && ang <= 80) return 15;
    return 40;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getAccessoryOptions(
    assortment: ExtraAssortment,
    { color, finish, accessoryColor }: { color: TileColor; finish: TileFinish; accessoryColor: AccessoryColor },
  ) {
    const items: string[] = [];
    switch (assortment) {
      case ExtraAssortment.VentilationGenomforing:
        switch (accessoryColor) {
          case AccessoryColor.GalvanizedGreySilver:
            items.push(...['C52297', 'C51297']);
            break;
          case AccessoryColor.Black:
            items.push(...['C52220', 'C51220']);
            break;
          case AccessoryColor.BrickRed:
            items.push(...['C52224', 'C51224']);
            break;
        }
        // eslint-disable-next-line no-case-declarations
        const avluftare = this.getAccessoryVariant(RoofArticle.Avluftare, { color, finish, accessoryColor })?.artnr;
        if (avluftare) items.push(avluftare);
        break;
      case ExtraAssortment.TatningLuftning:
        switch (accessoryColor) {
          case AccessoryColor.GalvanizedGreySilver:
          case AccessoryColor.Black:
          case AccessoryColor.BrickRed:
            items.push(...['C97590', 'C97420']);
            break;
        }
        break;
    }

    // Replace artnr if necessary
    for (let i = 0; i < items.length; i++) {
      items[i] = replaceByArtnr(items[i]);
    }

    return items;
  }
}
