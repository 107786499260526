import type { Roof } from '@/calculation/roof';
import type { ArticleMap, RoofTileOption } from '@/types';
import { RoofTile } from '@/calculation/rooftiles/rooftile';
import { PalemaTile } from '@/calculation/rooftiles/palema';
import { ExklusivTile } from '@/calculation/rooftiles/exklusiv';
import { CarismaTile } from '@/calculation/rooftiles/carisma';
import { HansaTile, HansaRakTile } from '@/calculation/rooftiles/hansa';
import { Tvilling, TvillingRak } from '@/calculation/rooftiles/tvilling';
import { Piano } from '@/calculation/rooftiles/piano';
import { Höganäs } from '@/calculation/rooftiles/höganäs';
import { Mecklenburger } from '@/calculation/rooftiles/mecklenburger';
import { TileFamily, TileFinish, TileColor, RoofArticle, GavelTillval, NockPannaVariant, Underlagstak, isCompatMode } from '@/calculation/common';

import { getArtPrefix } from '@/helpers/utilities';
import { useArticleStore } from '@/store/article';
import { useProjectStore } from '@/store/project';
import { createPinia, setActivePinia } from 'pinia';
import { AccessoryColor } from '@/enums';

import { i18n } from '@/i18n';
const { t } = i18n.global;

// Activate pinia for the unit tests
setActivePinia(createPinia());

// Instantiate all rooftiles now that all classes have been defined
RoofTile.Palema = new PalemaTile();
RoofTile.Exklusiv = new ExklusivTile();
RoofTile.Carisma = new CarismaTile();
RoofTile.Hansa = new HansaTile();
RoofTile.HansaRak = new HansaRakTile();
RoofTile.Tvilling = new Tvilling();
RoofTile.TvillingRak = new TvillingRak();
RoofTile.Piano = new Piano();
RoofTile.Höganäs = new Höganäs();
RoofTile.Mecklenburger = new Mecklenburger();

export { RoofTile };

/** Returns a promise that is fulfilled when all variants have been loaded */
export async function allVariantsLoaded() {
  await RoofTile.Palema.variantsLoaded;
  await RoofTile.Exklusiv.variantsLoaded;
  await RoofTile.Carisma.variantsLoaded;
  await RoofTile.Hansa.variantsLoaded;
  await RoofTile.HansaRak.variantsLoaded;
  await RoofTile.Tvilling.variantsLoaded;
  await RoofTile.TvillingRak.variantsLoaded;
  await RoofTile.Piano.variantsLoaded;
  await RoofTile.Höganäs.variantsLoaded;
  await RoofTile.Mecklenburger.variantsLoaded;

  allVariantsLoadedSync = true;
}

export let allVariantsLoadedSync = false;

export async function reloadVariants() {
  for (const tile of [
    RoofTile.Palema,
    RoofTile.Exklusiv,
    RoofTile.Carisma,
    RoofTile.Hansa,
    RoofTile.HansaRak,
    RoofTile.Tvilling,
    RoofTile.TvillingRak,
    RoofTile.Piano,
    RoofTile.Höganäs,
    RoofTile.Mecklenburger,
  ]) {
    allVariantsLoadedSync = false;
    tile.variantsLoaded = tile.loadVariants();
    tile.variantsLoaded.catch(() => {
      console.log('Cancelled requests for rooftile', TileFamily[tile.family]);
    });
  }
}

/** Returns all tile families and if they are selectable */
export function getSelectableFamilies(
  lAvstånd?: number | null,
  taklutning?: { min: number; max: number } | null,
  underlagstak?: Underlagstak | null,
): RoofTileOption[] {
  const { getMarket } = useProjectStore();
  const families = [
    RoofTile.Palema,
    RoofTile.Exklusiv,
    RoofTile.Carisma,
    RoofTile.Hansa,
    RoofTile.HansaRak,
    RoofTile.Tvilling,
    RoofTile.TvillingRak,
    RoofTile.Piano,
    RoofTile.Höganäs,
    RoofTile.Mecklenburger,
  ];
  return families.map((fam) => ({
    tile: fam,
    ...fam.isSelectable(lAvstånd, taklutning, underlagstak, getMarket),
  })).filter(fam => fam?.message !== t('validation.notice.disabled_due_to_market'));
}

/** Stores all the users choices of pann-attributes (family, finish, color) and checks which are selectable */
export class RoofTileSelection {
  private roof;

  private selTile = RoofTile.None;
  private selFinish = TileFinish.None;
  private selColor = TileColor.None;
  public selAccColor = AccessoryColor.None;

  public selNockTile = NockPannaVariant.None;
  public selGavelTillval = GavelTillval.None;
  public selPult = false;
  public selOverlapCarisma = false;
  public selBTS = false;
  public selNockBoard = true;
  public selDoldAvrinningsplat = true;
  public selTatningskloss = true;
  public selRänndalOValmKlipps = false;

  private nockAnslutningSelected = false;

  public selectedDrainAeratorArtNos: string[] = [];
  public selectedDrainAeratorAmount = 1;
  public selectedVentilationHoodArtNos: string[] = [];
  public selectedVentilationHoodAmount = 1;
  public selectedVentilationPassageArtNo: string = '';
  public selectedVentilationPassageAmount = 0;
  public selectedSealantArtNo = '';
  public selectedVentilationArtNo = '';
  public selectedFixingTileArtNo = '';
  public selectedFixingNockArtNos: string[] = [];

  public extraFixingTiles = 0;

  public constructor(roof: Roof) {
    this.roof = roof;
  }

  // /** Returns all tile families and if they are selectable */
  // public getSelectableFamilies(lAvstånd?: number | null, taklutning?: number | null, underlagstak?: Underlagstak | null): { tileFamily: RoofTile, valid: boolean, message: string }[] {
  // 	const families = [
  // 		RoofTile.Palema,
  // 		RoofTile.Carisma,
  // 		RoofTile.Exklusiv,
  // 		RoofTile.Hansa,
  // 		RoofTile.HansaRak,
  // 		RoofTile.Tvilling,
  // 		RoofTile.TvillingRak,
  // 		RoofTile.Piano,
  // 		RoofTile.Höganäs
  // 		RoofTile.Mecklenburger
  // 	];
  // 	return families.map(fam => ({
  // 		tileFamily: fam,
  // 		...fam.isSelectable(lAvstånd)
  // 	}));
  // }

  public setOptions(tileFamily: RoofTile, finish: TileFinish, color: TileColor, accColor: AccessoryColor, nockTile: NockPannaVariant) {
    this.selTile = tileFamily;
    this.selFinish = finish;
    this.selColor = color;
    this.selAccColor = accColor;
    this.selNockTile = nockTile;
  }

  get tileFamily() {
    return this.selTile;
  }
  set tileFamily(tile: RoofTile) {
    if (!tile.isSelectable(this.roof.getLäktavstånd())) throw Error('Tile is not selectable');
    this.selTile = tile;
    this.selFinish = TileFinish.None;
    this.selColor = TileColor.None;
  }

  public getSelectableFinish() {
    if (this.selTile == RoofTile.None) return [];

    return Object.keys(this.selTile.variants).map((key) => parseInt(key));
  }

  get tileFinish() {
    return this.selFinish;
  }
  set tileFinish(finish: TileFinish) {
    if (!this.getSelectableFinish().includes(finish)) throw Error('Finish is not selectable');
    this.selFinish = finish;
    this.selColor = TileColor.None;
  }

  public getSelectableColors() {
    if (this.selFinish == TileFinish.None) return [];

    return Object.keys(this.selTile.variants[this.selFinish]);
  }

  get tileColor() {
    return this.selColor;
  }
  set tileColor(color: TileColor) {
    if (!Object.keys(this.selTile.variants[this.selFinish]).includes(color)) throw Error('Color is not selectable');
    this.selColor = color;
  }

  public hasNockAnslutning() {
    return [
      TileFamily.HansaFalsatLertegel,
      TileFamily.HansaFalsatLertegelRakFramkant,
      TileFamily.TvillingFalsatLertegel,
      TileFamily.TvillingLertegelRakFramkant,
    ].includes(this.selTile.family);
  }
  get selNockAnslutning() {
    return this.nockAnslutningSelected;
  }
  set selNockAnslutning(value: boolean) {
    if (value && !this.hasNockAnslutning()) {
      console.warn('Försökte välja nockanslutningspanna på takpanna som inte stödjer nockanslutning');
      this.nockAnslutningSelected = false;
    } else {
      this.nockAnslutningSelected = value;
    }
  }

  /** Available nock tiles for the selected tile family, see "Takpannor artikelnummer" sheet for spec */
  public async getSelectableNockTiles() {
    if (this.tileFamily == RoofTile.None) throw Error('No tile selected');
    const options = [];
    if (await this.articleExistsInAssortment(RoofArticle.NockPanna)) options.push(RoofArticle.NockPanna);
    if (await this.articleExistsInAssortment(RoofArticle.NockPannaAlt)) options.push(RoofArticle.NockPannaAlt);
    return options;
  }

  /** Available gaveltillval for the selected tile family, see "Takpannor artikelnummer" sheet for spec */
  public async getSelectableGavelTillval() {
    if (this.tileFamily == RoofTile.None) throw Error('No tile selected');

    const options = [];
    if (await this.articleExistsInAssortment(RoofArticle.GavelPannaHögerBred)) options.push(RoofArticle.GavelPannaHögerBred);
    if (!this.hasNockAnslutning()) if (await this.articleExistsInAssortment(RoofArticle.GavelBeslag)) options.push(RoofArticle.GavelBeslag);

    return options;
  }

  // TODO: Add proper logic for selecting last two digits. A lot of articles only have a single variant. Additionally in the old calc the user could pick a preference order between colors of articles (e.g, pick the black variant if exists, otherwise red if exists, otherwise fallback to a default). Should this be implemented?
  // Note: A simpler version of this exists in current calc mockup. Only available color options should be displayed.
  public async getArticleNumber(article: RoofArticle, fallback = true, silentOnError=false): Promise<string> {
    const { getArticlesByPrefix } = useArticleStore();
    if (article == RoofArticle.ItemPallets) return '099900';
    // if (this.selFinish == TileFinish.None)
    // 	throw Error(`Cannot produce article number for ${RoofArticle[article]}, incomplete tile selection (${this.selTile.family}, ${TileFinish[this.selFinish]})`);
    if (this.selColor == TileColor.None)
      throw Error(
        `Cannot produce article number for ${RoofArticle[article]}, incomplete tile selection (${this.selTile.family}, ${TileFinish[this.selFinish]}, ${
          TileColor[this.selColor]
        })`,
      );
    if (!this.selTile.getArticle(article)) {
      if (!silentOnError)
        console.error(`Cannot produce article number for ${RoofArticle[article]}, tile doesn't have an itemNo entry for that article`);
      return '999999';
    }

    const itemNo = this.selTile.getArticle(article);
    const variantSuffix = this.selTile.variants[this.selFinish][this.selColor].nr;

    const articleNo = getArtPrefix(itemNo);
    let articles = await getArticlesByPrefix(articleNo, undefined, isCompatMode() ? { region: this.roof.market.split('-')[1] } : undefined);

    // Row 118 of 2023 issue tracker, assortments are filtered in product api
    // const assortment = assortments?.[this.roof.market]?.[this.tileFamily.material] ?? 'no-assortment';

    articles = Object.keys(articles).reduce((acc, artnr) => {
      // Only accept articles with status 20-50
      if (!['20', '30', '40', '50'].includes(articles[artnr].status)) return acc;

      acc[artnr] = articles[artnr];
      return acc;
    }, {} as ArticleMap);

    // TODO: Better fallback logic for when an article doesn't exist
    // Also, caching to avoid unnecessary requests
    let suggestedArtNr = articles[articleNo + variantSuffix]?.ArtNr;
    // console.log(`Suggested article number for ${RoofArticle[article]}: ${suggestedArtNr}`, articles);

    // Check if non-halfpallet variant exists
    if (!suggestedArtNr) {
      suggestedArtNr = articles[articleNo + variantSuffix.replace('.5', '')]?.ArtNr;
    }

    // Fallback to first artnr so we display something
    if (!suggestedArtNr && fallback) {
      suggestedArtNr = Object.values(articles)?.[0]?.ArtNr; // TODO: Better selection logic
      console.warn(`Could not find article ${articleNo}${variantSuffix} in ProductAPI, falling back to ${suggestedArtNr}`);
    } else if (!suggestedArtNr) {
      return '';
    }

    if (!suggestedArtNr) {
      // TODO: bestämde under mötet 9/15 att vi skulle kolla om det gick att skicka mejl eller sentry meddelanden för när vissa artiklar
      // saknar tillgängliga varianter. Logga i sentry när det här caset nås
      if (!silentOnError)
        console.error(`Cannot produce article number for ${RoofArticle[article]}, no article under ${articleNo}XX had status 20-50`);
      return '999999';
    }

    return suggestedArtNr;
  }

  public async getPalletAmount(artnr: string): Promise<number> {
    const { getArticle } = useArticleStore();
    const article = await getArticle(artnr);

    return article?.unit?.['p'];
  }

  public async articleExistsInAssortment(article: RoofArticle): Promise<boolean> {
    // Does the tile family have this article?
    if (Object.keys(this.tileFamily.itemNos).includes(String(article)) === false) return false;

    const { getArticlesByPrefix } = useArticleStore();
    const articleNo = getArtPrefix(this.tileFamily.getArticle(article));
    const artData = (await getArticlesByPrefix(articleNo))?.[0]; // We're not using per-variant granularity for this check, even if a certain variant
    // doesn't exist there will at least be a backup variant to use

    // Row 118 of 2023 issue tracker, assortments are filtered in product api
    // // Is the article in the current assortment?
    // const assortment = assortments?.[this.roof.market]?.[this.tileFamily.material] ?? '';
    // if (artData.ItemAssortmentGroup.includes(assortment) === false) return false;

    // Is the article status 20-50?
    if (['20', '30', '40', '50'].includes(artData.status) === false) return false;

    return true;
  }
}
