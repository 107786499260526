import { RoofTile } from './rooftile';
import { TileFamily, TileFinish, TileColor, RoofArticle, Material, Underlagstak, MarketStr, isCompatMode } from '@/calculation/common';
import { ExtraAssortment, AccessoryColor } from '@/enums';
import { replaceByArtnr } from '@/helpers/tile';

import { i18n } from '@/i18n';
const { t } = i18n.global;

export class Tvilling extends RoofTile {
  public constructor() {
    super(TileFamily.TvillingFalsatLertegel, 398, 192, 'H200');
    this.label = 'Tvilling 2-kupig';
    this.key = 'tvilling';
    this.material = Material.Lertegel;
    this.läktavstånd = {
      [MarketStr.Sverige]: {
        min: 266,
        angle14to18: 285,
        angle18to22: 285,
        angle22to80: 285,
      },
      [MarketStr.Norge]: {
        min: 266,
        angle14to18: 285,
        angle18to22: 285,
        angle22to80: 285,
      },
      [MarketStr.Tyskland]: {
        min: 266,
        angle14to18: 285,
        angle18to22: 285,
        angle22to80: 285,
      },
    };
    this.avstTillFörstaLäkt = {
      [MarketStr.Sverige]: 300,
      [MarketStr.Norge]: 320,
      [MarketStr.Tyskland]: 300,
    };

    this.itemNos = {
      [RoofArticle.TakPanna]: 'H20024',
      [RoofArticle.NockPanna]: 'H30024',
      [RoofArticle.NockPannaAlt]: 'H30424',
      [RoofArticle.NockBörjan]: 'H31524',
      [RoofArticle.NockSlut]: 'H31624',
      [RoofArticle.NockBörjanGavel]: 'H31824',
      [RoofArticle.NockSlutGavel]: 'H32024',
      [RoofArticle.BörjanValm]: 'H31024',
      [RoofArticle.BörjanValmAlt]: 'H32124',
      [RoofArticle.NockBörjanAlt]: 'H32124',
      [RoofArticle.NockSlutAlt]: 'H32224',
      [RoofArticle.ValmKlocka]: 'H35024',
      [RoofArticle.NockTBörjan]: 'H36024',
      [RoofArticle.ValmKlockaAlt]: 'H35224',
      [RoofArticle.HalvPanna]: 'H21024',
      [RoofArticle.NockAnslutning]: 'H20324',
      [RoofArticle.GavelPannaNAVänster]: 'H41324',
      [RoofArticle.GavelPannaNAHöger]: 'H41224',
      [RoofArticle.GavelPannaVänsterBred]: 'H41124',
      [RoofArticle.GavelPannaHögerBred]: 'H41024',
      [RoofArticle.Ventilationshuv]: 'H52224',
      [RoofArticle.Ventilationshuv2]: 'H88524',
      [RoofArticle.VentilationshuvMontering]: '060200',
      ...(isCompatMode() ? { [RoofArticle.Avluftare]: 'H50024' } : {}),
      [RoofArticle.Avluftare2]: 'H50024',
      [RoofArticle.AvluftarePlåt]: 'H51224',
      [RoofArticle.AvluftarePlåtMontering]: '060200',
      [RoofArticle.AvluftareLertegel]: 'H50024',
      [RoofArticle.Rififi]: '060000',
      [RoofArticle.InfästTaksteg]: '081190',
      [RoofArticle.Taksteg]: 'L82524',
      // [RoofArticle.Glidskydd]: '072920',
      [RoofArticle.Snöglidhinder]: '081720',
      [RoofArticle.SnöglidhinderKonsol]: '082820',
      [RoofArticle.Valmtätning300]: '091620',
      [RoofArticle.Valmtätning370]: '090720',
      [RoofArticle.Rändalstätning]: '118520',
      [RoofArticle.Fågelband]: '092020',
      [RoofArticle.Fågelband5mSvart]: '092120',
      [RoofArticle.Fågelband5mRöd]: '092124',
      [RoofArticle.VentiFågelband]: '091520',
      [RoofArticle.Takluftare]: '097200',
      [RoofArticle.Nocktätningsremsa]: '093110',
      [RoofArticle.DiagonalClip]: 'L96290',
      [RoofArticle.DiagonalClipZial]: 'L96190',
      [RoofArticle.SidofallsKlammer]: 'H96591',
      // [RoofArticle.Stormclips2]: '096591',
      // [RoofArticle.Stormclips3]: '096691',
      [RoofArticle.EasyClip]: '096691',
      [RoofArticle.EasyClip38]: '096591',
      [RoofArticle.EasyClip50]: '096691',
      [RoofArticle.EasyClip45x70]: '067291',
      [RoofArticle.EasyClip30x48]: '067891',
      [RoofArticle.EasyClip36x48]: '068391',
      [RoofArticle.Nockskruv]: '094391',
      [RoofArticle.Nockklammer]: 'H114724',
      [RoofArticle.NockklammerAlt]: 'H124724',
      [RoofArticle.NockKleeblatt]: 'H124720',
      [RoofArticle.Nockbrädshållare]: '065190',

      // Roof Security
      [RoofArticle.Bärläktsteg45]: isCompatMode() ? 'L72620' : 'H76420',
      [RoofArticle.Bärläktsteg25]: 'H76420',
      [RoofArticle.KoppladeTaksteg]: 'H84520',
      [RoofArticle.InfästningKoppladeTaksteg]: '083990',
      [RoofArticle.Glidskydd]: '072920',
      [RoofArticle.Taklucka]: '083820',
      [RoofArticle.Säkerhetskrok]: '070120',
      [RoofArticle.Snökrok]: '081420',
      [RoofArticle.Fästögla]: '171690',
      [RoofArticle.SpårPanna]: 'H72524',
      [RoofArticle.SpårPannaBörjan]: 'H72424',
      [RoofArticle.SpårPannaSlut]: 'H72624',
      [RoofArticle.SnowSlideObstacle]: '081790',
      [RoofArticle.SnowSlideObstacleKonsol]: '082890',
      [RoofArticle.IsStoppare]: '083020',

      [RoofArticle.BTSUnderlagstak]: '128001',
    };

    this.marketSpecificItemNos = {
      [MarketStr.Norge]: {
        [RoofArticle.Ventilationshuv]: 'H88524',
        [RoofArticle.Valmtätning300]: '092420',
      },
    };
  }

  public isSelectable(
    lAvstånd?: number | null,
    taklutning?: { min: number; max: number } | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    underlagstak?: Underlagstak | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    market?: MarketStr | null,
  ): { valid: boolean; message: string } {
    if (market === MarketStr.Tyskland) return { valid: false, message: t('validation.notice.disabled_due_to_market') };
    // 266 <= Läktavstånd <= 285
    if (lAvstånd && (lAvstånd < 266 || lAvstånd > 285)) return { valid: false, message: t('validation.notice.disabled_due_to_lakt') };
    if (taklutning && (!this.angleInRange(taklutning.min, { min: 14, max: 80 }) || !this.angleInRange(taklutning.max, { min: 14, max: 80 })))
      return { valid: false, message: t('validation.notice.disabled_due_to_angle') };

    return { valid: true, message: '' };
  }

  public bärläktAvståndNock(angle: number, underlagstak: Underlagstak): number {
    const ang = Number(angle.toFixed(1));
    if (underlagstak == Underlagstak.RåspontMedPapp || underlagstak == Underlagstak.LättMedStröläkt) {
      if (ang >= 14 && ang <= 22.5) return 70;
      if (ang >= 22.6 && ang <= 27.5) return 60;
      if (ang >= 27.6 && ang <= 32.5) return 55;
      if (ang >= 32.6 && ang <= 37.5) return 50;
      if (ang >= 37.6 && ang <= 42.5) return 45;
      if (ang >= 42.6 && ang <= 47.5) return 40;
      if (ang >= 47.6 && ang <= 52.5) return 40;
      if (ang >= 52.6 && ang <= 57.5) return 40;
      return 40;
    } else {
      if (ang >= 14 && ang <= 22.5) return 65;
      if (ang >= 22.6 && ang <= 27.5) return 60;
      if (ang >= 27.6 && ang <= 32.5) return 55;
      if (ang >= 32.6 && ang <= 37.5) return 50;
      if (ang >= 37.6 && ang <= 42.5) return 45;
      if (ang >= 42.6 && ang <= 47.5) return 40;
      if (ang >= 47.6 && ang <= 52.5) return 35;
      if (ang >= 52.6 && ang <= 57.5) return 35;
      return 35;
    }
  }

  public getAccessoryOptions(
    assortment: ExtraAssortment,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { color, finish, accessoryColor }: { color: TileColor; finish: TileFinish; accessoryColor: AccessoryColor },
  ) {
    const items: string[] = [];
    switch (assortment) {
      case ExtraAssortment.VentilationGenomforing:
        switch (accessoryColor) {
          case AccessoryColor.GalvanizedGreySilver:
          case AccessoryColor.Black:
            items.push(...['H52220', 'H51220']);
            break;
          case AccessoryColor.BrickRed:
            items.push(...['H52224', 'H51224']);
            break;
        }
        break;
    }

    // Replace artnr if necessary
    for (let i = 0; i < items.length; i++) {
      items[i] = replaceByArtnr(items[i]);
    }

    return items;
  }
}

export class TvillingRak extends RoofTile {
  public constructor() {
    super(TileFamily.TvillingLertegelRakFramkant, 398, 192, 'H201');
    this.label = 'Tvilling rak framkant 2-kupig';
    this.key = 'tvilling_rak';
    this.material = Material.Lertegel;
    this.läktavstånd = {
      [MarketStr.Sverige]: {
        min: 266,
        angle14to18: 285,
        angle18to22: 285,
        angle22to80: 285,
      },
      [MarketStr.Norge]: {
        min: 266,
        angle14to18: 285,
        angle18to22: 285,
        angle22to80: 285,
      },
      [MarketStr.Tyskland]: {
        min: 266,
        angle14to18: 285,
        angle18to22: 285,
        angle22to80: 285,
      },
    };
    this.avstTillFörstaLäkt = {
      [MarketStr.Sverige]: 280,
      [MarketStr.Norge]: 320,
      [MarketStr.Tyskland]: 280,
    };

    this.itemNos = {
      [RoofArticle.TakPanna]: 'H20124',
      [RoofArticle.NockPanna]: 'H30024',
      [RoofArticle.NockPannaAlt]: 'H30424',
      [RoofArticle.NockBörjan]: 'H31524',
      [RoofArticle.NockSlut]: 'H31624',
      [RoofArticle.NockBörjanGavel]: 'H31824',
      [RoofArticle.NockSlutGavel]: 'H32024',
      [RoofArticle.BörjanValm]: 'H31024',
      [RoofArticle.BörjanValmAlt]: 'H32124',
      [RoofArticle.NockBörjanAlt]: 'H32124',
      [RoofArticle.NockSlutAlt]: 'H32224',
      [RoofArticle.ValmKlocka]: 'H35024',
      [RoofArticle.NockTBörjan]: 'H36024',
      [RoofArticle.ValmKlockaAlt]: 'H35224',
      [RoofArticle.HalvPanna]: isCompatMode() ? 'H21024' : 'H21124',
      [RoofArticle.NockAnslutning]: 'H20424',
      [RoofArticle.Ventilationshuv]: 'H52224',
      [RoofArticle.Ventilationshuv2]: 'H88524',
      [RoofArticle.VentilationshuvMontering]: '060200',
      ...(isCompatMode() ? { [RoofArticle.Avluftare]: 'H50224' } : {}),
      [RoofArticle.Avluftare2]: 'H50024',
      [RoofArticle.AvluftarePlåt]: 'H51224',
      [RoofArticle.AvluftarePlåtMontering]: '060200',
      [RoofArticle.AvluftareLertegel]: 'H50224',
      [RoofArticle.Rififi]: '060000',
      [RoofArticle.InfästTaksteg]: '081190',
      [RoofArticle.Taksteg]: 'L82524',
      // [RoofArticle.Glidskydd]: '072920',
      [RoofArticle.Snöglidhinder]: '081720',
      [RoofArticle.SnöglidhinderKonsol]: '081820',
      [RoofArticle.Valmtätning300]: '091620',
      [RoofArticle.Valmtätning370]: '090720',
      [RoofArticle.Rändalstätning]: '118520',
      [RoofArticle.Fågelband]: '092020',
      [RoofArticle.Fågelband5mSvart]: '092120',
      [RoofArticle.Fågelband5mRöd]: '092124',
      [RoofArticle.VentiFågelband]: '091520',
      [RoofArticle.Takluftare]: '097200',
      [RoofArticle.Nocktätningsremsa]: '093110',
      [RoofArticle.DiagonalClip]: 'L96290',
      [RoofArticle.DiagonalClipZial]: 'L96190',
      [RoofArticle.SidofallsKlammer]: 'H96591',
      // [RoofArticle.Stormclips2]: '096591',
      // [RoofArticle.Stormclips3]: '096691',
      [RoofArticle.EasyClip]: '096691',
      [RoofArticle.EasyClip38]: '096591',
      [RoofArticle.EasyClip50]: '096691',
      [RoofArticle.EasyClip45x70]: '067291',
      [RoofArticle.EasyClip30x48]: '067891',
      [RoofArticle.EasyClip36x48]: '068391',
      [RoofArticle.Nockskruv]: '094391',
      [RoofArticle.Nockklammer]: 'H114724',
      [RoofArticle.NockklammerAlt]: 'H124724',
      [RoofArticle.NockKleeblatt]: 'H124720',
      [RoofArticle.Nockbrädshållare]: '065190',

      // Roof Secutity
      [RoofArticle.Bärläktsteg45]: isCompatMode() ? 'L72620' : 'H76420',
      [RoofArticle.Bärläktsteg25]: 'H76420',
      [RoofArticle.KoppladeTaksteg]: 'H84520',
      [RoofArticle.InfästningKoppladeTaksteg]: '083990',
      [RoofArticle.Glidskydd]: '072920',
      [RoofArticle.Taklucka]: '083820',
      [RoofArticle.Säkerhetskrok]: '070120',
      [RoofArticle.Snökrok]: '081420',
      [RoofArticle.Fästögla]: '171690',
      [RoofArticle.SnowSlideObstacle]: '081790',
      [RoofArticle.SnowSlideObstacleKonsol]: '082890',
      [RoofArticle.IsStoppare]: '083020',

      [RoofArticle.BTSUnderlagstak]: '128001',
    };

    this.marketSpecificItemNos = {
      [MarketStr.Norge]: {
        [RoofArticle.Ventilationshuv]: 'H88524',
        [RoofArticle.Valmtätning300]: '092420',
      },
    };
  }

  public isSelectable(
    lAvstånd?: number | null,
    taklutning?: { min: number; max: number } | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    underlagstak?: Underlagstak | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    market?: MarketStr | null,
  ): { valid: boolean; message: string } {
    if (market === MarketStr.Tyskland) return { valid: false, message: t('validation.notice.disabled_due_to_market') };
    // 266 <= Läktavstånd <= 285
    if (lAvstånd && (lAvstånd < 266 || lAvstånd > 285)) return { valid: false, message: t('validation.notice.disabled_due_to_lakt') };
    if (taklutning && (!this.angleInRange(taklutning.min, { min: 14, max: 80 }) || !this.angleInRange(taklutning.max, { min: 14, max: 80 })))
      return { valid: false, message: t('validation.notice.disabled_due_to_angle') };

    return { valid: true, message: '' };
  }

  public bärläktAvståndNock(angle: number, underlagstak: Underlagstak): number {
    const ang = Number(angle.toFixed(1));
    if (underlagstak == Underlagstak.RåspontMedPapp || underlagstak == Underlagstak.LättMedStröläkt) {
      if (ang >= 14 && ang <= 22.5) return 70;
      if (ang >= 22.6 && ang <= 27.5) return 60;
      if (ang >= 27.6 && ang <= 32.5) return 55;
      if (ang >= 32.6 && ang <= 37.5) return 50;
      if (ang >= 37.6 && ang <= 42.5) return 45;
      if (ang >= 42.6 && ang <= 47.5) return 40;
      if (ang >= 47.6 && ang <= 52.5) return 40;
      if (ang >= 52.6 && ang <= 57.5) return 40;
      return 40;
    } else {
      if (ang >= 14 && ang <= 22.5) return 65;
      if (ang >= 22.6 && ang <= 27.5) return 60;
      if (ang >= 27.6 && ang <= 32.5) return 55;
      if (ang >= 32.6 && ang <= 37.5) return 50;
      if (ang >= 37.6 && ang <= 42.5) return 45;
      if (ang >= 42.6 && ang <= 47.5) return 40;
      if (ang >= 47.6 && ang <= 52.5) return 35;
      if (ang >= 52.6 && ang <= 57.5) return 35;
      return 35;
    }
  }

  public getAccessoryOptions(
    assortment: ExtraAssortment,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { color, finish, accessoryColor }: { color: TileColor; finish: TileFinish; accessoryColor: AccessoryColor },
  ) {
    const items: string[] = [];
    switch (assortment) {
      case ExtraAssortment.VentilationGenomforing:
        switch (accessoryColor) {
          case AccessoryColor.GalvanizedGreySilver:
          case AccessoryColor.Black:
            items.push(...['H52220', 'H51220']);
            break;
          case AccessoryColor.BrickRed:
            items.push(...['H52224', 'H51224']);
            break;
        }
        break;
    }

    // Replace artnr if necessary
    for (let i = 0; i < items.length; i++) {
      items[i] = replaceByArtnr(items[i]);
    }

    return items;
  }
}
