import type { RadioOption, AmountOption } from '@/types';
import { FixingsTileOptionsBuilder } from '@/calculation/rooftiles/options/fixingsTile';
import {
  TileFamily,
  TileFinish,
  TileColor,
  RoofArticle,
  PrefixMap,
  TileFinishMap,
  Material,
  MarketStr,
  NockPannaVariant,
  Underlagstak,
  NockTile,
  NockPannaExtra as NockPannaExtra,
  RoofTypeIndex,
  isCompatMode,
  NockConnector,
  PultPanna,
  MIN_INPUT_LAKT,
  MAX_INPUT_LAKT,
} from '@/calculation/common';
import {
  ExtraAssortment,
  AccessoryColor,
  Sealant,
  Ventilation,
  ExposedWeather,
  FixingsTile,
  FixingsNock,
  FixingsRidge,
  DrainAerator,
  VentilationHood,
  VentilationPassage,
  Gable,
  BattenStep,
  FacadeHeight,
  BTSBaseRoof,
  accessoryColorMap,
  SnowSlideObstacle,
} from '@/enums';

import { getArtPrefix } from '@/helpers/utilities';
import { doArticleReplacements, getRoofTile } from '@/helpers/tile';
import { useArticleStore } from '@/store/article';
import { useProjectStore } from '@/store/project';
import { createPinia, setActivePinia } from 'pinia';

import { i18n } from '@/i18n';
import { getArticle } from '@/api/api';
const { t } = i18n.global;

let projectStore = null as ReturnType<typeof useProjectStore> | null;

const allowedTileColors = [
  TileColor.Ljusgrå,
  TileColor.Mellangrå,
  TileColor.Platinagrå,
  TileColor.Gul,
  TileColor.Brun,
  TileColor.Grön,
  TileColor.Svart,
  TileColor.Koppar,
  TileColor.Grafit,
  TileColor.Skiffer,
  TileColor.Olivgrön,
  TileColor.Granit,
  TileColor.Bongossibrun,
  TileColor.Titansvart,
  TileColor.Nötbrun,
  TileColor.Skiffersvart,
  TileColor.Mörkgrå,
  TileColor['Flash Tegelröd/Brun'],
  TileColor.Röd,
  TileColor.Tegelröd,
  TileColor.Vinröd,
  TileColor.Kastanjebrun,
  TileColor.Terracottaröd,
  TileColor['Antik Tegelröd/Svart'],
  TileColor.Indiaröd,
  TileColor.Gammalröd,
  TileColor.Naturröd,
] as TileColor[];

const accessoryColorFallback = {
  [AccessoryColor.GalvanizedGreySilver]: AccessoryColor.Black,
  [AccessoryColor.Black]: AccessoryColor.GalvanizedGreySilver,
  [AccessoryColor.BrickRed]: AccessoryColor.Black,
} as { [key in AccessoryColor]: AccessoryColor };

// Activate pinia for the unit tests
setActivePinia(createPinia());

/** Defines all possible rooftiles a roof can use as well as providing some data about the tile. Also provides methods for calculating the
 *  correct article number to use for each article type and checking if a certain article exists in the current assortment.
 *
 *  Each rooftile is held as a static singleton member of this class.
 */
export class RoofTile {
  static readonly None: RoofTile = new RoofTile(TileFamily.None, -1, -1);
  static Palema: RoofTile;
  static Carisma: RoofTile;
  static Exklusiv: RoofTile;
  static Hansa: RoofTile;
  static HansaRak: RoofTile;
  static Tvilling: RoofTile;
  static TvillingRak: RoofTile;
  static Piano: RoofTile;
  static Höganäs: RoofTile;
  static Mecklenburger: RoofTile;

  public artNrBase: string;
  public family: TileFamily;
  public label: string;
  public key: string;
  public material: Material;
  public läktavstånd: {
    [key: string]: {
      min: number;
      angle14to18: number;
      angle18to22: number;
      angle22to80: number;
    };
  };
  public avstTillFörstaLäkt: {
    [key: string]: number;
  };

  /** Nested map of all variants (last two digits and names) for each color and finish combination */
  public variants: {
    [key: string]: {
      [key: string]: {
        nr: string;
        label: string;
        artnr: string;
      };
    };
  } = {};
  public nockVariants: {
    [key: string]: {
      [key: string]: {
        nr: number;
        label: string;
        artnr: string;
      }[];
    };
  } = {};
  public accessoryVariants: {
    [key: number]: {
      [key: string]: {
        [key: string]: {
          nr: number;
          label: string;
          artnr: string;
        };
      };
    };
  } = {};
  public variantsLoaded: Promise<boolean>;
  public abortController = new AbortController();

  /** Map of the article to use for each article type for this family */
  public itemNos: { [key: string]: string } = {};
  public marketSpecificItemNos: { [key in MarketStr]?: { [key: string]: string } } = {};

  public bredd;

  /** Antal pannor per pall (Hämtas egentligen från C4) */
  public perPall;

  /** Max resterande avstånd i sidled som tillåts, om taket är mer än såhär mycket längre än pannorna måste en extra kolumn läggas till */
  public maxRest = 100;

  protected constructor(family: TileFamily, bredd: number, perPall: number, artNrBase = PrefixMap[family]) {
    this.artNrBase = artNrBase;
    this.family = family;
    this.label = '';
    this.key = '';
    this.material = Material.None;
    this.bredd = bredd;
    this.perPall = perPall;
    this.variantsLoaded = this.loadVariants();
    this.variantsLoaded.catch(() => {
      console.log('Cancelled requests for rooftile', TileFamily[family]);
    });
    this.läktavstånd = {};
    this.avstTillFörstaLäkt = {};
  }

  public getArticle(articleID: RoofArticle) {
    if (!projectStore) projectStore = useProjectStore();
    const market = projectStore.getMarket;
    return this.marketSpecificItemNos?.[market]?.[articleID] ?? this.itemNos[articleID];
  }

  /** Asynchronously load all valid variants for this tile */
  public async loadVariants(): Promise<boolean> {
    if (this.family == TileFamily.None) return true;

    // Cancel previous call if still running
    this.abortController.abort();
    this.abortController = new AbortController();
    const abortSignal = this.abortController.signal;

    const { getArticlesByPrefix } = useArticleStore();

    // Preload the article cache with data from disk if present
    try {
      const { addCache, addPrefixCache } = useArticleStore();
      const fs = await import('fs');
      const dataset = JSON.parse(fs.readFileSync(__dirname + '/../testingDataset.json', 'utf8'));
      if (dataset?.articles && dataset?.prefixes) {
        addCache(dataset.articles);
        for (const prefix of dataset.prefixes)
          addPrefixCache(
            prefix,
            dataset.articles.filter((art: { ArtNr: string }) => art?.ArtNr.startsWith(prefix)),
          );
      } else console.log("Couldn't parse the article cache file. Run `yarn test:setup` to cache article data");
    } catch (err) {
      if (import.meta.env.MODE == 'test') console.log("Couldn't load cached articles from disk. Run `yarn test:setup` to cache article data. Error: " + err);
    }

    // Build prefix batch
    const accessoryPrefixes = [
      RoofArticle.Avluftare,
      RoofArticle.AvluftarePlåt,
      RoofArticle.AvluftareLertegel,
      RoofArticle.Avrinningsplåt,
      RoofArticle.CBygel,
      RoofArticle.Ventilationshuv,
      RoofArticle.VentilationshuvMontering,
      RoofArticle.Kragrör,
      RoofArticle.GavelPannaNAHöger,
      RoofArticle.GavelPannaNAVänster,
      RoofArticle.GavelPannaVänster,
      RoofArticle.GavelPannaHöger,
      RoofArticle.GavelPannaVänsterBred,
      RoofArticle.GavelPannaHögerBred,
      RoofArticle.GavelBeslag,
      RoofArticle.DubbelvingadPanna,
      RoofArticle.PultPanna,
      RoofArticle.PultPannaHalv,
      RoofArticle.PultPannaGavelHöger,
      RoofArticle.PultPannaGavelVänster,
      RoofArticle.PultPannaGavelHöger,
      RoofArticle.PultPannaGavelVänster,
      RoofArticle.NockAnslutning,
      RoofArticle.Nockklammer,
      RoofArticle.NockklammerAlt,
      RoofArticle.NockKleeblatt,
      RoofArticle.Rändalstätning,
      RoofArticle.Tätningskloss,
      RoofArticle.Valmtätning300,
      RoofArticle.Valmtätning370,
      RoofArticle.Ventinock,

      // Roof security
      RoofArticle.Bärläktsteg45,
      RoofArticle.Bärläktsteg25,
      RoofArticle.KoppladeTaksteg,
      RoofArticle.SpårPanna,
      RoofArticle.SpårPannaHöger,
      RoofArticle.SpårPannaVänster,
      RoofArticle.Glidskydd,
      RoofArticle.Taklucka,
      RoofArticle.Säkerhetskrok,
      RoofArticle.Snökrok,
      RoofArticle.SnowSlideObstacle,
      RoofArticle.SnowSlideObstacleKonsol,
      RoofArticle.IsStoppare,
    ].reduce((acc, cur) => {
      const prefix = getArtPrefix(this.getArticle(cur));
      if (!prefix) return acc;

      if (acc[prefix]) acc[prefix].push(cur);
      else acc[prefix] = [cur];
      return acc;
    }, {} as { [key: string]: RoofArticle[] });

    const accArticles = await getArticlesByPrefix(
      Object.keys(accessoryPrefixes).join(','),
      false,
      {
        size: 1000,
      },
      abortSignal,
    );

    this.accessoryVariants = {};
    for (const artnr of Object.keys(accArticles)) {
      const accessories: RoofArticle[] = accessoryPrefixes[getArtPrefix(artnr)];
      const variant = (accArticles[artnr].ItemColorGroup || 'None') as keyof typeof TileFinish;
      const color = accArticles[artnr].ItemColorNumber as keyof typeof TileColor;

      for (const accessory of accessories) {
        if (!TileColor[color]) {
          if (color) console.warn(`Unknown color on accessory ${artnr}: ${color}`);
          continue;
        }

        if (this.accessoryVariants[accessory] === undefined) this.accessoryVariants[accessory] = {};
        if (this.accessoryVariants[accessory][color] === undefined) this.accessoryVariants[accessory][color] = {};
        this.accessoryVariants[accessory][color][variant] = {
          nr: accessory,
          label: '', // Gets overwritten later
          artnr: artnr,
        };
      }
    }

    // BENT-133: Directly replace specific colors of certain articles with other articles, unused
    for (const replacement of [
      // { from: '072220', to: '076220' },
      { from: 'H72120', to: 'H76320' },
      { from: 'H72124', to: 'H76324' },
      { from: 'H72324', to: 'H76324' },
    ]) {
      for (const key of Object.keys(this.itemNos)) {
        const part = Number(key) as RoofArticle;
        let article;
        // Match article number of this part to replacement target
        if (getArtPrefix(replacement.from) !== getArtPrefix(this.getArticle(part))) continue;
        if (!(article = await getArticle(replacement.to, abortSignal))) continue;

        if (this.accessoryVariants[part] === undefined) this.accessoryVariants[part] = {};
        this.accessoryVariants[part][TileColor[article.ItemColorNumber as keyof typeof TileColor]] = {
          None: {
            nr: part,
            label: '',
            artnr: replacement.to,
          },
        };
      }
    }

    // Load nock variants
    this.nockVariants = {};
    for (const nock of [this.getArticle(RoofArticle.NockPanna), this.getArticle(RoofArticle.NockPannaAlt)].filter(Boolean)) {
      const isAlt = nock === this.getArticle(RoofArticle.NockPannaAlt);
      // const nockbase = nock.replace(/[0-9]{2}\D*$/, "");
      const nockbase = getArtPrefix(nock);
      const nockArticles = await getArticlesByPrefix(nockbase, false, undefined, abortSignal);
      for (const artnr of Object.keys(nockArticles)) {
        const variant = nockArticles[artnr].ItemColorGroup as keyof typeof TileFinish;
        const color = nockArticles[artnr].ItemColorNumber as keyof typeof TileColor;

        // const colorCode = artnr.match(/[0-9]{2}(?:\.5)?\D*$/)?.[0];
        // if (!colorCode) continue;
        if (!TileFinish[variant]) {
          if (variant) console.warn(`Unknown finish on tile ${artnr}: ${variant}`);
          continue;
        }
        if (!TileColor[color]) {
          if (color) console.warn(`Unknown color on tile ${artnr}: ${color}`);
          continue;
        }

        if (this.nockVariants[TileFinish[variant]] === undefined) this.nockVariants[TileFinish[variant]] = { [TileColor[color]]: [] };
        if (this.nockVariants[TileFinish[variant]][TileColor[color]] === undefined) this.nockVariants[TileFinish[variant]][TileColor[color]] = [];
        this.nockVariants[TileFinish[variant]][TileColor[color]].push({
          nr: isAlt ? NockPannaVariant.Alt : NockPannaVariant.Standard,
          label: nockArticles[artnr].ItemLongDescription, // Gets overwritten in "nockOptions"
          artnr: artnr,
        });
      }
    }

    // Load tile variants (This depends on accessory and nock variants)
    const articles = await getArticlesByPrefix(this.artNrBase, false, undefined, abortSignal);

    // Loop through each article and add it to its variant bucket
    this.variants = {};
    for (const artnr of Object.keys(articles)) {
      const variant = articles[artnr].ItemColorGroup as keyof typeof TileFinish;
      const color = articles[artnr].ItemColorNumber as keyof typeof TileColor;

      const colorCode = artnr.match(/[0-9]{2}(?:\.5)?\D*$/)?.[0];
      if (!colorCode) continue;
      if (!TileFinish[variant]) {
        if (variant) console.warn(`Unknown finish on tile ${artnr}: ${variant}`);
        continue;
      }
      if (!TileColor[color]) {
        if (color) console.warn(`Unknown color on tile ${artnr}: ${color}`);
        continue;
      }

      // make sure the color is valid
      if (!allowedTileColors.includes(TileColor[color])) {
        continue;
      }

      // make sure the tile has matching "Nockpanna", "NockAnslutning" and "Hålpanna/Avluftare"
      if (
        !this.nockVariants?.[TileFinish[variant]]?.[TileColor[color]] ||
        (this.getArticle(RoofArticle.NockAnslutning) && !this.accessoryVariants?.[RoofArticle.NockAnslutning]?.[TileColor[color]]?.[variant]) ||
        (this.getArticle(RoofArticle.Avluftare) && !this.accessoryVariants?.[RoofArticle.Avluftare]?.[TileColor[color]]?.[variant]) ||
        (this.getArticle(RoofArticle.AvluftareLertegel) && !this.accessoryVariants?.[RoofArticle.AvluftareLertegel]?.[TileColor[color]]?.[variant])
      ) {
        if (import.meta.env.MODE != 'test')
          console.warn(`${TileFamily[this.family]}: Skipping tile ${artnr} because it has no matching nock or holepanna`, {
            nock: {
              variants: this.nockVariants,
              color: TileColor[color],
              finish: TileFinish[variant],
              valid: !!this.nockVariants?.[TileFinish[variant]]?.[TileColor[color]],
            },
            anslutning: {
              variants: this.accessoryVariants,
              color: TileColor[color],
              finish: variant,
              part: RoofArticle.NockAnslutning,
              valid: !!(this.getArticle(RoofArticle.NockAnslutning) && !!this.accessoryVariants?.[RoofArticle.NockAnslutning]?.[TileColor[color]]?.[variant]),
            },
            avluftare: {
              variants: this.accessoryVariants,
              color: TileColor[color],
              finish: variant,
              part: RoofArticle.Avluftare,
              valid: !!(this.getArticle(RoofArticle.Avluftare) && !!this.accessoryVariants?.[RoofArticle.Avluftare]?.[TileColor[color]]?.[variant]),
            },
          });
        continue;
      }

      if (this.variants[TileFinish[variant]] === undefined) this.variants[TileFinish[variant]] = {};

      // Exklusiv has a .5 article for each tile, make sure we don"t overwrite an existing variant with its .5 halftile variant
      if (this.variants[TileFinish[variant]][TileColor[color]] !== undefined && colorCode.endsWith('.5')) continue;

      this.variants[TileFinish[variant]][TileColor[color]] = {
        nr: colorCode,
        label: articles[artnr].ItemLongDescription,
        artnr: artnr,
      };
    }

    return true;
  }

  public getAccessoryVariant(
    part: RoofArticle,
    { color, finish = 'None', accessoryColor }: { color?: TileColor; finish?: TileFinish | keyof typeof TileFinish; accessoryColor?: AccessoryColor },
  ) {
    // This function could be called with TileFinish indices converted to string ("0", "1", ...), convert those values to TileFinish key
    if (isNaN(Number(finish)) === false) finish = TileFinish[Number(finish)] as keyof typeof TileFinish;

    let variant;
    // Based on tile color
    if (color) {
      variant = this.accessoryVariants[part]?.[color]?.[finish];
    }
    // Based on selected accessory color
    if (!variant && accessoryColor) {
      const colors = accessoryColorMap[accessoryColor];
      for (const key in this.accessoryVariants[part]) {
        let v;
        if (colors.includes(key)) v = this.accessoryVariants[part][key]?.[TileFinish[TileFinish.None]];
        if (v) {
          variant = v;
          break;
        }
      }
    }
    // Based on selected accessory color fallback (e.g If selected doesn't exist)
    if (!variant && accessoryColor) {
      const colors = accessoryColorMap[accessoryColorFallback[accessoryColor]];
      for (const key in this.accessoryVariants[part]) {
        let v;
        if (colors.includes(key)) v = this.accessoryVariants[part][key]?.[TileFinish[TileFinish.None]];
        if (v) {
          variant = v;
          break;
        }
      }
    }
    // If we STILL didn't find a color, see if the hardcoded variant exists (catches nockbygel Carisma)
    if (!variant && accessoryColor) {
      for (const color in this.accessoryVariants[part])
        for (const finish in this.accessoryVariants[part][color])
          if (this.accessoryVariants[part][color][finish].artnr == this.getArticle(part)) variant = this.accessoryVariants[part][color][finish];
    }

    return variant;
  }

  public finishOptions(): RadioOption[] {
    const options = [];
    for (const key in this.variants) {
      options.push({ value: key, label: TileFinishMap[key] });
    }
    return doArticleReplacements(options);
  }

  public colorOptions(finish: TileFinish): RadioOption[] {
    const options = [];
    for (const color in this.variants[finish]) {
      options.push({
        value: color,
        label: color,
        artnr: this.variants[finish][color].artnr,
      });
    }
    return doArticleReplacements(options.sort((a, b) => a.label.localeCompare(b.label, 'sv')));
  }

  public nockOptions(rooftype: RoofTypeIndex, extensionTypes: RoofTypeIndex[], finish: TileFinish, color: TileColor, angle: number, angleRange: {min: number, max: number}, market: MarketStr): RadioOption[] {
    if (this.family == TileFamily.None) return [];

    // BENT-166: Om huvudtak och alla utbyggnader är pulttak, ge inga nockpanneval
    if (
      rooftype == RoofTypeIndex.Pulttak &&
      extensionTypes.every((eType) =>
        [RoofTypeIndex.Front, RoofTypeIndex.FrontValm, RoofTypeIndex.FrontPulttak, RoofTypeIndex.PultPulttak, RoofTypeIndex.PultPulttakValm].includes(eType),
      )
    ) {
      return [];
    }

    const options = [] as RadioOption[];
    const labels = {} as { [key: number]: string };
    let defaultNock = NockPannaVariant.Standard;
    switch (this.family) {
      case TileFamily.PianoFalsatLertegel:
        labels[NockPannaVariant.Standard] = t('roof_tile.nock.piano');
        labels[NockPannaVariant.Alt] = t('roof_tile.nock.universal');
        break;
      case TileFamily.Carisma:
        labels[NockPannaVariant.Standard] = t('roof_tile.nock.carisma');
        labels[NockPannaVariant.Alt] = t('roof_tile.nock.straight');
        break;
      case TileFamily.HansaFalsatLertegel:
      case TileFamily.HansaFalsatLertegelRakFramkant:
      case TileFamily.TvillingFalsatLertegel:
      case TileFamily.TvillingLertegelRakFramkant:
      case TileFamily.Strängpressat2kupLertegel:
        labels[NockPannaVariant.Standard] = t('roof_tile.nock.universal');
        labels[NockPannaVariant.Alt] = t('roof_tile.nock.kleeblatt');
        break;
      case TileFamily.Exklusiv:
        labels[NockPannaVariant.Standard] = t('roof_tile.nock.straight');
        labels[NockPannaVariant.Alt] = t('roof_tile.nock.fals');
        defaultNock = market == MarketStr.Norge ? NockPannaVariant.Standard : NockPannaVariant.Alt;
        break;
      default:
        labels[NockPannaVariant.Standard] = t('roof_tile.nock.straight');
        labels[NockPannaVariant.Alt] = t('roof_tile.nock.fals');
        break;
    }

    const allowed = {
      [NockPannaVariant.Standard]: true,
      [NockPannaVariant.Alt]: true,
    } as { [key: number]: boolean };

    switch (this.family) {
      case TileFamily.Palema:
      case TileFamily.Exklusiv:
        if (angleRange.max > 45)
          allowed[NockPannaVariant.Alt] = false;
        break;
      case TileFamily.Carisma:
        allowed[NockPannaVariant.Standard] = false;
        if (angleRange.min >= 18 && angleRange.max <= 45) {
          allowed[NockPannaVariant.Standard] = true;
        }
        break;
      case TileFamily.HansaFalsatLertegel:
      case TileFamily.HansaFalsatLertegelRakFramkant:
      case TileFamily.TvillingFalsatLertegel:
      case TileFamily.TvillingLertegelRakFramkant:
        allowed[NockPannaVariant.Alt] = false;
        if (angleRange.min >= 14 && angleRange.max <= 50) {
          allowed[NockPannaVariant.Alt] = true;
        }
        break;
      case TileFamily.PianoFalsatLertegel:
        if ([RoofTypeIndex.Valm, RoofTypeIndex.Halvvalm, RoofTypeIndex.MansardValm, RoofTypeIndex.MansardHalvvalm]
              .includes(rooftype) && (angle < 25 || angle > 45)) {
          allowed[NockPannaVariant.Standard] = false;
        }else if (angle < 14 || angle > 45) {
          allowed[NockPannaVariant.Standard] = false;
        }
        break;
      case TileFamily.Strängpressat2kupLertegel:
        if (angle >= 51 && angle <= 80) {
          allowed[NockPannaVariant.Alt] = false;
        }
        break;
    }

    // BENT-138: Disallow Kleeblatt for mansard roofs when angle is outside 25-50 deg range
    for (const variant of [NockPannaVariant.Standard, NockPannaVariant.Alt]) {
      const itemNo = variant === NockPannaVariant.Standard ? this.getArticle(RoofArticle.NockPanna) : this.getArticle(RoofArticle.NockPannaAlt);
      if (
        itemNo?.startsWith('H304') &&
        [RoofTypeIndex.Mansard, RoofTypeIndex.MansardValm, RoofTypeIndex.MansardHalvvalm].includes(rooftype) &&
        (angle < 25 || angle > 50)
      )
        allowed[variant] = false;
    }

    if (!this.nockVariants[finish] || !this.nockVariants[finish][color]) return options;
    for (const nock of this.nockVariants[finish][color]) {
      if (!allowed[nock.nr]) continue;
      options.push({
        value: nock.nr,
        label: labels[nock.nr], // TODO: Remove extra labels used for debugging
        artnr: nock.artnr,
        default: nock.nr === defaultNock,
      });
    }
    return doArticleReplacements(options.sort((a, b) => Number(a.value) - Number(b.value)));
  }

  public nockExtraOptions(nock: NockPannaVariant): RadioOption[] {
    let options = [] as RadioOption[];
    switch (this.family) {
      case TileFamily.Carisma:
        // Only display nockextras for the standard nock
        if (nock === NockPannaVariant.Alt) break;
        options = [
          {
            value: NockPannaExtra.Nockbygel,
            label: '', // overwritten
          },
          {
            value: NockPannaExtra.Overlapp,
            label: '', // overwritten
          },
        ];
        break;
    }
    return doArticleReplacements(options);
  }

  public accessoryColorOptions(color: TileColor): RadioOption[] {
    let def = AccessoryColor.None;
    switch (color) {
      case TileColor.Ljusgrå:
      case TileColor.Mellangrå:
      case TileColor.Platinagrå:
      case TileColor.Gul:
        def = AccessoryColor.GalvanizedGreySilver;
        break;
      case TileColor.Brun:
      case TileColor.Grön:
      case TileColor.Svart:
      case TileColor.Koppar:
      case TileColor.Grafit:
      case TileColor.Skiffer:
      case TileColor.Olivgrön:
      case TileColor.Granit:
      case TileColor.Bongossibrun:
      case TileColor.Titansvart:
      case TileColor.Nötbrun:
      case TileColor.Skiffersvart:
      case TileColor.Mörkgrå:
        def = AccessoryColor.Black;
        break;
      case TileColor['Flash Tegelröd/Brun']:
      case TileColor.Röd:
      case TileColor.Tegelröd:
      case TileColor.Vinröd:
      case TileColor.Kastanjebrun:
      case TileColor.Terracottaröd:
      case TileColor['Antik Tegelröd/Svart']:
      case TileColor.Indiaröd:
      case TileColor.Gammalröd:
      case TileColor.Naturröd:
        def = AccessoryColor.BrickRed;
        break;
    }

    const options = [];
    for (const color in AccessoryColor) {
      const c = Number(color) as AccessoryColor;
      if (!c) continue;
      options.push({
        value: c,
        label: color,
        default: c === def,
      });
    }
    return doArticleReplacements(options.sort((a, b) => a.label.localeCompare(b.label, 'sv')));
  }

  public drainAeratorOptions(mainRoofAngle: number, market: MarketStr): RadioOption[] {
    let options = [DrainAerator.None];
    let defaultDrain = DrainAerator.None;

    if (market === MarketStr.Tyskland) {
      switch (this.family) {
        case TileFamily.Palema:
        case TileFamily.Mecklenburger:
        case TileFamily.Carisma:
          options.push(DrainAerator.Concrete);
          options.push(DrainAerator.PlastDunstrohrlufter);
          options.push(DrainAerator.PlastEntwasserunglu);
          options.push(DrainAerator.PlastDunstrohrlufter125);
          options.push(DrainAerator.PlastEntwasserunglu125);
          options.push(DrainAerator.PlastDunstrohrlufter160);
          break;
        case TileFamily.Exklusiv:
          options.push(DrainAerator.PlastDunstrohrlufter);
          options.push(DrainAerator.PlastDunstrohrlufter160);
          break;
      }

      defaultDrain = DrainAerator.Concrete;

    }else {
      switch (this.family) {
        case TileFamily.Exklusiv:
        case TileFamily.Strängpressat2kupLertegel:
          options.push(DrainAerator.Plate);
          break;
        case TileFamily.HansaFalsatLertegel:
        case TileFamily.HansaFalsatLertegelRakFramkant:
        case TileFamily.TvillingFalsatLertegel:
        case TileFamily.PianoFalsatLertegel:
        case TileFamily.TvillingLertegelRakFramkant:
          options = [...options, DrainAerator.Clay, DrainAerator.Plate];
          break;
        default:
          options = [...options, DrainAerator.Concrete, DrainAerator.Plate];
      }

      switch (this.family) {
        case TileFamily.Palema:
        case TileFamily.Carisma:
          defaultDrain = DrainAerator.Concrete;
          break;
        case TileFamily.Exklusiv:
        case TileFamily.Strängpressat2kupLertegel:
          defaultDrain = DrainAerator.Plate;
          break;
        case TileFamily.HansaFalsatLertegel:
        case TileFamily.HansaFalsatLertegelRakFramkant:
        case TileFamily.TvillingFalsatLertegel:
        case TileFamily.PianoFalsatLertegel:
        case TileFamily.TvillingLertegelRakFramkant:
          defaultDrain = DrainAerator.Clay;
          break;
      }

      if (mainRoofAngle > 45) {
        options.splice(options.indexOf(DrainAerator.Plate));
        if (defaultDrain == DrainAerator.Plate) defaultDrain = options[options.length - 1];
      }
    }

    return doArticleReplacements(
      options.map((o) => ({
        value: o,
        label: '',
        default: o === defaultDrain,
      })),
    );
  }

  public drainAeratorItems(
    selected: DrainAerator,
    finish: TileFinish,
    color: TileColor,
    accessoryColor: AccessoryColor,
    underlagstak: Underlagstak,
    market: MarketStr,
  ): RadioOption[] {
    const items = [];
    let parts = [] as RoofArticle[];

    if (market === MarketStr.Tyskland) {
      switch (selected) {
        case DrainAerator.Concrete:
          parts = [RoofArticle.Avluftare, RoofArticle.AvluftareTyskSpitze, RoofArticle.LösVenthattSpitze];
          break;
        case DrainAerator.PlastDunstrohrlufter:
          parts = [RoofArticle.Dunstrohrlufter100110, RoofArticle.FlexrörAvluftare10070];
          break;
        case DrainAerator.PlastEntwasserunglu:
          parts = [RoofArticle.Entwässerunglü100110, RoofArticle.FlexrörAvluftare10070];
          break;
        case DrainAerator.PlastDunstrohrlufter125:
          parts = [RoofArticle.Dunstrohrlufter125, RoofArticle.FlexrörAvluftare12570];
          break;
        case DrainAerator.PlastEntwasserunglu125:
          parts = [RoofArticle.Entwässerunglü125, RoofArticle.FlexrörAvluftare12570];
          break;
        case DrainAerator.PlastDunstrohrlufter160:
          parts = [RoofArticle.Dunstrohrlufter160];
          break;
      }

    }else {
      switch (selected) {
        case DrainAerator.Concrete:
          parts = [RoofArticle.Avluftare, RoofArticle.Kragrör];
          if (underlagstak !== Underlagstak.RåspontMedPapp) parts.push(RoofArticle.Rififi);
          break;
        case DrainAerator.Plate:
          parts = [RoofArticle.AvluftarePlåt];
          if (underlagstak !== Underlagstak.RåspontMedPapp) parts.push(RoofArticle.AvluftarePlåtMontering);
          break;
        case DrainAerator.Clay:
          parts = [RoofArticle.AvluftareLertegel];
      }
    }

    for (const part of parts) {
      if (!this.getArticle(part)) continue;
      const variant = this.getAccessoryVariant(part, { color, finish, accessoryColor });
      items.push({
        value: part,
        label: this.getArticle(part),
        artnr: variant?.artnr ?? this.getArticle(part),
      });
    }

    return doArticleReplacements(items);
  }

  public ventilationPassageOptions(finish: TileFinish, color: TileColor, accessoryColor: AccessoryColor, market: MarketStr): RadioOption[] {
    if (market !== MarketStr.Tyskland) return [];

    let options: VentilationPassage[] = [];

    if ([TileFamily.Palema, TileFamily.Carisma, TileFamily.Mecklenburger].includes(this.family)) {
      options = [VentilationPassage.GastDurchgGS, VentilationPassage.AntennenDurchgPanna, VentilationPassage.Betong];
    }else if ([TileFamily.Exklusiv].includes(this.family)) {
      options = [VentilationPassage.Plast, VentilationPassage.Metall];
    }

    const optionMap = {
      [VentilationPassage.GastDurchgGS]: RoofArticle.GastDurchgGS,
      [VentilationPassage.AntennenDurchgPanna]: RoofArticle.AntennenDurchgPanna,
      [VentilationPassage.AntennenAufsatz]: RoofArticle.AntennenAufsatz,
      [VentilationPassage.Betong]: RoofArticle.AntennenAufsatz,
      [VentilationPassage.Plast]: RoofArticle.GastDurchgGS,
      [VentilationPassage.Metall]: RoofArticle.AntennenAufsatz,
    } as { [key: number]: RoofArticle };

    const labelMap = {
      [VentilationPassage.GastDurchgGS]: 'accessory.ventilation_passages.gastdurchggs',
      [VentilationPassage.AntennenDurchgPanna]: 'accessory.ventilation_passages.antennendurchgangspfanne',
      [VentilationPassage.Betong]: 'accessory.ventilation_passages.concrete',
      [VentilationPassage.Plast]: 'accessory.ventilation_passages.plastic',
      [VentilationPassage.Metall]: 'accessory.ventilation_passages.metal',
    } as { [key: number]: string };

    return doArticleReplacements(
      options.map((option) => {
        const mapped = optionMap[option];
        const artnr = this.getArticle(mapped);
        const variant = this.getAccessoryVariant(mapped, { color, finish, accessoryColor });
        return {
          value: option,
          label: labelMap[option] ? t(labelMap[option]) : artnr,
          artnr: variant?.artnr || artnr,
        };
      }),
    );
  }

  public ventilationHoodOptions(angle: number, market: MarketStr): VentilationHood[] {
    if (angle > 45 || market == MarketStr.Tyskland) return [VentilationHood.None];
    else return [VentilationHood.None, VentilationHood.Plate];
  }

  public ventilationHoodItems(
    selected: VentilationHood,
    underlagstak: Underlagstak,
    accessoryColor: AccessoryColor,
    family: TileFamily,
    color: TileColor,
  ): RadioOption[] {
    const items = [];
    let parts = [] as RoofArticle[];

    switch (selected) {
      case VentilationHood.Plate:
        parts = [RoofArticle.Ventilationshuv];
        if (underlagstak !== Underlagstak.RåspontMedPapp) {
          parts.push(RoofArticle.VentilationshuvMontering);
        }
        break;
    }

    for (const part of parts) {
      const variant = this.getAccessoryVariant(part, { accessoryColor });
      let artnr = variant?.artnr ?? this.getArticle(part);

      // These hould be a general rule in the future, but for now it's only for these specific cases
      // TODO: Remove this when the general rule is in place
      if (
        [TileFamily.TvillingFalsatLertegel, TileFamily.TvillingLertegelRakFramkant].includes(family) &&
        color === TileColor.Titansvart &&
        artnr === 'H88520'
      ) {
        artnr = 'H88587';
      }

      // TODO: Remove this when the general rule is in place
      if (
        [TileFamily.HansaFalsatLertegel, TileFamily.HansaFalsatLertegelRakFramkant].includes(family) &&
        color === TileColor.Titansvart &&
        artnr === 'H88624'
      ) {
        artnr = 'H88687';
      }

      items.push({
        value: part,
        label: artnr,
        artnr: artnr,
      });
    }
    return doArticleReplacements(items);
  }

  public sealantOptions(
    roofType: RoofTypeIndex,
    family: TileFamily,
    connector: NockConnector,
    angle: { min: number; max: number },
    accessoryColor: AccessoryColor,
    market: MarketStr,
  ): RadioOption[] {
    if (connector === NockConnector.Yes && [RoofTypeIndex.Sadel, RoofTypeIndex.Mansard, RoofTypeIndex.Pulttak].includes(roofType)) return [];

    const isValm = [RoofTypeIndex.Valm, RoofTypeIndex.Halvvalm, RoofTypeIndex.MansardValm, RoofTypeIndex.MansardHalvvalm].includes(roofType);
    let def = Sealant.None;
    switch (family) {
      case TileFamily.Palema:
        def = Sealant.NockValmRoll300mm;
        break;
      case TileFamily.Exklusiv:
        if (isValm) {
          def = Sealant.NockValmRoll370mm;
        } else {
          def = Sealant.NockValmRoll300mm;
        }
        break;
      case TileFamily.Carisma:
      case TileFamily.PianoFalsatLertegel:
      case TileFamily.Strängpressat2kupLertegel:
        def = Sealant.NockValmRoll300mm;
        break;
      case TileFamily.HansaFalsatLertegel:
      case TileFamily.HansaFalsatLertegelRakFramkant:
        def = Sealant.NockValmRoll370mm;
        break;
      case TileFamily.TvillingFalsatLertegel:
      case TileFamily.TvillingLertegelRakFramkant:
        if (isValm) {
          def = Sealant.NockValmRoll370mm;
        } else if (angle.max >= 14 && angle.max <= 26) {
          def = Sealant.NockValmRoll300mm;
        } else if (angle.max >= 27 && angle.max <= 80) {
          def = Sealant.NockValmRoll370mm;
        }
        break;
    }
    if (market == MarketStr.Norge) {
      // BENT-178
      def = Sealant.NockValmRoll300mm;
    }

    let options = [Sealant.NockValmRoll300mm, Sealant.NockValmRoll370mm];
    const optionMap = {
      [Sealant.NockValmRoll300mm]: RoofArticle.Valmtätning300,
      [Sealant.NockValmRoll340mm]: RoofArticle.Valmtätning340,
      [Sealant.NockValmRoll370mm]: RoofArticle.Valmtätning370,
      [Sealant.Ventinock]: RoofArticle.Ventinock,
    } as { [key: number]: RoofArticle };
    switch (family) {
      case TileFamily.Palema:
        options.push(Sealant.Ventinock);
        break;
    }

    if (market == MarketStr.Tyskland) {
      options = [Sealant.NockValmRoll300mm, Sealant.NockValmRoll340mm, Sealant.NockValmRoll370mm];
      def = Sealant.NockValmRoll300mm;
    }

    return doArticleReplacements(
      options.map((option) => {
        const mapped = optionMap[option];
        const variant = this.getAccessoryVariant(mapped, { accessoryColor });
        return {
          value: option,
          label: '', // overwritten by label in component
          artnr: variant?.artnr ?? this.getArticle(mapped),
          default: option === def,
        };
      }),
    );
  }

  public ventilationOptions(family: TileFamily, finish: TileFinish, color: TileColor, accessoryColor: AccessoryColor, market: MarketStr): RadioOption[] {
    let def = Ventilation.Birdband1m;
    let options = [
      Ventilation.Birdband1m,
      Ventilation.Birdband5mRollBlack,
      Ventilation.Birdband5mRollBrickRed,
      Ventilation.VentilatingBirdband1m,
    ] as Ventilation[];

    switch (family) {
      case TileFamily.Carisma:
      case TileFamily.PianoFalsatLertegel:
        options = [];
        break;
    }

    if (market == MarketStr.Tyskland) {
      // options = [Ventilation.Tätningsbeslag, Ventilation.RidgePlate, Ventilation.RidgePlateMount, Ventilation.ChimneySealant5m, Ventilation.ChimneySealant7m, Ventilation.ChimneySealant8m, Ventilation.RidgeSealant];

      switch (family) {
        case TileFamily.Palema:
        case TileFamily.Exklusiv:
        case TileFamily.Mecklenburger:
          // options.push(Ventilation.VentivalmPlast, Ventilation.VentivalmBorst, Ventilation.Birdband1m, Ventilation.VentilatingBirdband1m);
          options = [Ventilation.Birdband1m, Ventilation.VentilatingBirdband1m];
          def = Ventilation.Birdband1m;
          break;
        case TileFamily.Carisma:
          options = [Ventilation.VentilatingLath1m];
          def = Ventilation.VentilatingLath1m;
          break;
      }
    }

    const optionMap = {
      [Ventilation.Birdband1m]: RoofArticle.Fågelband,
      [Ventilation.Birdband5mRollBlack]: RoofArticle.Fågelband5mSvart,
      [Ventilation.Birdband5mRollBrickRed]: RoofArticle.Fågelband5mRöd,
      [Ventilation.VentilatingBirdband1m]: RoofArticle.VentiFågelband,
      [Ventilation.Tätningsbeslag]: RoofArticle.Tätningsbeslag,
      [Ventilation.RidgePlate]: RoofArticle.RidgePlate,
      [Ventilation.RidgePlateMount]: RoofArticle.RidgePlateMount,
      [Ventilation.ChimneySealant5m]: RoofArticle.ChimneySealant5m,
      [Ventilation.ChimneySealant7m]: RoofArticle.ChimneySealant7m,
      [Ventilation.ChimneySealant8m]: RoofArticle.ChimneySealant8m,
      [Ventilation.RidgeSealant]: RoofArticle.RidgeSealant,
      [Ventilation.VentivalmPlast]: RoofArticle.VentivalmPlast,
      [Ventilation.VentivalmBorst]: RoofArticle.VentivalmBorst,
      [Ventilation.VentilatingLath1m]: RoofArticle.VentiLäktPlast,
    } as { [key: number]: RoofArticle };

    switch (market) {
      case MarketStr.Norge:
        if (accessoryColor === AccessoryColor.BrickRed) {
          def = Ventilation.Birdband5mRollBrickRed;
        } else {
          def = Ventilation.Birdband5mRollBlack;
        }
        break;
    }

    return doArticleReplacements(
      options.map((option) => {
        const mapped = optionMap[option];
        let artnr = this.getArticle(mapped);

        // Fågelband har fasta artikelnummer, alla andra måste vi hämta rätt variant för
        if (![Ventilation.Birdband1m, Ventilation.Birdband5mRollBlack,
            Ventilation.Birdband5mRollBrickRed, Ventilation.VentilatingBirdband1m, Ventilation.VentilatingLath1m].includes(option)) {
          const variant = this.getAccessoryVariant(mapped, { color, finish, accessoryColor });
          if (variant?.artnr) artnr = variant?.artnr;
        }

        return {
          value: option,
          label: artnr, // overwritten by label in component
          artnr: artnr,
          default: option === def,
        };
      }),
    );
  }

  public fixingsTileOptions(family: TileFamily, underlagstak: Underlagstak, market: MarketStr): RadioOption[] {
    const options = new FixingsTileOptionsBuilder(this, market);
    const rOptions = options.underlagstak(underlagstak).family(family).setDefault(family, underlagstak).build();

    // Move EasyClip45x70 to the front, all other options are ordered correctly by their enum order but this one was added later
    let easyClipIndex;
    const easyClipArtnr = getRoofTile(family)?.getArticle(RoofArticle.EasyClip45x70) ?? '999999';
    if ((easyClipIndex = rOptions.findIndex(o => o.artnr === easyClipArtnr)) != -1) {
      const easyClipOption = rOptions.splice(easyClipIndex, 1)[0];
      rOptions.splice(0, 0, easyClipOption);
    }

    return rOptions;
  }

  public fixingsTileOptionsOld(family: TileFamily, underlagstak: Underlagstak): RadioOption[] {
    let def = FixingsTile.None;
    switch (family) {
      case TileFamily.Palema:
        if (underlagstak === Underlagstak.RåspontMedPapp) {
          def = FixingsTile.EasyClip;
        } else {
          def = FixingsTile.Screw;
        }
        break;
      case TileFamily.Exklusiv:
        if (underlagstak === Underlagstak.RåspontMedPapp) {
          def = FixingsTile.EasyClip;
        } else {
          def = FixingsTile.Stormclips;
        }
        break;
      case TileFamily.Carisma:
        def = FixingsTile.Screw;
        break;
      case TileFamily.PianoFalsatLertegel:
        if (underlagstak === Underlagstak.RåspontMedPapp) {
          def = FixingsTile.EasyClip;
        } else {
          def = FixingsTile.DiagonalClip;
        }
        break;
      case TileFamily.HansaFalsatLertegel:
      case TileFamily.HansaFalsatLertegelRakFramkant:
      case TileFamily.TvillingFalsatLertegel:
      case TileFamily.TvillingLertegelRakFramkant:
        if (underlagstak === Underlagstak.RåspontMedPapp) {
          def = FixingsTile.EasyClip;
        } else {
          def = FixingsTile.DiagonalClip;
        }
        break;
      case TileFamily.Strängpressat2kupLertegel:
        def = FixingsTile.RoofhookJP;
        break;
    }

    // Fixing tile
    let options = [] as FixingsTile[];
    switch (family) {
      case TileFamily.Palema:
        if (underlagstak === Underlagstak.RåspontMedPapp) {
          options = [FixingsTile.EasyClip, FixingsTile.Screw, FixingsTile.Nail, FixingsTile.Stormclips];
        } else {
          options = [FixingsTile.Screw, FixingsTile.Nail, FixingsTile.Stormclips];
        }
        break;
      case TileFamily.Exklusiv:
        if (underlagstak === Underlagstak.RåspontMedPapp) {
          options = [FixingsTile.EasyClip, FixingsTile.Stormclips];
        } else {
          options = [FixingsTile.Stormclips];
        }
        break;
      case TileFamily.Carisma:
        options = [FixingsTile.Screw, FixingsTile.Stormclips];
        break;
      case TileFamily.PianoFalsatLertegel:
        if (underlagstak === Underlagstak.RåspontMedPapp) {
          options = [FixingsTile.EasyClip, FixingsTile.DiagonalClipZial];
        } else {
          options = [FixingsTile.DiagonalClip];
        }
        break;
      case TileFamily.HansaFalsatLertegel:
      case TileFamily.HansaFalsatLertegelRakFramkant:
      case TileFamily.TvillingFalsatLertegel:
      case TileFamily.TvillingLertegelRakFramkant:
        if (underlagstak === Underlagstak.RåspontMedPapp) {
          options = [FixingsTile.EasyClip, FixingsTile.SidefoldStaples, FixingsTile.DiagonalClipZial];
        } else {
          options = [FixingsTile.SidefoldStaples, FixingsTile.DiagonalClip];
        }
        break;
      case TileFamily.Strängpressat2kupLertegel:
        options = [FixingsTile.RoofhookJP, FixingsTile.SBracket, FixingsTile.RooftileHook];
        break;
    }

    const optionMap = {
      [FixingsTile.EasyClip]: RoofArticle.EasyClip,
      [FixingsTile.Screw]: RoofArticle.SkruvPanna,
      [FixingsTile.Nail]: RoofArticle.SpikPanna,
      [FixingsTile.Stormclips]: RoofArticle.Stormclips,
      [FixingsTile.DiagonalClip]: RoofArticle.DiagonalClip,
      [FixingsTile.DiagonalClipZial]: RoofArticle.DiagonalClipZial,
      [FixingsTile.SidefoldStaples]: RoofArticle.SidofallsKlammer,
      [FixingsTile.RoofhookJP]: RoofArticle.TakkrokJP,
      [FixingsTile.SBracket]: RoofArticle.SKlammer,
      [FixingsTile.RooftileHook]: RoofArticle.Takpannekrok,
    } as { [key: number]: RoofArticle };

    return doArticleReplacements(
      options.map((option) => {
        const mapped = optionMap[option];
        return {
          value: option,
          label: this.getArticle(mapped), // overwritten by label in component
          artnr: this.getArticle(mapped),
          default: option === def,
        };
      }),
    );
  }

  public fixingsNockOptions(exposedWeather: ExposedWeather, family: TileFamily, nock: NockPannaVariant, market: MarketStr): RadioOption[] {
    if (nock === NockPannaVariant.None) return [];

    // Fixings nock
    let options = [] as FixingsNock[];
    let nockTile = NockTile.None;
    switch (family) {
      case TileFamily.PianoFalsatLertegel:
        nockTile = nock === NockPannaVariant.Standard ? NockTile.NockPiano : NockTile.NockUniversal;
        break;
      case TileFamily.Carisma:
        nockTile = nock === NockPannaVariant.Standard ? NockTile.NockCarismaBygel : NockTile.NockCarismaÖverlapp;
        break;
      case TileFamily.HansaFalsatLertegel:
      case TileFamily.HansaFalsatLertegelRakFramkant:
      case TileFamily.TvillingFalsatLertegel:
      case TileFamily.TvillingLertegelRakFramkant:
      case TileFamily.Strängpressat2kupLertegel:
        nockTile = nock === NockPannaVariant.Standard ? NockTile.NockUniversal : NockTile.NockKleeblatt;
        break;
      default:
        nockTile = nock === NockPannaVariant.Standard ? NockTile.Nock230250 : NockTile.Nock300Fals;
        break;
    }

    let def = FixingsNock.None;
    switch (nockTile) {
      case NockTile.Nock230250:
        def = exposedWeather === ExposedWeather.Yes ? FixingsNock.NockScrewAndStaples : FixingsNock.NockScrew;
        break;
      case NockTile.NockUniversal:
        def = exposedWeather === ExposedWeather.Yes ? FixingsNock.NockScrewAndStaplesHansa : FixingsNock.NockScrew;
        break;
      case NockTile.NockKleeblatt:
        def = exposedWeather === ExposedWeather.Yes ? FixingsNock.NockScrewAndNockClip : FixingsNock.NockScrew;
        break;
      case NockTile.Nock300Fals:
      case NockTile.NockCarismaBygel:
      case NockTile.NockCarismaÖverlapp:
      case NockTile.NockPiano:
        def = FixingsNock.NockScrew;
    }

    switch (nockTile) {
      case NockTile.NockUniversal:
        if (exposedWeather === ExposedWeather.No) {
          options = [FixingsNock.NockScrew];
        }
        options = [...options, FixingsNock.NockScrewAndStaplesHansa];
        break;
      case NockTile.NockKleeblatt:
        if (exposedWeather === ExposedWeather.No) {
          options = [FixingsNock.NockScrew];
        }
        options = [...options, FixingsNock.NockScrewAndNockClip];
        break;
      case NockTile.Nock230250:
        if (exposedWeather === ExposedWeather.No) {
          options = [FixingsNock.NockScrew];
        }
        options = [...options, FixingsNock.NockScrewAndStaples];
        break;
      case NockTile.Nock300Fals:
      case NockTile.NockCarismaBygel:
      case NockTile.NockCarismaÖverlapp:
      default:
        options = [FixingsNock.NockScrew];
        break;
    }

    if (market == MarketStr.Tyskland) {
      if ([NockTile.NockCarismaBygel].includes(nockTile)) {
        options = [FixingsNock.ConcreteAccessoryScrewStainless];
        def = FixingsNock.ConcreteAccessoryScrewStainless;
      }else {
        const nockKlammer = (nock == NockPannaVariant.Standard) ? FixingsNock.Nockklammer : FixingsNock.NockklammerAlt;
        options = [nockKlammer, FixingsNock.ConcreteAccessoryScrew, FixingsNock.ConcreteAccessoryScrewStainless];
        def = nockKlammer;
      }
    }

    const optionMap = {
      [FixingsNock.NockScrew]: RoofArticle.Nockskruv,
      [FixingsNock.NockScrewAndStaples]: RoofArticle.Nockklammer,
      [FixingsNock.NockScrewAndStaplesHansa]: RoofArticle.Nockklammer,
      [FixingsNock.NockScrewAndNockClip]: RoofArticle.NockKleeblatt,
      [FixingsNock.Nockklammer]: RoofArticle.Nockklammer,
      [FixingsNock.NockklammerAlt]: RoofArticle.NockklammerAlt,
      [FixingsNock.ConcreteAccessoryScrew]: RoofArticle.SkruvTillBetongtillbehör,
      [FixingsNock.ConcreteAccessoryScrewStainless]: RoofArticle.SkruvTillBetongtillbehörRostfri,
    } as { [key: number]: RoofArticle };

    return doArticleReplacements(
      options.map((option) => {
        const mapped = optionMap[option];
        return {
          value: option,
          label: this.getArticle(mapped), // overwritten by label in component
          default: option === def,
        };
      }),
    );
  }

  public fixingsNockItems(selected: FixingsNock, accessoryColor: AccessoryColor): RadioOption[] {
    const items = [];
    let parts = [] as RoofArticle[];

    switch (selected) {
      case FixingsNock.NockScrew:
        parts = [RoofArticle.Nockskruv];
        break;
      case FixingsNock.NockScrewAndStaples:
        parts = [RoofArticle.Nockskruv, RoofArticle.Nockklammer];
        break;
      case FixingsNock.NockScrewAndStaplesHansa:
        parts = [RoofArticle.Nockskruv, RoofArticle.Nockklammer];
        break;
      case FixingsNock.NockScrewAndNockClip:
        parts = [RoofArticle.Nockskruv, RoofArticle.NockKleeblatt];
        break;
      case FixingsNock.Nockklammer:
        parts = [RoofArticle.Nockklammer];
        break;
      case FixingsNock.NockklammerAlt:
        parts = [RoofArticle.NockklammerAlt];
        break;
      case FixingsNock.ConcreteAccessoryScrew:
        parts = [RoofArticle.SkruvTillBetongtillbehör];
        break;
      case FixingsNock.ConcreteAccessoryScrewStainless:
        parts = [RoofArticle.SkruvTillBetongtillbehörRostfri];
        break;
    }

    for (const part of parts) {
      if (!this.getArticle(part)) continue;
      const variant = this.getAccessoryVariant(part, { accessoryColor });
      items.push({
        value: part,
        label: this.getArticle(part),
        artnr: variant?.artnr ?? this.getArticle(part),
      });
    }
    return doArticleReplacements(items);
  }

  public fixingsRidgeOptions(hasRidges: bool, market: MarketStr): RadioOption[] {
    if (!hasRidges || market != MarketStr.Tyskland)
      return [];

    return [FixingsRidge.Yes, FixingsRidge.No];
  }

  public fixingsRidgeItems(choice: FixingsRidge): RadioOption[] {
    if (choice == FixingsRidge.Yes) {
      const part = RoofArticle.RänndalsOValmklipps;
      const art = this.getArticle(part);
      if (!art) return [];
      const items = [{
        value: part,
        label: art,
        artnr: art,
      }];
      return doArticleReplacements(items);
    }
    return [];
  }

  public nockConnectorOptions(family: TileFamily, market: MarketStr): RadioOption[] {
    // Only show nock connectors if hansa or tvilling is selected
    if (
      [
        TileFamily.HansaFalsatLertegel,
        TileFamily.HansaFalsatLertegelRakFramkant,
        TileFamily.TvillingFalsatLertegel,
        TileFamily.TvillingLertegelRakFramkant,
      ].includes(family)
    ) {
      const defaultVal = market !== MarketStr.Norge;
      return [
        {
          label: 'no',
          value: NockConnector.No,
          default: !defaultVal,
        },
        {
          label: 'yes',
          value: NockConnector.Yes,
          default: defaultVal,
        },
      ];
    }

    return [];
  }

  public nockConnectorItems(finish: TileFinish, color: TileColor): RadioOption[] {
    const part = RoofArticle.NockAnslutning as RoofArticle;
    const f = TileFinish[Number(finish)] as keyof typeof TileFinish;
    const variant = this.getAccessoryVariant(part, { finish: f, color });
    return doArticleReplacements([
      {
        value: part,
        label: '', // overwritten by label in component
        artnr: variant?.artnr ?? this.getArticle(part),
      },
    ]);
  }

  public gableOptions(rooftype: RoofTypeIndex, family: TileFamily, finish: TileFinish, color: TileColor, market: MarketStr): RadioOption[] {
    if ([RoofTypeIndex.Valm, RoofTypeIndex.MansardValm].includes(rooftype)) return [];

    let options = [] as Gable[];
    switch (family) {
      case TileFamily.Palema:
      case TileFamily.Exklusiv:
        options = [Gable.None, Gable.GablePan, Gable.GableFittings];
        break;
      case TileFamily.Carisma:
        options = [Gable.None, Gable.GablePanWide, Gable.GableFittings];
        break;
      case TileFamily.HansaFalsatLertegel:
      case TileFamily.TvillingFalsatLertegel:
      case TileFamily.PianoFalsatLertegel:
        options = [Gable.None, Gable.GablePanNA];
        break;
      case TileFamily.Mecklenburger:
        options = [Gable.None, Gable.GablePan, Gable.GableFittings];
        break;
    }
    if (market === MarketStr.Tyskland && [TileFamily.Palema, TileFamily.Mecklenburger].includes(family))
      options.push(Gable.DoubleWingedTile);

    const optionMap = {
      [Gable.GablePan]: RoofArticle.GavelPannaHöger,
      [Gable.GableFittings]: RoofArticle.GavelBeslag,
      [Gable.GablePanNA]: RoofArticle.GavelPannaNAHöger,
      [Gable.GablePanWide]: RoofArticle.GavelPannaHögerBred,
      [Gable.DoubleWingedTile]: RoofArticle.GavelPannaHögerBred,
    };

    const f = TileFinish[Number(finish)] as keyof typeof TileFinish;
    options = options.filter((option) => option === Gable.None || !!this.accessoryVariants[optionMap[option]]?.[color]?.[f]);

    if (options.length === 1) return []; // Don"t show if the only option is None (None should always be shown alongside the other options)

    return doArticleReplacements(
      options.map((option) => {
        return {
          value: option,
          label: '', // overwritten by label in component
        };
      }),
    );
  }

  public gableItems(gable: Gable, finish: TileFinish, color: TileColor): RadioOption[] {
    const items = [];
    let parts = [] as RoofArticle[];

    // Only need to display the image of the right side of the gable
    switch (gable) {
      case Gable.GablePan:
        parts = [RoofArticle.GavelPannaHöger];
        break;
      case Gable.GableFittings:
        parts = [RoofArticle.GavelBeslag];
        break;
      case Gable.GablePanNA:
        parts = [RoofArticle.GavelPannaNAHöger];
        break;
      case Gable.GablePanWide:
        parts = [RoofArticle.GavelPannaHögerBred];
        break;
      case Gable.DoubleWingedTile:
        parts = [RoofArticle.DubbelvingadPanna];
        break;
    }

    const f = TileFinish[Number(finish)] as keyof typeof TileFinish;
    for (const part of parts) {
      if (!this.getArticle(part)) continue;
      let variant = this.accessoryVariants[part]?.[color]?.[f] ?? this.accessoryVariants[part]?.[color]?.[TileFinish.None];

      // Get the first variant if no variant is found
      if (!variant) {
        variant = this.accessoryVariants[part]?.[color]?.[Object.keys(this.accessoryVariants[part][color])[0]];
      }

      items.push({
        value: part,
        label: this.getArticle(part),
        artnr: variant?.artnr ?? this.getArticle(part),
      });
    }
    return doArticleReplacements(items);
  }

  public pultOptions(rooftype: RoofTypeIndex, market: MarketStr): RadioOption[] {
    if (![RoofTypeIndex.Pulttak].includes(rooftype)) return [];

    if (market != MarketStr.Tyskland) return [];

    let options = [PultPanna.None] as PultPanna[];
    switch (this.family) {
      case TileFamily.Palema:
      case TileFamily.Exklusiv:
      case TileFamily.Carisma:
      case TileFamily.Mecklenburger:
        options = [PultPanna.None, PultPanna.PultPanna];
        break;
    }

    const labelMap = { [PultPanna.PultPanna]: t('pult_tile.pult_tile'), [PultPanna.None]: t('generic.none') };

    return options.map(option => ({
          label: labelMap[option],
          value: option,
          default: option == PultPanna.None,
      })
    );
  }

  public pultItems(pult: PultPanna, gable: Gable, finish: TileFinish, color: TileColor) {
    const items: RadioOption[] = [];

    const arts: RoofArticle[] = [];
    const halfArts: RoofArticle[] = [];
    if (pult == PultPanna.PultPanna) {
      arts.push(RoofArticle.PultPanna);
      halfArts.push(RoofArticle.PultPannaHalv);
      if (gable !== Gable.None) {
        arts.push(RoofArticle.PultPannaGavelHöger);
        arts.push(RoofArticle.PultPannaGavelVänster);
        halfArts.push(RoofArticle.PultPannaGavelHöger);
        halfArts.push(RoofArticle.PultPannaGavelVänster);
      }
    }

    if (this.family == RoofTile.Carisma.family)
      arts.push(...halfArts);

    const f = TileFinish[Number(finish)] as keyof typeof TileFinish;
    for (const part of arts) {
      if (!this.getArticle(part)) continue;
      let variant = this.accessoryVariants[part]?.[color]?.[f] ?? this.accessoryVariants[part]?.[color]?.[TileFinish.None];

      // Get the first variant if no variant is found
      if (!variant) {
        variant = this.accessoryVariants[part]?.[color]?.[Object.keys(this.accessoryVariants[part][color])[0]];
      }

      items.push({
        value: part,
        label: this.getArticle(part),
        artnr: variant?.artnr ?? this.getArticle(part),
      });
    }
    return doArticleReplacements(items);
  }

  public battenStepOptions(
    angle: { min: number; max: number },
    facadeHeight: FacadeHeight,
    underlagstak: Underlagstak,
    accessoryColor: AccessoryColor,
    market: MarketStr,
  ): RadioOption[] {
    let def = BattenStep.None;
    const parts = [] as RoofArticle[];
    if (angle.max <= 45 && [FacadeHeight.Less3m, FacadeHeight.Less4m].includes(facadeHeight) && market !== MarketStr.Norge) {
      switch (this.family) {
        case TileFamily.PianoFalsatLertegel:
          if (underlagstak === Underlagstak.RåspontMedPapp) {
            parts.push(RoofArticle.Bärläktsteg25);
          }
          break;
        default:
          switch (underlagstak) {
            case Underlagstak.LättMedStröläkt:
            case Underlagstak.LättUtanStröläkt:
              parts.push(RoofArticle.Bärläktsteg45);
              break;
            case Underlagstak.RåspontMedPapp:
              parts.push(RoofArticle.Bärläktsteg25);
              break;
          }
      }
    }
    if (angle.max <= 55) {
      parts.push(RoofArticle.KoppladeTaksteg);
    }

    const optionMap = {
      [RoofArticle.Bärläktsteg45]: BattenStep.Step,
      [RoofArticle.Bärläktsteg25]: BattenStep.Step,
      [RoofArticle.KoppladeTaksteg]: BattenStep.Connected,
    } as { [key: number]: BattenStep };

    // Should select "bärläktsteg" as default, if available. Otherwise, select "kopplade taksteg" or None
    def = optionMap[parts[0]] ?? BattenStep.None;

    return doArticleReplacements(
      parts.map((part) => {
        const battenStep = optionMap[part];
        const variant = this.getAccessoryVariant(part, { accessoryColor });

        return {
          value: battenStep,
          label: '', // overwritten by label in component
          artnr: variant?.artnr ?? this.getArticle(part),
          default: battenStep === def,
        };
      }),
    );
  }

  public trackingTileOptions(tileFamily: TileFamily, tileFinish: TileFinish, tileColor: TileColor): RadioOption[] {
    const articles = [];

    switch (tileFamily) {
      case TileFamily.Palema:
      case TileFamily.Exklusiv:
        articles.push(RoofArticle.SpårPanna);
        break;
      case TileFamily.Carisma:
        articles.push(RoofArticle.SpårPannaHöger);
        articles.push(RoofArticle.SpårPannaVänster);
        break;
    }

    return articles.map(
      (art) =>
        ({
          label: RoofArticle[art],
          artnr: this.getAccessoryVariant(art, { color: tileColor, finish: tileFinish })?.artnr ?? this.getArticle(art),
        } as RadioOption),
    );
  }

  public slipProtectionItem(accessoryColor: AccessoryColor): AmountOption {
    const part = RoofArticle.Glidskydd;
    const variant = this.getAccessoryVariant(part, { accessoryColor });
    return {
      artnr: variant?.artnr ?? this.getArticle(part),
    };
  }

  public roofHatchItem(accessoryColor: AccessoryColor): AmountOption {
    const part = RoofArticle.Taklucka;
    const variant = this.getAccessoryVariant(part, { accessoryColor });
    return {
      artnr: variant?.artnr ?? this.getArticle(part),
    };
  }

  public safetyHookItem(accessoryColor: AccessoryColor): AmountOption {
    const part = RoofArticle.Säkerhetskrok;
    const variant = this.getAccessoryVariant(part, { accessoryColor });
    return {
      artnr: variant?.artnr ?? this.getArticle(part),
    };
  }

  public snowHookItem(accessoryColor: AccessoryColor): AmountOption {
    const part = RoofArticle.Snökrok;
    const variant = this.getAccessoryVariant(part, { accessoryColor });
    return {
      artnr: variant?.artnr ?? this.getArticle(part),
    };
  }

  public snowSlideObstacleItem(accessoryColor: AccessoryColor): RadioOption {
    const part = RoofArticle.SnowSlideObstacle;
    const variant = this.getAccessoryVariant(part, { accessoryColor });
    return {
      label: '',
      value: '',
      artnr: variant?.artnr ?? this.itemNos[part],
    };
  }

  public snowSlideObstacleKonsolItem(accessoryColor: AccessoryColor): RadioOption {
    const part = RoofArticle.SnowSlideObstacleKonsol;
    const variant = this.getAccessoryVariant(part, { accessoryColor });
    return {
      label: '',
      value: '',
      artnr: variant?.artnr ?? this.itemNos[part],
    };
  }

  public iceStopperItem(accessoryColor: AccessoryColor): AmountOption {
    const part = RoofArticle.IsStoppare;
    const variant = this.getAccessoryVariant(part, { accessoryColor });
    return {
      artnr: variant?.artnr ?? this.getArticle(part),
    };
  }

  public snowSlideObstacleOptions(): RadioOption[] {
    const parts = [] as SnowSlideObstacle[];
    switch (this.family) {
      default:
        parts.push(SnowSlideObstacle.Yes, SnowSlideObstacle.No);
    }

    return doArticleReplacements(
      parts.map((part) => {
        return {
          value: part,
          label: part === SnowSlideObstacle.Yes ? 'yes' : 'no',
          artnr: part === SnowSlideObstacle.Yes ? this.getArticle(RoofArticle.Snöglidhinder) : '',
        };
      }),
    );
  }

  public BTSBaseRoofOptions(family: TileFamily): RadioOption[] {
    const parts = [] as BTSBaseRoof[];
    switch (family) {
      case TileFamily.Carisma:
      case TileFamily.Strängpressat2kupLertegel:
        break;
      default:
        parts.push(BTSBaseRoof.Yes, BTSBaseRoof.No);
    }

    return doArticleReplacements(
      parts.map((part) => {
        return {
          value: part,
          label: part === BTSBaseRoof.Yes ? 'yes' : 'no',
          artnr: part === BTSBaseRoof.Yes ? this.getArticle(RoofArticle.BTSUnderlagstak) : '',
        };
      }),
    );
  }

  public angleInRange(angle: number, range: { min: number; max: number }): boolean {
    return angle >= range.min && angle <= range.max;
  }

  public isSelectable(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    lAvstånd?: number | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    taklutning?: { min: number; max: number } | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    underlagstak?: Underlagstak | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    market?: MarketStr | null,
  ) {
    return {
      valid: true,
      message: '',
    };
  }

  public harHalvpanna(): boolean {
    return [TileFamily.Palema, TileFamily.Carisma, TileFamily.TvillingFalsatLertegel, TileFamily.PianoFalsatLertegel, TileFamily.Mecklenburger].includes(this.family);
  }

  public amountPerPack() {
    switch (this.family) {
      case TileFamily.HansaFalsatLertegel:
      case TileFamily.HansaFalsatLertegelRakFramkant:
      case TileFamily.PianoFalsatLertegel:
        return 6;
      case TileFamily.TvillingFalsatLertegel:
        return 4;
      case TileFamily.TvillingLertegelRakFramkant:
        if (isCompatMode()) return 1;
        return 4;
      // Old calc doesn"t round up into whole packs
      case TileFamily.Strängpressat2kupLertegel:
        if (isCompatMode()) return 1;
        return 48;
      default:
        return 1;
    }
  }

  /** Byggbredd for gavelpannor */
  public byggBreddGavelPannaVänster(helpanna: boolean) {
    switch (this.family) {
      case TileFamily.Mecklenburger:
      case TileFamily.Palema:
        return 265;
      case TileFamily.Exklusiv:
        return 215;
      case TileFamily.Carisma:
        if (helpanna) return 200;
        else return 75;
      // case TileFamily.Meckelnburg:
      // 	return 265;
      case TileFamily.HansaFalsatLertegel:
        return 195;
      case TileFamily.TvillingFalsatLertegel:
        return 180;
      case TileFamily.PianoFalsatLertegel:
        if (helpanna) return 118;
        return 52;
    }
    return 0;
  }
  public byggBreddGavelPannaHöger(helpanna: boolean) {
    switch (this.family) {
      case TileFamily.Palema:
      case TileFamily.Mecklenburger:
        return 295;
      case TileFamily.Exklusiv:
        return 265;
      case TileFamily.Carisma:
        if (helpanna) return 265;
        else return 140;
      // case TileFamily.Meckelnburg:
      // 	return 295;
      case TileFamily.HansaFalsatLertegelRakFramkant:
        return 0;
      case TileFamily.HansaFalsatLertegel:
        return 230;
      case TileFamily.TvillingFalsatLertegel:
        return 214;
      case TileFamily.PianoFalsatLertegel:
        if (helpanna) return 190;
        return 88;
    }
    return 0;
  }

  public takpannaByggBredd() {
    switch (this.family) {
      case TileFamily.Mecklenburger:
      case TileFamily.Palema:
        return 300;
      case TileFamily.Exklusiv:
        return 250;
      case TileFamily.Carisma:
        return 250;
      // case TileFamily.Meckelnburg:
      // 	return 300;
      case TileFamily.HansaFalsatLertegelRakFramkant:
        return 210;
      case TileFamily.HansaFalsatLertegel:
        return 210;
      case TileFamily.TvillingFalsatLertegel:
        return 346;
      case TileFamily.TvillingLertegelRakFramkant:
        return 346;
      case TileFamily.PianoFalsatLertegel:
        return 204;
      case TileFamily.Strängpressat2kupLertegel:
        return 253;
    }
    return 0;
  }

  public halvpannaByggBredd() {
    switch (this.family) {
      case TileFamily.Palema:
      case TileFamily.Mecklenburger:
        return 150;
      case TileFamily.Exklusiv:
        return 0;
      case TileFamily.Carisma:
        return 125;
      // case TileFamily.Meckelnburg:
      // 	return 150;
      case TileFamily.HansaFalsatLertegelRakFramkant:
        return 0;
      case TileFamily.HansaFalsatLertegel:
        return 0;
      case TileFamily.TvillingFalsatLertegel:
        return 173;
      case TileFamily.TvillingLertegelRakFramkant:
        return 173;
      case TileFamily.PianoFalsatLertegel:
        return 102;
    }
    return 0;
  }

  // Cases: tre värden av vinkel, ett för varje familj
  public maxLäktavstånd(market: MarketStr, angle: number) {
    let läktavstånd;
    if (!(angle >= 14 && angle <= 80)) throw new Error('Vinkel outside of allowed range 14-80: ' + angle);
    if (!this.läktavstånd?.[market]) throw new Error('Selected market does not exist on tile: ' + this.label);
    if (angle >= 14 && angle <= 18) läktavstånd = this.läktavstånd[market].angle14to18;
    if (angle >= 18 && angle <= 22) läktavstånd = this.läktavstånd[market].angle18to22;
    if (angle >= 22 && angle <= 80) läktavstånd = this.läktavstånd[market].angle22to80;
    if (!läktavstånd) throw new Error('No max läktavstånd specified for tile: ' + this.artNrBase);

    // Clamp to global max
    if (läktavstånd > MAX_INPUT_LAKT[market]) läktavstånd = MAX_INPUT_LAKT[market];

    return läktavstånd;
  }

  public minLäktavstånd(market: MarketStr) {
    let läktavstånd = this.läktavstånd[market].min;
    if (!läktavstånd) throw new Error('No min läktavstånd specified for tile: ' + this.label);

    // Clamp to global min
    if (läktavstånd < MIN_INPUT_LAKT[market]) läktavstånd = MIN_INPUT_LAKT[market];

    return läktavstånd;
  }

  public förstaläkt(market: MarketStr) {
    const avst = this.avstTillFörstaLäkt[market];
    if (!avst) throw new Error("No 'distance to first läkt' specified for tile: " + this.label);
    return avst;
  }

  // TODO
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public bärläktAvståndNock(angle: number, _underlagstak: Underlagstak): number {
    const ang = Number(angle.toFixed(1));
    if ([RoofTile.Palema].includes(this)) {
      if (ang >= 14 && ang <= 27) return 40;
      if (ang >= 27.1 && ang <= 35) return 25;
      if (ang >= 35.1 && ang <= 80) return 15;
      return 40;
    }
    throw new Error('Bärläktavstånd not implemented for tile: ' + this.label);
  }

  public itemPrefixNo(article: RoofArticle) {
    const artnr = this.itemNos?.[article] ?? '';
    return artnr.replace(/[0-9][0-9]([^0-9].*)?$/, '');
  }

  public getAccessoryOptions(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    assortment: ExtraAssortment,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { color, finish, accessoryColor }: { color: TileColor; finish: TileFinish; accessoryColor: AccessoryColor },
  ): string[] {
    return [];
  }
}

// // Min och max läktavstånd, hämtat från gsheets
// const minLäktavstånd: { [key in MarketStr]?: { [key: string]: number } } = { // TODO
//     [MarketStr.Sverige]: {
//         [RoofTile.Palema.label]: 310,
//         [RoofTile.Exklusiv.label]: 310,
//         [RoofTile.Carisma.label]: 310,
//         [RoofTile.Hansa.label]: 320,
//         [RoofTile.HansaRak.label]: 320,
//         [RoofTile.Tvilling.label]: 266,
//         [RoofTile.TvillingRak.label]: 266,
//         [RoofTile.Piano.label]: 310,
//         [RoofTile.Höganäs.label]: 375,
//     },
//     [MarketStr.Norge]: {
//         [RoofTile.Palema.label]: 310,
//         [RoofTile.Exklusiv.label]: 310,
//         [RoofTile.Carisma.label]: 310,
//         [RoofTile.Hansa.label]: 320,
//         [RoofTile.HansaRak.label]: 320,
//         [RoofTile.Tvilling.label]: 266,
//         [RoofTile.TvillingRak.label]: 266,
//         [RoofTile.Piano.label]: 310,
//         [RoofTile.Höganäs.label]: 375,
//     }
// };
// const maxLäktavstånd14Till18: { [key in MarketStr]?: { [key: string]: number } } = { // TODO
//     [MarketStr.Sverige]: {
//         [RoofTile.Palema.label]: 320,
//         [RoofTile.Exklusiv.label]: 320,
//         [RoofTile.Carisma.label]: 320,
//         [RoofTile.Hansa.label]: 345,
//         [RoofTile.HansaRak.label]: 345,
//         [RoofTile.Tvilling.label]: 285,
//         [RoofTile.TvillingRak.label]: 285,
//         [RoofTile.Piano.label]: 345,
//         [RoofTile.Höganäs.label]: 375,
//     },
//     [MarketStr.Norge]: {
//         [RoofTile.Palema.label]: 320,
//         [RoofTile.Exklusiv.label]: 320,
//         [RoofTile.Carisma.label]: 320,
//         [RoofTile.Hansa.label]: 345,
//         [RoofTile.HansaRak.label]: 345,
//         [RoofTile.Tvilling.label]: 285,
//         [RoofTile.TvillingRak.label]: 285,
//         [RoofTile.Piano.label]: 310,
//         [RoofTile.Höganäs.label]: 375,
//     }
// };
// const maxLäktavstånd18Till22: { [key in MarketStr]?: { [key: string]: number } } = { // TODO
//     [MarketStr.Sverige]: {
//         [RoofTile.Palema.label]: 340,
//         [RoofTile.Exklusiv.label]: 340,
//         [RoofTile.Carisma.label]: 340,
//         [RoofTile.Hansa.label]: 345,
//         [RoofTile.HansaRak.label]: 345,
//         [RoofTile.Tvilling.label]: 285,
//         [RoofTile.TvillingRak.label]: 285,
//         [RoofTile.Piano.label]: 345,
//         [RoofTile.Höganäs.label]: 375,
//     },
//     [MarketStr.Norge]: {
//         [RoofTile.Palema.label]: 340,
//         [RoofTile.Exklusiv.label]: 340,
//         [RoofTile.Carisma.label]: 340,
//         [RoofTile.Hansa.label]: 345,
//         [RoofTile.HansaRak.label]: 345,
//         [RoofTile.Tvilling.label]: 285,
//         [RoofTile.TvillingRak.label]: 285,
//         [RoofTile.Piano.label]: 310,
//         [RoofTile.Höganäs.label]: 375,
//     }
// };
// const maxLäktavstånd22Till80: { [key in MarketStr]?: { [key: string]: number } } = { // TODO
//     [MarketStr.Sverige]: {
//         [RoofTile.Palema.label]: 375,
//         [RoofTile.Exklusiv.label]: 375,
//         [RoofTile.Carisma.label]: 350,
//         [RoofTile.Hansa.label]: 345,
//         [RoofTile.HansaRak.label]: 345,
//         [RoofTile.Tvilling.label]: 285,
//         [RoofTile.TvillingRak.label]: 285,
//         [RoofTile.Piano.label]: 345,
//         [RoofTile.Höganäs.label]: 375,
//     },
//     [MarketStr.Norge]: {
//         [RoofTile.Palema.label]: 375,
//         [RoofTile.Exklusiv.label]: 375,
//         [RoofTile.Carisma.label]: 350,
//         [RoofTile.Hansa.label]: 345,
//         [RoofTile.HansaRak.label]: 345,
//         [RoofTile.Tvilling.label]: 285,
//         [RoofTile.TvillingRak.label]: 285,
//         [RoofTile.Piano.label]: 310,
//         [RoofTile.Höganäs.label]: 375,
//     }
// };
// const avstTillFörstaLäkt: { [key in MarketStr]?: { [key: string]: number } } = { // TODO
//     [MarketStr.Sverige]: {
//         [RoofTile.Palema.label]: 340,
//         [RoofTile.Exklusiv.label]: 340,
//         [RoofTile.Carisma.label]: 360,
//         [RoofTile.Hansa.label]: 340,
//         [RoofTile.HansaRak.label]: isCompatMode() ? 320 : 340,
//         [RoofTile.Tvilling.label]: 300,
//         [RoofTile.TvillingRak.label]: 280,
//         [RoofTile.Piano.label]: 340,
//         [RoofTile.Höganäs.label]: 375,
//     },
//     [MarketStr.Norge]: {
//         [RoofTile.Palema.label]: 370,
//         [RoofTile.Exklusiv.label]: 370,
//         [RoofTile.Carisma.label]: 370,
//         [RoofTile.Hansa.label]: 365,
//         [RoofTile.HansaRak.label]: 365,
//         [RoofTile.Tvilling.label]: 320,
//         [RoofTile.TvillingRak.label]: 320,
//         [RoofTile.Piano.label]: 365,
//         [RoofTile.Höganäs.label]: 375,
//     }
// };
