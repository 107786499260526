import { RoofTile } from './rooftile';
import { TileFamily, TileFinish, TileColor, RoofArticle, Material, Underlagstak, MarketStr, isCompatMode } from '@/calculation/common';
import { ExtraAssortment, AccessoryColor } from '@/enums';
import { replaceByArtnr } from '@/helpers/tile';

import { i18n } from '@/i18n';
const { t } = i18n.global;

export class Piano extends RoofTile {
  public constructor() {
    super(TileFamily.PianoFalsatLertegel, 240, 240, 'H000');
    this.label = 'Piano platt';
    this.key = 'piano';
    this.material = Material.Lertegel;
    this.läktavstånd = {
      [MarketStr.Sverige]: {
        min: 310,
        angle14to18: 345,
        angle18to22: 345,
        angle22to80: 345,
      },
      [MarketStr.Norge]: {
        min: 310,
        angle14to18: 345,
        angle18to22: 345,
        angle22to80: 375,
      },
      [MarketStr.Tyskland]: {
        min: 310,
        angle14to18: 345,
        angle18to22: 345,
        angle22to80: 345,
      },
    };
    this.avstTillFörstaLäkt = {
      [MarketStr.Sverige]: 340,
      [MarketStr.Norge]: 365,
      [MarketStr.Tyskland]: 340,
    };

    this.itemNos = {
      [RoofArticle.TakPanna]: 'H00024',
      [RoofArticle.NockPanna]: 'H30224',
      [RoofArticle.NockPannaAlt]: 'H30024',
      [RoofArticle.NockBörjan]: 'H32524',
      [RoofArticle.NockSlut]: isCompatMode() ? 'H32624' : 'H32524',
      [RoofArticle.NockBörjanGavel]: 'H31824',
      [RoofArticle.NockSlutGavel]: 'H32024',
      [RoofArticle.NockBörjanDubbelfals]: 'H30324',
      [RoofArticle.BörjanValm]: 'H32524',
      [RoofArticle.BörjanValmAlt]: isCompatMode() ? 'H32524' : 'H31024',
      [RoofArticle.NockBörjanAlt]: 'H31524',
      [RoofArticle.NockSlutAlt]: 'H31624',
      [RoofArticle.ValmKlocka]: isCompatMode() ? 'H35024' : 'H35424',
      [RoofArticle.ValmKlockaAlt]: 'H35024',
      [RoofArticle.ValmKlockaBörjan]: 'H35524',
      [RoofArticle.ValmKlockaSlut]: 'H35624',
      [RoofArticle.HalvPanna]: 'H21224',
      [RoofArticle.GavelPannaNAVänster]: 'H42324',
      [RoofArticle.GavelPannaNAHöger]: 'H42224',
      [RoofArticle.GavelPannaVänsterBred]: 'H42124',
      [RoofArticle.GavelPannaHögerBred]: 'H42024',
      [RoofArticle.GavelPannaHalvVänsterBred]: 'H42324',
      [RoofArticle.GavelPannaHalvHögerBred]: 'H42224',
      [RoofArticle.GavelPannaDubbelfals]: 'H00124',
      [RoofArticle.Ventilationshuv]: isCompatMode() ? 'H50524' : 'H52424',
      [RoofArticle.Ventilationshuv2]: 'H88424',
      // [RoofArticle.VentilationshuvMontering]: '060200',
      [RoofArticle.AvluftarePlåt]: 'H51420',
      // [RoofArticle.AvluftarePlåtMontering]: '060100',
      [RoofArticle.AvluftareLertegel]: 'H50120',
      [RoofArticle.Rififi]: '060000',
      [RoofArticle.InfästTaksteg]: '081190',
      [RoofArticle.Taksteg]: '083324',
      [RoofArticle.Snöglidhinder]: '081724',
      [RoofArticle.SnöglidhinderKonsol]: '081824',
      [RoofArticle.Valmtätning300]: '091624',
      [RoofArticle.Valmtätning370]: '090724',
      [RoofArticle.Rändalstätning]: '118524',
      [RoofArticle.Fågelband]: '092020',
      [RoofArticle.Fågelband5mSvart]: '092120',
      [RoofArticle.Fågelband5mRöd]: '092124',
      [RoofArticle.VentiFågelband]: '091520',
      [RoofArticle.Tätningskloss]: 'C97424',
      [RoofArticle.Avrinningsplåt]: 'C78890',
      [RoofArticle.Takluftare]: '097200',
      [RoofArticle.Nocktätningsremsa]: '093110',
      [RoofArticle.DiagonalClip]: 'L96290',
      [RoofArticle.DiagonalClipZial]: 'L96190',
      // [RoofArticle.Stormclips2]: '096491',
      [RoofArticle.EasyClip]: '096591',
      [RoofArticle.EasyClip38]: '096491',
      [RoofArticle.EasyClip50]: '096591',
      [RoofArticle.EasyClip30x48]: '067991',
      [RoofArticle.EasyClip36x48]: '068491',
      [RoofArticle.Nockskruv]: isCompatMode() ? '094391' : '093691',
      [RoofArticle.Nockklammer]: 'H114724',
      [RoofArticle.NockklammerAlt]: 'H124724',
      [RoofArticle.Nockbrädshållare]: '065190',

      // Roof Secutity
      // [RoofArticle.Bärläktsteg45]: '071220',
      [RoofArticle.Bärläktsteg25]: '071220',
      [RoofArticle.KoppladeTaksteg]: '084320',
      [RoofArticle.InfästningKoppladeTaksteg]: '083990',
      [RoofArticle.Glidskydd]: '072920',
      [RoofArticle.Taklucka]: 'C83820',
      [RoofArticle.Säkerhetskrok]: '070120',
      [RoofArticle.Snökrok]: '081420',
      [RoofArticle.Fästögla]: '171690',
      [RoofArticle.SnowSlideObstacle]: '081790',
      [RoofArticle.SnowSlideObstacleKonsol]: '082990',
      [RoofArticle.IsStoppare]: '083020',

      [RoofArticle.BTSUnderlagstak]: '128001',
    };

    this.marketSpecificItemNos = {
      [MarketStr.Norge]: {
        [RoofArticle.Ventilationshuv]: isCompatMode() ? 'H50524' : 'H88424', // Check compat mode
        [RoofArticle.Valmtätning300]: '092420',
        [RoofArticle.SidofallsKlammer]: 'H96391',
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public isSelectable(
    lAvstånd?: number | null,
    taklutning?: { min: number; max: number } | null,
    underlagstak?: Underlagstak | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    market?: MarketStr | null,
  ): { valid: boolean; message: string } {
    if (market === MarketStr.Tyskland) return { valid: false, message: t('validation.notice.disabled_due_to_market') };
    // 310 <= Läktavstånd <= 345
    if (lAvstånd && (lAvstånd < 310 || lAvstånd > 345)) return { valid: false, message: t('validation.notice.disabled_due_to_lakt') };
    if (taklutning && (!this.angleInRange(taklutning.min, { min: 14, max: 80 }) || !this.angleInRange(taklutning.max, { min: 14, max: 80 })))
      return { valid: false, message: t('validation.notice.disabled_due_to_angle') };
    if (underlagstak && underlagstak !== Underlagstak.RåspontMedPapp) return { valid: false, message: t('validation.notice.disabled_due_to_underlagstak') };

    return { valid: true, message: '' };
  }

  public bärläktAvståndNock(angle: number, underlagstak: Underlagstak): number {
    const ang = Number(angle.toFixed(1));
    if (underlagstak == Underlagstak.RåspontMedPapp || underlagstak == Underlagstak.LättMedStröläkt) {
      if (ang >= 14 && ang <= 22.5) return 55;
      if (ang >= 22.6 && ang <= 27.5) return 50;
      if (ang >= 27.6 && ang <= 32.5) return 45;
      if (ang >= 32.6 && ang <= 37.5) return 40;
      if (ang >= 37.6 && ang <= 42.5) return 35;
      if (ang >= 42.6 && ang <= 47.5) return 30;
      if (ang >= 47.6 && ang <= 52.5) return 25;
      if (ang >= 52.6 && ang <= 57.5) return 20;
      return 20;
    } else {
      if (ang >= 14 && ang <= 22.5) return 50;
      if (ang >= 22.6 && ang <= 27.5) return 45;
      if (ang >= 27.6 && ang <= 32.5) return 40;
      if (ang >= 32.6 && ang <= 37.5) return 35;
      if (ang >= 37.6 && ang <= 42.5) return 30;
      if (ang >= 42.6 && ang <= 47.5) return 25;
      if (ang >= 47.6 && ang <= 52.5) return 20;
      if (ang >= 52.6 && ang <= 57.5) return 15;
      return 15;
    }
  }

  public getAccessoryOptions(
    assortment: ExtraAssortment,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { color, finish, accessoryColor }: { color: TileColor; finish: TileFinish; accessoryColor: AccessoryColor },
  ) {
    const items: string[] = [];
    switch (assortment) {
      case ExtraAssortment.VentilationGenomforing:
        switch (accessoryColor) {
          case AccessoryColor.GalvanizedGreySilver:
            items.push(...['H52497', 'H51497']);
            break;
          case AccessoryColor.Black:
            items.push(...['H52420', 'H51420']);
            break;
          case AccessoryColor.BrickRed:
            items.push(...['H52424', 'H51424']);
            break;
        }

        break;
    }

    // Replace artnr if necessary
    for (let i = 0; i < items.length; i++) {
      items[i] = replaceByArtnr(items[i]);
    }

    return items;
  }
}
